import { TFunction } from 'i18next';

export const getRoomsBillingColumns = (t: TFunction) => [
  {
    title: t('Billing.roomsColumns.created_at'),
    dataIndex: 'created_at',
    key: 'created_at',
    width: '10%',
  },
  {
    title: t('Billing.roomsColumns.paid_at'),
    dataIndex: 'paid_at',
    key: 'paid_at',
    width: '10%',
  },
  {
    title: t('Billing.roomsColumns.tariff_lifetime_to'),
    dataIndex: 'tariff_lifetime_to',
    key: 'tariff_lifetime_to',
    width: '10%',
  },
  {
    title: t('Billing.roomsColumns.room_name'),
    dataIndex: 'room_name',
    key: 'room_name',
    width: '15%',
  },
  {
     title: t('Billing.roomsColumns.paid_period'),
    dataIndex: 'paid_period',
    key: 'paid_period',
    width: '10%',
  },
  {
    title: t('Billing.roomsColumns.cost'),
    dataIndex: 'cost',
    key: 'cost',
    width: '10%',
  },
  {
    title: t('Billing.roomsColumns.payment_status'),
    dataIndex: 'payment_status',
    key: 'payment_status',
    width: '10%',
  },
  {
    title: t('Billing.roomsColumns.tariff_name'),
    dataIndex: 'tariff_name',
    key: 'tariff_name',
    width: '20%',
  },
  {
    title: '',
    dataIndex: 'decline',
    key: 'decline',
    width: '5%',
  },
  // {
  //     title: t('Billing.roomsColumns.next_payment'),
  //     dataIndex: 'next_payment',
  //     key: 'next_payment',
  //     width: '15%',
  // },
];
