import { Checkbox, Form, Input, InputRef, Modal, message } from 'antd';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import classes from './NewRoomModal.module.scss';
import dayjs from 'dayjs';
import api from 'api';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { adminPermissions, setDefaultUserPermissions, setRooms, setUserAndRoomStatus } from 'store/slices/userDataSlice';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { createChat } from 'store/reducers/chatCreator';
import { Tarrifs } from 'components/TariffsComponent/tariffsData';
import { fetchUserPermissions } from 'store/reducers/userDataCreator';
import { ChoosePayment } from 'components/TariffsComponent/TariffsComponent';
import LicenseTermModal from './LicenseTermModal/LicenseTermModal';

// @ts-ignore
const isBillingActive = window.REACT_APP_IS_BILLING_ACTIVE !== 'REPLACE_REACT_APP_IS_BILLING_ACTIVE' ? window.REACT_APP_IS_BILLING_ACTIVE : process.env.REACT_APP_IS_BILLING_ACTIVE;

type NewRoomModalProps = {
  open: boolean
  onCancel: () => void;
  newRoomState?: ChoosePayment;
};

export default function NewRoomModal({open, onCancel, newRoomState}: NewRoomModalProps) {
  const [isCreatingRoom, setIsCreatingRoom] = React.useState(false);
  const [isLicenseTermModalOpen, setIsLicenseTermModalOpen] = React.useState<boolean>(false);
  const [roomName, setRoomName] = React.useState('');

  const [form] = Form.useForm();
  const nameInput = React.useRef<InputRef | null>(null);
      
  const { userData, available_rooms } = useAppSelector(store => store.userData);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCreateNewRoom = async (value: any) => {
    setIsCreatingRoom(true);
    try {
      const formData = new FormData();

      for(const key in value){
        value[key] && formData.append(key, value[key]);
      }
      if (newRoomState?.tariff) {     
        const tariffData:any = newRoomState?.tariff;   
        for(const key in tariffData){
          tariffData[key] && formData.append(key, key === 'tariff_constraints'
            ? JSON.stringify(tariffData[key])
            : tariffData[key]
          );
        }
      };
      dispatch(setUserAndRoomStatus(''));
      const { data: newRoom } = newRoomState?.tariff?.tariff_name === Tarrifs.Trial 
        ? await api.createTrialRoom(value.name)
        : await api.createRoom(formData);
    
      const UTCDate = dayjs().valueOf() + new Date().getTimezoneOffset() * 60 * 1000;
      const parsedDateRoom = {...newRoom, lives_to: null, last_action_timestamp: UTCDate, created_at: Date.parse(String(newRoom.created_at)), permissions: adminPermissions}
      dispatch(setRooms(
        available_rooms[0]
          ? [parsedDateRoom, ...available_rooms] 
          : [parsedDateRoom]
      ));
      if (parsedDateRoom.payment_link) {
        window.location.href = parsedDateRoom.payment_link;
      };

      navigate(`/room/${newRoom.id}/documents`);
      await dispatch(fetchUserPermissions({ idRoom: newRoom.id }));
      dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
      dispatch(setDefaultUserPermissions());
      dispatch(setDataRoom(parsedDateRoom));
      dispatch(
        createChat({
          data: {
            entity_type: 1,
            entity_id: newRoom.id,
            room_id: newRoom.id!,
            title: newRoom.name,
            members_ids: [userData?.id!],
          },
          ownerId: userData?.id!,
        })
      );
      setIsCreatingRoom(false);
      onCancel();
      message.success(t('Header.success.created'));
    } catch (err: unknown) {
      setIsCreatingRoom(false);
      const details = (err instanceof AxiosError && err?.response?.data?.error) || newRoomState?.tariff?.tariff_name

      switch(details) {
        case 'room already exists':
          return message.warning(t('Header.error.alreadyExists'));
        case 'trial':
          return message.error(t('Header.error.trialCreate'));
        default: 
          return message.error(t('Header.error.create'));
      };
    }
  };

  React.useEffect(() => nameInput.current?.focus(), []);

  const modalTitle = (value?: Tarrifs) => {    
    switch(value) {
      case Tarrifs.Trial:
        return t('Header.createModal.trial_title'); 
      case Tarrifs.Starter:
        return `${t(`Tariffs.tariff`)} ${t(`Tariffs.tariff_name.${value}`)} ${newRoomState?.tariff?.tariff_constraints.storage} ${t('Tariffs.gb')}`;
      case Tarrifs.Business:
        return `${t(`Tariffs.tariff`)} ${t(`Tariffs.tariff_name.${value}`)} ${newRoomState?.tariff?.tariff_constraints.storage} ${t('Tariffs.gb')}`;
      default: 
        return t('Header.createModal.title');
    };
  }

  const roomNameLabel = (value?: Tarrifs) => {
    switch(value) {
      case Tarrifs.Trial:
        return t('Header.createModal.trial_nameLabel');
      default: 
        return t('Header.createModal.nameLabel');
    };
  };

  const openLicenseModal = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setIsLicenseTermModalOpen!(true);
  };

  const checkBillingEnv = isBillingActive === 'true'
    ? !form.getFieldValue(['is_accepted_agreement'])
    : false

  return (
    <Modal 
      styles={{ body: {maxHeight: '85vh'} }}
      width={'60%'}
      title={modalTitle(newRoomState?.tariff?.tariff_name)}
      open={open}
      centered={true}
      onCancel={onCancel}
      onOk={form.submit}
      okButtonProps={{disabled: checkBillingEnv || !roomName.trim()}}
      okText={ isBillingActive === 'true'
        ? (
          newRoomState?.tariff?.tariff_name === Tarrifs.Trial 
          ? t('Header.createModal.getButton')
          : t('Header.createModal.payButton')
        )
        : t('Header.createModal.createButton')
      }
      confirmLoading={isCreatingRoom}
      cancelText={t('Header.createModal.cancelButton')}
      className={classes.modalAddRoom}
    >
      <div className={classes.modalWrap}>
      <Form className={classes.form} layout='vertical' onFinish={handleCreateNewRoom} form={form}>
        
        <Form.Item
          className={classes.form_input}
          label={roomNameLabel(newRoomState?.tariff?.tariff_name)}
          name='name'
          rules={[{
            required: true, 
            whitespace: true,
            message: t('Header.createModal.roomNameValidate')
          }]}
        >
          <Input
            ref={nameInput}
            count={{
              show: true,
              max: 80,
            }}
            value={roomName}
            onChange={(e) => setRoomName(e.target.value)}
            maxLength={80}
            placeholder={t('Header.createModal.namepPaceholder')}
          />
        </Form.Item>

        {isBillingActive === 'true' &&  <div className={classes.productCard}>
          <div className={classes.title}>{t('Header.createModal.productCard.title')}</div>
          <div className={classes.row}>
            <div className={classes.name}>{t('Header.createModal.productCard.tariffName')}</div>
            <div className={classes.text}>{t(`Tariffs.tariff_name.${newRoomState?.tariff?.tariff_name}`)}</div>
          </div>
          {newRoomState?.tariff?.tariff_name !== Tarrifs.Trial && <div className={classes.row}>
            <div className={classes.name}>{t('Header.createModal.productCard.tariffCost')}</div>
            <div className={classes.text}>{`${newRoomState?.tariff?.tariff_cost}$ ${t(`Tariffs.one_month`)}`}</div>
          </div>}
          <div className={classes.row}>
            <div className={classes.name}>{t('Header.createModal.productCard.tariffInfo')}</div>
            <div className={classes.text}>
              <span>{`- ${t('Header.createModal.productCard.storage')} - ${newRoomState?.tariff?.tariff_constraints?.storage} ${t('Tariffs.gb')}`}</span>
              <br/>
              <span style={{paddingTop: 5}}>- {t(`Tariffs.number_of_users_${newRoomState?.tariff?.tariff_constraints?.members}`)}</span>
            </div>
          </div>
          <div></div>
        </div>}

        {isBillingActive === 'true' && <Form.Item
          name='is_accepted_agreement'
          valuePropName='checked'
          style={{marginTop: 15}}
          getValueFromEvent={(e) => {
            if (e.target.checked) openLicenseModal(e);
          }}
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error(t('personalInfo.acceptedAgreementError'))),
            },
          ]}
         >
          <Checkbox>
            <span className={classes.licenseButton}>
              {t('personalInfo.acceptAgreement')}{' '}
              <span className={classes.link} onClick={(e) => openLicenseModal(e)}>
                {t('personalInfo.license')}
              </span>{' '}
              {t('personalInfo.platform')}
            </span>
          </Checkbox>
        </Form.Item>}
      </Form>
      </div>


      {isLicenseTermModalOpen && (
        <React.Suspense fallback={<div />}>
          <LicenseTermModal
            isLicenseTermModalOpen={isLicenseTermModalOpen}
            setIsLicenseTermModalOpen={setIsLicenseTermModalOpen}
            t={t}
            form={form}
          />
        </React.Suspense>
      )}
    </Modal>
  );
}
