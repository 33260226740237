import { requestTemplate } from 'api';
import { CancelToken } from 'axios';
import { INotification } from 'interfaces';

//NOTIFICATIONS
const getNotifications = ( locale: string, signal?: CancelToken ) => requestTemplate.get<INotification[]>(`/notifications/?locale=${locale}`, { cancelToken: signal });
const updateNotification = (payload: { notification_id: string, status: number }) => requestTemplate.put<INotification>('/notifications/', payload);

const notifications = {
  getNotifications,
  updateNotification,
};

export default notifications;
