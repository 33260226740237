import { message, Modal, Tabs, TabsProps } from 'antd';
import React from 'react';
import classes from '../AccessTerms.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, ClockCircleOutlined, CloseOutlined } from '@ant-design/icons';
import cn from 'classnames';
import NDAAgreement from 'components/NDAElements/NDAAgreement';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import { IDocument } from 'interfaces';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import StateOfTerm from 'routes/NDASection/StateOfTerm/StateOfTerm';
import dayjs from 'dayjs';
import api from 'api';

type ModalTermType = {
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTerm: IDocument;
  setSelectedTerm: React.Dispatch<React.SetStateAction<IDocument | null>>;
  setActiveTerms: React.Dispatch<React.SetStateAction<IDocument[]>>;
};

const ModalTerm = ({open, setIsOpen, selectedTerm, setSelectedTerm, setActiveTerms}: ModalTermType) => {
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { userData, userPermissions } = useAppSelector((state) => state.userData);
  
  const canViewState = userPermissions.can_edit_room_settings && selectedTerm.user_status?.status_access === 'approved';

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  
  const formatNDA = selectedTerm.name?.split('.')?.reverse()[0];  
  const icon = availableIconFormat.includes(formatNDA) ? formatNDA : 'default';

  React.useEffect(() => {
    if (dataRoom) {
      const fileInfo = selectedTerm?.name!.split(/\.\b/); 
      dispatch(
        fetchDocumentToViewer({
          token: selectedTerm.token,
          id: dataRoom?.id!,
          entity: selectedTerm.id,
          extension: fileInfo[1],
          is_agreement: true,
          locale: i18n.language,
          action: 'view',
        })
      );
    }
  }, [dataRoom?.id]);  

  const changeTermStatus = async (status: number) => {
    try {
      const UTCDate = dayjs().valueOf() + new Date().getTimezoneOffset() * 60 * 1000;

      if (status === 1) {
        await api.acceptTerm({room_id: dataRoom?.id!, document_id: selectedTerm.id})
        setActiveTerms(prev => prev.map(term => term.id === selectedTerm.id
          ? { ...term,
              user_status: {
                ...term.user_status!,
                accepted_at: UTCDate,
                status_access: 'approved'
              }
           }
          : term
        ));
        setSelectedTerm(prev => (prev && {...prev, 
          user_status: {
            ...prev.user_status!,
            accepted_at: UTCDate,
            status_access: 'approved'
          }
        }))
      } else {
        await api.rejectTerm({room_id: dataRoom?.id!, document_id: selectedTerm.id})
        setActiveTerms(prev => prev.map(term => term.id === selectedTerm.id
          ? { ...term, 
            user_status: {
              ...term.user_status!,
              accepted_at: UTCDate,
              status_access: 'denied'
            }
          }
          : term
        ));
        setSelectedTerm(prev => (prev && {...prev, 
          user_status: {
            ...prev.user_status!,
            accepted_at: UTCDate,
            status_access: 'denied'
          }
        }))
      }
      status === 1 
        ? message.success(t('Settings.terms.acceptedTerm'))
        : message.warning(t('Settings.terms.rejectedTerm'))
    } catch (e) {
      console.log(e);
      status === 1
        ? message.error(t('Settings.terms.acceptedError'))
        : message.error(t('Settings.terms.rejectedError'))
    }
  };

  const TabsData: TabsProps['items'] = [
    {
      key: 'agreement',
      label: t('Confirm.agreement'),
      children: <NDAAgreement userAcceptNDA={selectedTerm?.user_status?.status_access === 'approved'} changeTermStatus={changeTermStatus}/>,
    },
  ];

  canViewState && TabsData.push({
    key: 'state',
    label: t('Settings.terms.state.title'),
    children: <StateOfTerm
      selectedTerm={selectedTerm}
      title={t('Settings.terms.titleTermsModal')}
      documentName={selectedTerm.name}
    />,
  });

  const titleFile = (
    <div>
      {t('Settings.terms.titleTermsModal')} 
    </div>
  );

  const getStatusIcon = (status?: string) => {
    switch(status) {
      case 'denied':
        return <CloseOutlined className={classes.rejectIcon}/>
      case 'approved':
        return <CheckOutlined className={classes.acceptIcon}/>
      default: 
        return <ClockCircleOutlined className={classes.notAcceptIcon}/>
    };
  };

  const getClassOfWrap = (status?: string) => {
    switch(status) {
      case 'denied':
        return classes.reject
      case 'approved':
        return classes.accept
      default: 
        return classes.nonAccept
    };
  };

  const getStatusText = (status?: string) => {
    switch(status) {
      case 'denied':
        return t('Settings.terms.state.rejected')
      case 'approved':
        return <>
          <div>{t('Confirm.signedByMe')}</div>
          <div className={classes.date}>{t('Confirm.date')}</div>
          <div className={classes.dateValue}>{getDateWithTimeZone(selectedTerm?.user_status?.accepted_at!, userData?.date_format!)}</div>
        </>
      default: 
        return t('Settings.terms.state.nonAccepted')
    };
  }

  return (
    <Modal
      centered
      width={'90%'}
      onCancel={() => setIsOpen(false)}
      open={open}
      className={classes.modalWrap}
      title={titleFile} 
      footer={null}
    >
      <>
        <div className={classes.titleWrap}>
        <div className={classes.statusWrap}>
          <div className={classes.docName}>
            <div style={{ fontSize: 20 }}>{documnentIcons[icon as keyof typeof documnentIcons]}</div>
            <div style={{ paddingLeft: 6 }}>{selectedTerm.name}</div>
          </div>
          
          {getStatusIcon(String(selectedTerm?.user_status?.status_access))}
        </div>
        
        {<div className={cn(classes.dateWrap, getClassOfWrap(String(selectedTerm?.user_status?.status_access)))}>
          {getStatusText(String(selectedTerm?.user_status?.status_access))}
        </div>}
      </div>
    
      <Tabs className={classes.tabs} defaultActiveKey={'agreement'} items={TabsData} type='card' />

      </>
    </Modal>
  );
}

export default ModalTerm;
