import { requestTemplate } from 'api';

// TARIFFS
const getTariffs = (tariff_name?: string) => requestTemplate.get(`/tariffs/?tariff_name=${tariff_name}`); // tariff_name: str – если не передать, то будет весь список тарифной сетки

const getRoomsBills = () => requestTemplate.get(`/tariffs/billing/`);

const createTrialRoom = (payload: any) => requestTemplate.post<any>(`/tariffs/trial/?room_name=${payload}`);

const extendRoom = (payload: any) => requestTemplate.post<string>(`/tariffs/extend/`, payload);

const declinePayment = (payload: {billing_id: string}) => requestTemplate.post<string>(`/tariffs/billing/decline/?billing_id=${payload.billing_id}`);

const tariffs = {
  getTariffs,
  getRoomsBills,
  createTrialRoom,
  extendRoom,
  declinePayment,
};

export default tariffs;
