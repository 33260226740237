import React from 'react';
import classes from './VersionsTab.module.scss';
import { Button, Empty, Form, Input, message, Table, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setIsOpenFileDetailsModal, setIsOpenNewVersionModal } from 'store/slices/windowStateSlice';
import api from 'api';
import { IColumn, IDocument } from 'interfaces';
import TableLoader from 'components/Sceletons/TableLoader';
import { useTranslation } from 'react-i18next';
import { getVersionsColumns } from './columns/versionsColumns';
import dayjs from 'dayjs';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import { DownloadOutlined, SwapOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { setIsOpenViewer } from 'store/slices/viewerSlice';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import ConfirmationModal from 'components/Modals/ConfirmationModal/ConfirmationModal';
import { setDocuments } from 'store/slices/dataDocumentsSlice';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { getFile } from 'helpers/downloadSomeFile';
import convert from 'api/convert';
import { typesToSigning } from 'components/DocumentTable/DocumentTable';

const VersionsTab = () => {
  const [versionsData, setVersionsData] = React.useState<IDocument[]>([]);
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const [isLoadingVersions, setiILoadingVersions] = React.useState(false);
  const [choosenVersion, setChoosenVersion] = React.useState<IDocument | null>(null);
  const [form] = Form.useForm();
  
  const [isOpenRollbackModal, setIsOpenRollbackModal] = React.useState(false);
  const [isRollbacking, setIsRollbacking] = React.useState(false);

  const [isOpenRestoreModal, setIsOpenRestoreModal] = React.useState(false);
  const [isRestoring, setIsRestoring,] = React.useState(false);

  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { selectedDocument, documents, folders, currentFolder } = useAppSelector((state) => state.documents);
  const { userData } = useAppSelector((state) => state.userData);
  const { userFoldersPermissions, userRootPermissions } = useAppSelector((store) => store.permissions);

  const typeDocument = availableIconFormat.includes(selectedDocument?.extension || '') ? selectedDocument?.extension : 'default';
  const iconDocument = typeDocument as keyof typeof documnentIcons;
  
  const timeOffset = dayjs().utcOffset();

  const isRootFolder = !currentFolder[0];
  const targetFolderPermissions = (isRootFolder && userRootPermissions)
    ? userRootPermissions.permissions
    : userFoldersPermissions?.find(folder => folder.id === currentFolder[0]?.id)?.permissions;
      
  const isAvailableUploadNewVersion = targetFolderPermissions?.includes('can_upload');

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();

  const getCorrectIcon = (extention: string) => {
    const typeVersion = availableIconFormat.includes(extention) ? extention : 'default';
    const iconVersion = typeVersion as keyof typeof documnentIcons;
    return iconVersion as keyof typeof documnentIcons
  }

  const openSupportingModal = (version: IDocument, openHandle: () => void) => {
    setChoosenVersion(version);
    openHandle();
  };

  const closeSupportingModals = () => {
    setIsOpenRestoreModal(false);
    setIsOpenRollbackModal(false);
    setChoosenVersion(null);
  };

  const getAllVersions = async () => {
    if ( selectedDocument && (versionsData && !versionsData[0]) ) {
      setiILoadingVersions(true);
      try {
        const versions = await api.getDocumentVersions(selectedDocument?.id);
                
        const preparedVersions = versions.data && versions.data[0] &&
          versions.data?.map((version: IDocument, i) => ({
            ...version,
            upload_at: dayjs(version.upload_at).valueOf(),
            author: version.author ? version.author.name : '',
            key: version.id,
            version_num: version.version_num ? Number(version.version_num) : i,
          }))
          .sort((a,b) => {
            if(a.id === selectedDocument.id) return -1;
            if(b.id === selectedDocument.id) return 1
            return b.version_num - a.version_num
          });

        setVersionsData(preparedVersions);
      } catch (e) {
        message.error(t('Documents.details.versions.errorGettingVersions'));
      } finally {
        setiILoadingVersions(false)
      }
    }
  };

  const downloadVersion = async (version: IDocument) => {
    setChoosenVersion(version);

    const downloadPayload = {
      extension: version.extension,
      token: version.token,
      id: dataRoom?.id,
      entity: version.id,
      action: version.is_confidential ? 'download_confident' : 'download',
      is_agreement: false,
      is_webview: false,
      signing_status: version.signing_status,
      locale: i18n.language
    };

    const downloadInPDF = !selectedDocument?.permissions?.includes('can_download') && selectedDocument?.permissions?.includes('can_download_pdf');
    const iconOfDownloadingVersion = downloadInPDF ? 'pdf' : version?.extension || '';
    const correctFileName = downloadInPDF ? version.name.replace(/\.\w+$/, '.pdf') : version.name
    try {
      const file = downloadInPDF
        ? await convert.convertDocument(downloadPayload)
        : await api.downloadFile(downloadPayload);
      
      getFile(file.data as Blob, correctFileName);
      
      message.success(<div>
        <span>{documnentIcons[getCorrectIcon(iconOfDownloadingVersion)]}</span> {correctFileName} - {t('Documents.details.versions.successDownload')}
      </div>, 5);
    } catch {
      message.error(t('Documents.details.versions.errorDownload'))
    } finally {
      setChoosenVersion(null)
    }
  }

  const onRestore = async () => {
    setIsRestoring(true);
    try {
      const responseVersionRestore = await api.versionRestore({version_id: choosenVersion?.id!});
      dispatch(setIsOpenFileDetailsModal(false));
      message.success(<div>
        <span>{documnentIcons[getCorrectIcon(choosenVersion?.extension || '')]}</span> {choosenVersion?.name} - {t('Documents.details.versions.rollbackSuccess')}
      </div>, 5);
    
      dispatch(setDocuments({
        documents: [
          ...documents,
          {
            ...choosenVersion!,
            id: responseVersionRestore.data?.id,
            status: 0,
            upload_at: dayjs().valueOf() - timeOffset * 60 * 1000,
          }
      ],
        folders
      }));
    } catch (e) {
      message.error(t('Documents.details.versions.restoreError'));
    } finally {
      setIsRestoring(false);
      closeSupportingModals();
    }
  };

  const onRollback = async () => {
    setIsRollbacking(true);
    try {
      const responseVersionReset = await api.versionReset({ version_id: choosenVersion?.id!, comment: form.getFieldValue('comment') });      
      message.success(<div>
        <span>{documnentIcons[getCorrectIcon(choosenVersion?.extension || '')]}</span> {choosenVersion?.name} - {t('Documents.details.versions.restoreSuccess')}
      </div>, 5);

      dispatch(setDocuments({
        documents: documents.map(document => {
          return document.id === selectedDocument!.id
            ? {
                ...document,
                name: responseVersionReset.data.name,
                id: responseVersionReset.data.id,
                extension: responseVersionReset.data.extension,
                token: responseVersionReset.data.token,
              }
            : document
        }),
        folders
      }));
      dispatch(setIsOpenFileDetailsModal(false));
      form.resetFields(['comment'])
    } catch (e) {
      message.error(t('Documents.details.versions.rollbackError'));
    } finally {
      setIsRollbacking(false);
      closeSupportingModals();
    }
  };

  React.useEffect(() => {
    getAllVersions();
  }, [versionsData]);

  React.useEffect(()=> {
    const documentColumns = getVersionsColumns(t);
    const customColumns = documentColumns?.map((col: IColumn) => ({
      ...col,
      render: (value: any, info: any) => renderCell({value, info, col}),
    }))

    setModifiedColumns(customColumns);
  },[versionsData, i18n.language, choosenVersion]);  

  const renderCell = (props: any) => {
    const { value, info, col } = props;
    const icon = availableIconFormat.includes(info.extension) ? info.extension : 'default';

    if (col.key === 'name') {
      return <div>
         {documnentIcons[icon as keyof typeof documnentIcons]}
         <span style={{paddingLeft: 4}}>{value}</span>
      </div>
    }

    if(col.key === 'upload_at'){
      return value && getDateWithTimeZone(value, userData?.date_format!);
    }

    if (col.key === 'author') {
      return value && <div>
         {<UserOutlined className={classes.authorIcon}/>}
         <span style={{paddingLeft: 4}}>{value}</span>
      </div>
    }

    if (col.key === 'version_comment') {
      return <div className={classes.commentDiv}>
        {value || '-'}
      </div>
    }

    if (col.key === 'details') {
      if (selectedDocument?.id === info.id){
        return <div className={classes.current}>{t('Documents.details.versions.current')}</div>
      }

      const isAvailableDownloadVersion = [...typesToSigning, 'xls', 'xlsx'].includes(info.extension)
        ? selectedDocument?.permissions?.includes('can_download') || selectedDocument?.permissions?.includes('can_download_pdf')
        : selectedDocument?.permissions?.includes('can_download');

      return (
        <div className={classes.details}>
          <Tooltip title={t('Documents.details.versions.download')}>
            <Button
              className={isAvailableDownloadVersion ? classes.iconButton : 'null'}
              disabled={!isAvailableDownloadVersion || Boolean(choosenVersion?.id)}
              size='small'
              type='text'
              loading={choosenVersion?.id === info.id}
              icon={<DownloadOutlined style={{fontSize: 18}}/>}
              onClick={() => downloadVersion(info)}
            />
          </Tooltip>

          {/* <Tooltip title={t('Documents.details.versions.restore')}>
            <Button
              className={isAvailableUploadNewVersion ? classes.iconButton : 'null'}
              disabled={!isAvailableUploadNewVersion}
              size='small'
              type='text'
              icon={<RollbackOutlined style={{fontSize: 18}}/>}
              onClick={() => openSupportingModal(info, () => setIsOpenRestoreModal(true))}
            />
          </Tooltip> */}

          <Tooltip title={t('Documents.details.versions.rollback')}>
            <Button
              className={isAvailableUploadNewVersion ? classes.iconButton : 'null'}
              disabled={!isAvailableUploadNewVersion}
              size='small'
              type='text'
              icon={<SwapOutlined style={{fontSize: 18}}/>}
              onClick={() => openSupportingModal(info, () => setIsOpenRollbackModal(true))}
            />
          </Tooltip>
        </div>
      )
    }
    
    return value;
  };

  const rollbackText = (
    <div>
      <div className={classes.confirmText}>{t('Documents.details.versions.rollbackDocument')}</div>
      <div className={classes.confirmDocCard}>
        <span style={{paddingRight: 3}}>{documnentIcons[iconDocument]}</span>
        {selectedDocument?.name} ( {t('Documents.details.versions.tableColumns.date')} - {getDateWithTimeZone(selectedDocument?.upload_at, userData?.date_format!)} )
      </div>
      <div className={classes.confirmText}>{t('Documents.details.versions.toVersion')}</div>
      <div className={classes.confirmDocCard}>
        <span style={{paddingRight: 3}}>{documnentIcons[getCorrectIcon(choosenVersion?.extension || '')]}</span>
        {choosenVersion?.name} ( {t('Documents.details.versions.tableColumns.date')} - {getDateWithTimeZone(choosenVersion?.upload_at, userData?.date_format!)} )
      </div>
      <Form form={form} layout='vertical' style={{paddingTop: 10}}>
        <Form.Item
          label={<span style={{fontWeight: 600}}>{t('Documents.modals.newVersion.commentReset')}</span>}
          name='comment'
        >
          <Input.TextArea
            placeholder={t('Documents.modals.newVersion.enterCommentReset')}
            autoSize={{ maxRows: 10, minRows: 4 }}
          />
        </Form.Item>
      </Form>
    </div>
  );

  const restoreText = (
    <div>
      <div className={classes.confirmText}>{t('Documents.details.versions.restoreDocument')}</div>
      <div className={classes.confirmDocCard}>
        <span style={{paddingRight: 3}}>{documnentIcons[getCorrectIcon(choosenVersion?.extension || '')]}</span>
        {choosenVersion?.name} ( {t('Documents.details.versions.tableColumns.date')} - {getDateWithTimeZone(choosenVersion?.upload_at, userData?.date_format!)} )
      </div>
      <div className={classes.confirmSubText}>{t('Documents.details.versions.restoreExplanation')}</div>
    </div>
  );
  
  return (
    <div className={classes.versionsWrap}>
      <div className={classes.versionsTable}>
        { isLoadingVersions 
          ? <TableLoader height={'calc(100vh - 261px)'} padding={5} borderradius={8} margintop={5}/>
          : <Table
            locale={{emptyText: 
              <Empty
                description={t('Documents.details.versions.noVersions')}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            }}
            className={classes.table}
            dataSource={versionsData}
            columns={modifiedColumns}
            size='middle'
            pagination={false}
            scroll={{ y: 'calc(90vh - 190px)' }}
            rowClassName={classes.hoverable}
            onRow={(record, idx: any) => {
              const onViewClick = async (event: any) => {
                navigate(`${window.location.pathname}?webviewer_file_id=${record.id}`);
                dispatch(setIsOpenViewer(true));
                await dispatch(fetchDocumentToViewer({
                  token: record.token,
                  extension: record.extension,
                  is_agreement: false,
                  id: dataRoom?.id,
                  entity: record.id,
                  action: record.is_confidential ? 'view_confident': 'view',
                  locale: i18n.language
                }));
              }
    
              return {
                onDoubleClick: onViewClick,
              }
            }}
          />
        }
      </div>
      <div className={classes.newVersion}>
        <Tooltip title={Boolean(selectedDocument?.signing_status !== null) ? t('Documents.details.versions.signedDocument') : null}>
          <Button
            onClick={() => dispatch(setIsOpenNewVersionModal(true))}
            type='primary'
            disabled={!isAvailableUploadNewVersion || Boolean(selectedDocument?.signing_status !== null)}
          >
            {t('Documents.details.versions.uploadVersion')}
          </Button>
        </Tooltip>
      </div>

      {isOpenRollbackModal && (
        <React.Suspense fallback={<div />}>
          <ConfirmationModal
            isOpen={isOpenRollbackModal}
            onCancel={closeSupportingModals}
            isLoading={isRollbacking}
            submit={onRollback}
            text={rollbackText}
            title={t('Documents.details.versions.rollbackVersionTitle')}
            okText={t('Documents.details.versions.rollback')}
            customClassname={'restore'}
          />
        </React.Suspense>
      )}

      {isOpenRestoreModal && (
        <React.Suspense fallback={<div />}>
          <ConfirmationModal
            isOpen={isOpenRestoreModal}
            onCancel={closeSupportingModals}
            isLoading={isRestoring}
            submit={onRestore}
            text={restoreText}
            title={t('Documents.details.versions.restoreVersionTitle')}
            okText={t('Documents.details.versions.restore')}
          />
        </React.Suspense>
      )}

      {(isRestoring || isRollbacking) && <UploadingComponent />}

    </div>
  );
}

export default VersionsTab;
