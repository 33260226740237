import { LoadingOutlined } from '@ant-design/icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, message, Tooltip, Typography } from 'antd';
import api from 'api';
import classNames from 'classnames';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import { TFunction } from 'i18next';
import { dateFormatType, IDataRoom, INotification } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { VariableSizeList } from 'react-window';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchRoom } from 'store/reducers/roomsCreator';
import { fetchUserPermissions } from 'store/reducers/userDataCreator';
import { setCurrentFolder } from 'store/slices/dataDocumentsSlice';
import { setUserPermissions } from 'store/slices/dataPermissionsSlice';
import { setIsLoadingNotifications, setNotifications, setNotificationsStatus } from 'store/slices/dataRoomSlice';
import { setRooms, setUserAndRoomStatus } from 'store/slices/userDataSlice';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import classes from './HeaderComponent.module.scss';
import dayjs from 'dayjs';

type ActionsType = {
  onAccept: (event: any) => void;
  onReject: (event: any) => void;
};

type NotificationRowProps = {
  items: INotification[];
  index: number;
  t: TFunction;
  style: any;
  onItemClick: () => void;
  dateFormat: dateFormatType;
  arrUpdatingIds?: string[];
  actions: ActionsType;
  rooms: IDataRoom[];
};

function NotificationRow({
  items,
  index,
  t,
  style,
  onItemClick,
  dateFormat,
  arrUpdatingIds,
  actions,
  rooms,
}: NotificationRowProps) {
  const [isActionLoading, setIsActionLoading] = React.useState(false);
  const date = getDateWithTimeZone(items[index].created_at, dateFormat);
  const isHoverableItem = items[index].link || items[index].status !== 1;

  Object.keys(actions).forEach((key) => {
    const oldAction = actions[key as keyof typeof actions];
    actions[key as keyof typeof actions] = async (event: React.MouseEvent) => {
      event.stopPropagation();
      setIsActionLoading(true);
      await oldAction(event);
      setIsActionLoading(false);
    };
  });

  return (
    <div
      style={style}
      className={classNames(classes.list_item, isHoverableItem && classes.hoverable)}
      onClick={isActionLoading ? undefined : onItemClick}
    >
      <Tooltip title={t(`Header.Notification.${items[index].type}`)}>
        <Typography.Title level={5} className={classes.list_item_title}>
          <span className={classes.list_item_inner_title}>
            {arrUpdatingIds!.includes(items[index].id) ? (
              <LoadingOutlined style={{ color: 'lightblue' }} />
            ) : (
              !items[index].status && <FontAwesomeIcon className={classes.list_item_dot} icon={faCircle} />
            )}{' '}
            {t(`Header.Notification.${items[index].type}`)}
          </span>
          <span className={classes.list_item_timestamp}>{date}</span>
        </Typography.Title>
      </Tooltip>
      <Tooltip mouseEnterDelay={0.6} title={items[index].message}>
        <div className={classes.list_item_description}>{items[index].message}</div>
      </Tooltip>

      {items[index].details.invite_status === 0 ? (
        <>
          <Divider style={{ margin: 0 }} />
          <div className={classes.list_item_buttons}>
            <Button loading={isActionLoading} onClick={actions.onAccept} type='primary'>
              {t('Confirm.accept')}
            </Button>
            <Button loading={isActionLoading} onClick={actions.onReject} type='primary' danger>
              {t('Confirm.reject')}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
}

type Props = {
  setIsNotificationsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  notifications: INotification[];
  isLoadingNotifications: boolean;
};

function NotificationList({ setIsNotificationsOpen, notifications, isLoadingNotifications }: Props) {
  const [arrUpdatingIds, setArrUpdatingIds] = React.useState<string[]>([]);
  const { userData, available_rooms } = useAppSelector((state) => state.userData);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const getSize = React.useCallback(
    (idx: number) => {
      return notifications[idx].details.invite_status === 0 ? 154 : 110;
    },
    [notifications, userData, available_rooms]
  );

  const onViewBtnClick = () => {
    dispatch(setIsLoadingNotifications(true));
    const newNotificationsIdxs: number[] = [];
    const newNotifications = notifications.filter((not, idx) => {
      if (not.status === 0) {
        newNotificationsIdxs.push(idx);
        return true;
      }

      return false;
    });

    Promise.all(
      newNotifications.map(async (not) => {
        return (await api.updateNotification({ notification_id: not.id, status: 1 })).data;
      })
    )
      .then((datas) => {
        let dataIdx = -1;
        const changedNotifications = notifications.map((not, idx) => {
          if (newNotificationsIdxs.includes(idx)) {
            dataIdx += 1;
            return datas[dataIdx];
          }

          return not;
        });

        dispatch(setNotifications(changedNotifications));
      })
      .finally(() => dispatch(setIsLoadingNotifications(false)));
  };
  return (
    <div className={classes.notification_items}>
      <VariableSizeList
        itemCount={notifications.length}
        height={notifications.length > 3 ? 400 : notifications.length * 110}
        itemSize={getSize}
        itemData={notifications}
        width={'100%'}
      >
        {({ index, style }) => {
          const onItemClick = async () => {
            if (notifications[index].details?.invite_status === 2) return;
            if (notifications[index].link) {
              navigate(String(notifications[index].link));
              const selectRoomTab =
                String(notifications[index].link).includes('prevTab=rooms') ||
                String(notifications[index].link).includes('/documents/');
              if (selectRoomTab) dispatch(setSelectedKeys(['rooms']));

              setIsNotificationsOpen(false);
              const regFolderId =
                notifications[index]?.link?.match('/documents/(.*)/') ||
                notifications[index]?.link?.match('/documents/(.*)?mod');
              const folderId = regFolderId && regFolderId[1].replace('?', '');
              const regRoomId = notifications[index]?.link?.match('/room/(.*?)/');
              const roomId = regRoomId ? regRoomId[1] : notifications[index].details.room_id;

              if (!dataRoom && roomId) {
                await dispatch(fetchUserPermissions({ idRoom: roomId }));
                await dispatch(fetchRoom({ id: roomId }));
                dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
              } else if (dataRoom?.id !== roomId) {
                dispatch(setUserPermissions(null));
                dispatch(setUserAndRoomStatus('fulfilledUserData'));
              } else if (folderId) {
                const folder = await api.getFolderById(folderId, roomId);
                const folderPath = folder.data.folder_path ? folder.data.folder_path?.reverse() : [];
                localStorage.setItem('previousFolderPath', JSON.stringify(folderPath));
                dispatch(setCurrentFolder(folderPath));
              } else {
                dispatch(setCurrentFolder([]));
              }
            }

            if (notifications[index].status !== 1) {
              notifications[index].status !== 1 && setArrUpdatingIds((prev) => [...prev, notifications[index].id]);
              const { data } = await api.updateNotification({ notification_id: notifications[index].id, status: 1 });
              setArrUpdatingIds(arrUpdatingIds.filter((id) => id === notifications[index].id));
              dispatch(
                setNotifications(
                  notifications.map((notification) => (notification.id === data.id ? data : notification))
                )
              );
            }
          };

          const actions: ActionsType = {
            onAccept: async () => {
              try {
                const response = await api.acceptInvite(notifications[index].details.invite_id!);
                const UTCDate = dayjs().valueOf() + new Date().getTimezoneOffset() * 60 * 1000;

                const newRooms: IDataRoom[] = available_rooms.map((room) =>
                  room.id === response?.data?.id
                  ? {
                      ...room,
                      invite_id: undefined,
                      agreement_enable: response.data.agreement_enable,
                      access_terms_status: response.data.access_terms_status,
                      last_action_timestamp: response.data.last_action_timestamp || UTCDate,
                      created_at: response.data.created_at,
                      lives_to: null,
                    }
                  : room
                )
                dispatch(setRooms(newRooms));
                message.success(t('Header.Notification.invites.success.accepted'));
                dispatch(setNotificationsStatus('pending'));

                dispatch(
                  setNotifications(
                    notifications.map((not, idx) =>
                      idx === index ? { ...not, details: { ...not.details, invite_status: 1 as any } } : not
                    )
                  )
                );
                setTimeout(() => dispatch(setNotificationsStatus('fulfilled')), 10);
              } catch (Err) {
                message.error(t('Header.Notification.invites.error.accepted'));
              }
            },
            onReject: async () => {
              try {
                const response = await api.declineInvite(notifications[index].details.invite_id!);
                message.success(t('Header.Notification.invites.success.declined'));
                dispatch(setNotificationsStatus('pending'));
                dispatch(
                  setNotifications(
                    notifications.map((not, idx) =>
                      idx === index ? { ...not, details: { ...not.details, invite_status: 2 as any } } : not
                    )
                  )
                );
                setTimeout(() => dispatch(setNotificationsStatus('fulfilled')), 10);
              } catch (Err) {
                message.error(t('Header.Notification.invites.error.declined'));
              }
            },
          };

          return (
            <NotificationRow
              dateFormat={userData?.date_format!}
              onItemClick={onItemClick}
              t={t}
              style={style}
              items={notifications}
              index={index}
              arrUpdatingIds={arrUpdatingIds}
              actions={actions}
              rooms={available_rooms!}
            />
          );
        }}
      </VariableSizeList>

      <Button loading={isLoadingNotifications} type='default' onClick={onViewBtnClick} className={classes.list_button}>
        {t('Header.Notification.btn')}
      </Button>
    </div>
  );
}

export default NotificationList;
