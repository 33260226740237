import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from 'antd'
import classes from './GlobalMobileBanner.module.scss'
import { CloseOutlined } from '@ant-design/icons'

const { Text } = Typography

const withGlobalMobileBanner = (WrappedApp: React.FC) => {
  return (props: any) => {
    const [showBanner, setShowBanner] = useState(false);
    const { t } = useTranslation('translation', { keyPrefix: 'MobileApps' });
    const navigate = useNavigate();
    useEffect(() => {
      if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)) {
        setShowBanner(true);
      }
    }, []);

    const onClickDownload = () => {
      navigate('/unsupported')
      setShowBanner(false);
    }
    const closeBanner = () => setShowBanner(false);

    return (
      <>
        {showBanner && (
          <div className={classes.topBanner}>
            <div className={classes.bannerContent}>
                <Text>{t('MobileApp.downloadApp')}</Text>
                <Button onClick={onClickDownload} type="primary">{t('MobileApp.downloadBtn')}</Button>
                <Button className={classes.bannerClose} icon={<CloseOutlined />} onClick={closeBanner} />
            </div>
          </div>
        )}
        <WrappedApp {...props} />
      </>
    );
  };
};

export default withGlobalMobileBanner;