import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { IDataRoom, IDocument } from 'interfaces';
import dayjs from 'dayjs';
import { CancelTokenSource } from 'axios';
import { RootState } from 'store';
import { setRooms } from 'store/slices/userDataSlice';
import { setNotificationsStatus } from 'store/slices/dataRoomSlice';

export const fetchRoom = createAsyncThunk('dataRoom/fetchAll', async (props: { id: string }, thunkAPI) => {
  try {
    const rooms = await api.getRoom(props.id);

    return {
      ...rooms.data,
      description: rooms.data.description ? rooms.data.description : '',
    };
  } catch (e) {
    return thunkAPI.rejectWithValue('Download room failed');
  }
});

export const fetchConfidentialDocuments = createAsyncThunk(
  'confidentialDocuments/fetchAll',
  async (props: { room_id: string }, thunkAPI) => {
    try {
      const documents = await api.getConfidentialDocuments(props.room_id);
      const offset = new Date().getTimezoneOffset() * 60 * 1000;

      const preparedDocuments = documents.data.map((document: IDocument) => {
        const inSeconds = dayjs(document.upload_at).valueOf();
        const rightTime = document.upload_at ? dayjs(inSeconds - offset).format('DD.MM.YYYY, HH:mm:ss') : '-';
        return {
          ...document,
          upload_at: rightTime,
          key: document.token,
        };
      });
      return preparedDocuments;
    } catch (e) {
      return thunkAPI.rejectWithValue('Download failed');
    }
  }
);

export const fetchRoomNotifications = createAsyncThunk(
  'notifications/fetchAll',
  async (payload: { locale: string, source?: CancelTokenSource }, thunkAPI) => {
    try {
      const response = await api.getNotifications(payload.locale, payload?.source?.token);
      const { dispatch } = thunkAPI;
      // const { available_rooms } = (thunkAPI.getState() as RootState).userData;
      // const currentRoomIds = available_rooms.map(room => room.id);

      // const netApprovedRooms: IDataRoom[] = response.data
      //   .filter(({ details }) => details.invite_status === 0)
      //   .filter(({ details }) => !currentRoomIds?.includes(details.room_id))
      //   .map((invites) => ({
      //     id: invites.details.room_id,
      //     name: invites.details.room_name,
      //     description: '',
      //     created_at: 0,
      //     invited_at: invites.created_at,
      //     last_action_timestamp: '',
      //     owner_id: '',
      //     folders: [],
      //     watermark_enabled: false,
      //     invite_id: invites.details.invite_id,
      //   }));      

      // dispatch(setRooms([...netApprovedRooms, ...available_rooms]));
      setTimeout(() => {
        dispatch(setNotificationsStatus(response.data.length ? 'fulfilled' : 'empty'));
      }, 10)
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err);
    }
  }
);
