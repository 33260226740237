import { requestTemplate } from 'api';
import { IDetails, IPendingInviteUsers, IDataRoom } from 'interfaces';
import { NewInvitesResponse } from './users';
import { IRoomInite } from 'interfaces/IInvitations';

//INVITES
const getNewInvite = (payload: {emails: string[], room_id: string, group_id: string, type?: string, details: IDetails}) =>
  requestTemplate.post<NewInvitesResponse>(`/invites/invite/?action=create&id=${payload.room_id}`, payload);
const getPendingInviteUsers = (room_id: string) =>
  requestTemplate.get<IPendingInviteUsers[]>(`/invites/?room_id=${room_id}`);
const deleteInvite = (invite_id: string) =>
  requestTemplate.delete<IPendingInviteUsers[]>(`/invites/?invite_id=${invite_id}`);
const acceptInvite = (invite_id: string) =>
  requestTemplate.post<IDataRoom>(`/invites/accept/?invite_id=${invite_id}`);
const declineInvite = (invite_id: string) =>
  requestTemplate.delete(`/invites/decline/?invite_id=${invite_id}`);
const getInvitesByUser = () =>
  requestTemplate.get<IRoomInite[]>(`/invites/by_user/`);

const invites = {
  getNewInvite,
  getPendingInviteUsers,
  deleteInvite,
  acceptInvite,
  declineInvite,
  getInvitesByUser,
};

export default invites;
