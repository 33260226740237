import React from 'react';
import classes from './TariffsComponent.module.scss';
import { Button, Segmented, Select, Switch, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PaymentFlowSelection from 'components/Modals/PaymentFlowSelection/PaymentFlowSelection';
import { IColumn } from 'interfaces';
import { tariffsColumns } from './tariffsColumns';
import { costWithSale, ITariff, StorageValues, tariffsCost, tariffsData, Tarrifs } from './tariffsData';
import { CaretRightFilled, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import colors from '../../scss/variables/colors.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { NewRoomModal } from 'components/Modals';
import { languagesValues } from 'components/Header/HeaderComponent';
import { setDataRoom } from 'store/slices/dataRoomSlice';

// @ts-ignore
const contactUsAdress = window.REACT_APP_CONTACT_US !== 'REPLACE_REACT_APP_CONTACT_US' ? window.REACT_APP_CONTACT_US : process.env.REACT_APP_CONTACT_US || 'https://demo.tazadeal.com/contact';
const publicFunctions = ['water_marks', 'document_encryption', 'documents_approval', 'chats', 'versions', 'contact_list',
  'base_func', 'doc_link', 'timeline', 'doc_chat', 'privace_report', 'privace_terms', 'privace_nda'];
const requestFunc = ['electronic_signature', 'SSO', 'integrations_website', 'integrations_payment', 'integrations_schedulers'];
const rowOn4Columns = [ 'compare_title', 'safety_subTitle', 'documents_subTitle', 'confident_subTitle', 'integrations_subTitle', 'additional_subTitle'];

const iconCheck = <CheckOutlined  style={{fontSize: 16, color: colors.excel, paddingRight: 5}}/>;
const iconClose = <CloseOutlined  style={{fontSize: 16, color: colors.red, paddingRight: 5}}/>;
const iconCheckGray = <CheckOutlined  style={{fontSize: 16, color: colors.textcolor, paddingRight: 5}}/>;
const pointIcon =<CaretRightFilled style={{fontSize: 14, color: 'black', paddingRight: 5}}/>;

type TariffsComponentProps = {
  isProtected?: boolean;
};

export type ChoosePayment = {
  isOpenNewRoomModal: boolean;
  isChoosePaymentFlow?: boolean;
  tariff: ITariff | null;
}

const TariffsComponent = ({isProtected}: TariffsComponentProps) => {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const [dataSource, setDataSource] = React.useState<any>([]);
  const [forAYear, setForAYear] = React.useState(false);
  const [newRoomState, setNewRoomState] =
    React.useState<ChoosePayment>({isOpenNewRoomModal: false, isChoosePaymentFlow: false, tariff: null});

  const dispatch = useAppDispatch();

  const formTariff = (tariff_name: Tarrifs, cost: number, storage: number, members: number) => ({
    tariff_name: tariff_name,
    tariff_cost: `${String(cost * (forAYear ? 12 : 1))}`,
    tariff_duration: 1,
    tariff_duration_unit: forAYear ? 'YEAR' : 'MONTH',
    tariff_constraints: {
      storage: storage,
      members: members
    }
  })

  const { userData, isLoadingUserData } = useAppSelector((store) => store.userData);

  const defaultLanguage = localStorage.getItem('language') || 'en';
  const { i18n, t } = useTranslation();
  
  const onLanguageChange = (value: string) => {
    localStorage.setItem('language', value);
    i18n.changeLanguage(value);
  };

  const sharedOnCell = (info: {key: string}) => {
    if (rowOn4Columns.includes(info.key)) {
      return { colSpan: 0 };
    }
    return {};
  };

  React.useEffect(()=> {
    setDataSource(tariffsData);
    dispatch(setDataRoom(null));
    return () => {
      localStorage.removeItem('ended_tariff_lifetime');
    }
  },[]);

  React.useEffect(()=> {    
    const customColumns = tariffsColumns.map((col: IColumn) => ({
      ...col,
      render: (value: any, info: any) => renderCell({value, info, col}),
      onCell: col.key !== Tarrifs.Trial
        ? sharedOnCell
        : (info: {key: string}) => ({ colSpan: rowOn4Columns.includes(info.key) ? 4 : 1 }),
    }))
    setModifiedColumns(customColumns);
  },[i18n.language, dataSource, forAYear, userData]);

  const getTariffByName = (tariffName: Tarrifs) => {
    const rightIndexStorage = tariffsData.findIndex(el => el.key === 'storage_capacity');
    const storage: StorageValues = dataSource[rightIndexStorage].current_capacity[tariffName];

    const rightIndexMembers = tariffsData.findIndex(el => el.key === 'number_of_users');
    const members = dataSource[rightIndexMembers][tariffName];
    
    const cost = forAYear
      ? costWithSale[tariffName as Tarrifs]?.[storage as StorageValues]
      : tariffsCost[tariffName as Tarrifs]?.[storage as StorageValues];

    return formTariff(tariffName, Number(cost), Number(storage), members);
  }

  const openRightModal = (tariffName: Tarrifs) => {
    Boolean(localStorage.getItem('ended_tariff_lifetime')) && tariffName !== Tarrifs.Trial
      ? setNewRoomState({isOpenNewRoomModal: false, isChoosePaymentFlow: true, tariff: getTariffByName(tariffName)})
      : setNewRoomState({isOpenNewRoomModal: true, isChoosePaymentFlow: false, tariff: getTariffByName(tariffName)})
  }

  const closeModals = () => {
    setNewRoomState({isOpenNewRoomModal: false, isChoosePaymentFlow: false, tariff: null});
  }

  const renderCell = (props: any) => {
    const { value, info, col } = props;

    if(info.key === 'tariff_name') {
      return <div className={classes.tariff_name}>
        {t(`Tariffs.${info.key}.${col.key}`)}
      </div>
    };

    if(info.key === 'price') {
      const rightIndex = tariffsData.findIndex(el => el.key === 'storage_capacity');
      const price = tariffsCost[col.key as Tarrifs]?.[tariffsData[rightIndex].current_capacity[col.key] as StorageValues];
      const priceWithSale = costWithSale[col.key as Tarrifs]?.[tariffsData[rightIndex].current_capacity[col.key] as StorageValues];

      switch(col.key) {
        case Tarrifs.Trial:
          return <div className={classes.price} style={{fontSize: 20, fontWeight: 500}}>{t('Tariffs.14_days')}</div>;
        case Tarrifs.Enterprise:
          return (
            <div
              className={classes.price}
              style={{fontSize: 20, fontWeight: 500}}
            >
              <span>{t('Tariffs.custom_price')}</span>
            </div>
          );
        default: 
          return (
            <div className={classes.price}>
              {forAYear && <span style={{paddingRight: 5, textDecoration: 'line-through'}}>$ {price}{t(`Tariffs.${info.key}`)}</span>}
              <span style={{fontSize: 22, fontWeight: 500}}>$</span>
              <span style={{fontSize: 22, fontWeight: 500}}> {forAYear ? priceWithSale : price}</span>
              {t(`Tariffs.${info.key}`)}
              {forAYear && <div className={classes.forAYear}>
                {t(`Tariffs.payingAnnually`)}
              </div>}
            </div>
          );
      };
    };

    if(info.key === 'number_of_users') {
      return <div className={classes.users} style={{fontWeight: 500}}>
        {t(`Tariffs.${info.key}_${value}`)}
      </div>
    };

    if(info.key === 'by_it' || info.key === 'by_it_2') {
      switch(col.key) {
        case Tarrifs.Trial:
          return (
            <Tooltip title={t('Tariffs.onceAYear')}>
              <Button
                disabled={
                  !userData?.can_take_trial ||
                  isLoadingUserData}
                loading={isLoadingUserData}
                style={{width: '100%'}}
                type='primary'
                onClick={() => openRightModal(Tarrifs.Trial)}
              >
                {t(`Tariffs.try_free`)}
              </Button>
            </Tooltip>
          );
        case Tarrifs.Enterprise:
          return <Button
              style={{width: '100%'}}
              type='primary'
              onClick={() => window.location.href = contactUsAdress}
            >
              {t(`Tariffs.contact_us`)}
            </Button>;
        default: 
          return <Button
              style={{width: '100%'}}
              type='primary'
              disabled={isLoadingUserData}
              loading={isLoadingUserData}
              onClick={() => openRightModal(col.key)}

            >
              {`${t(`Tariffs.buy`)} ${t(`Tariffs.tariff_name.${col.key}`)}`}
            </Button>;
      };
    }

    if (info.key === 'storage_capacity') {
      const options = value.map((el: any) => ({
        label: `${el} ${t('Tariffs.gb')}`,
        value: el,
      }))
      return <div className={classes.storage_capacity}>
        {t(`Tariffs.${info.key}`)}
        {value[0] 
          ? <Segmented
              className={classes.segmentedStyle}
              value={info.current_capacity.value}
              onChange={(value) => {
                const newDataSource=[...dataSource];
                const rightIndex = tariffsData.findIndex(el => el.key === 'storage_capacity');
                newDataSource[rightIndex].current_capacity[col.key] = value;                
                setDataSource(newDataSource);      
              }}
              options={options}
              block
            />
          : <div style={{fontSize: 18, fontWeight: 500, paddingLeft: 6, textAlign: 'center'}}>{t(`Tariffs.on_request`)}</div>
        }
      </div>
    }

    if (info.key === 'suitable_for') {
      return <div className={classes.suitable_for}>
        <p style={{fontWeight: 500}}>{t(`Tariffs.${info.key}.${col.key}.${info.key}`)}</p>
        <div>{t(`Tariffs.${info.key}.${col.key}.1`)}</div>
        <div>{t(`Tariffs.${info.key}.${col.key}.2`)}</div>
        <div>{t(`Tariffs.${info.key}.${col.key}.3`)}</div>
        <div>{t(`Tariffs.${info.key}.${col.key}.4`)}</div>
        <div>{t(`Tariffs.${info.key}.${col.key}.5`)}</div>
      </div>
    }
    if (info.key === 'you_get') {
      return <div style={{fontWeight: 600}}>{t('Tariffs.you_get')}</div>
    }
    
    if (info.key === 'you_get_list') {
      switch(col.key) {
        case Tarrifs.Trial:
          return <div className={classes.you_get_list}>
            <div>{pointIcon}{t('Tariffs.one_room')}</div>
            <div>{pointIcon}{t(`Tariffs.cloud_storage_${col.key}`)}</div>
            <div>{pointIcon}{t(`Tariffs.invite_participants_${col.key}`)}</div>
            <div>{pointIcon}{t(`Tariffs.mobile_application`)}</div>
          </div>;
        case Tarrifs.Starter:
          return <div className={classes.you_get_list}>
          <div>{pointIcon}{t('Tariffs.one_room')}</div>
          <div>{pointIcon}{t(`Tariffs.cloud_storage_${col.key}`)}</div>
          <div>{pointIcon}{t(`Tariffs.invite_participants_${col.key}`)}</div>
          <div>{pointIcon}{t(`Tariffs.mobile_application`)}</div>
          <div >{pointIcon}{t(`Tariffs.free_save_month`)}</div>
        </div>;
        case Tarrifs.Business:
          return <div className={classes.you_get_list}>
          <div>{pointIcon}{t('Tariffs.one_room')}</div>
          <div>{pointIcon}{t(`Tariffs.cloud_storage_${col.key}`)}</div>
          <div>{pointIcon}{t(`Tariffs.invite_participants_${col.key}`)}</div>
          <div>{pointIcon}{t(`Tariffs.mobile_application`)}</div>
          <div >{pointIcon}{t(`Tariffs.free_save_month`)}</div>
        </div>;
        default: 
          return <div className={classes.you_get_list}>
          <div>{pointIcon}{t('Tariffs.unlimited_rooms')}</div>
          <div>{pointIcon}{t(`Tariffs.invite_participants`)}</div>
          <div>{pointIcon}{t(`Tariffs.mobile_application`)}</div>
          </div>;
      };
    }

    if (info.key === 'compare_title' && col.key === Tarrifs.Trial) {
      return <div className={classes.compareTitle}>
        {t(`Tariffs.compare_title`)}
      </div>
    }

    if(info.key === 'room_num') {
      switch(col.key) {
        case Tarrifs.Enterprise:
          return <div>{pointIcon}{t(`Tariffs.room_num_enterprise`)}</div>;
        default: 
          return <div>{pointIcon}{t(`Tariffs.room_num`)}</div>;
      };
    };

    if(info.key === 'room_members') {
      switch(col.key) {
        case Tarrifs.Trial:
          return <div>{pointIcon}{t(`Tariffs.room_members_${col.key}`)}</div>;
        case Tarrifs.Starter:
          return <div>{pointIcon}{t(`Tariffs.room_members_${col.key}`)}</div>;
        case Tarrifs.Business:
          return <div>{pointIcon}{t(`Tariffs.room_members_${col.key}`)}</div>;
        default: 
          return <div>{pointIcon}{t(`Tariffs.room_members`)}</div>;
      };
    };

    if(info.key === 'cloud_storage') {
      switch(col.key) {
        case Tarrifs.Trial:
          return <div>{pointIcon}{t(`Tariffs.cloud_storage_${col.key}`)}</div>;
        case Tarrifs.Starter:
          return <div>
            <div>{pointIcon}{t(`Tariffs.cloud_storage_from_to`)}</div>
            <div style={{fontSize: 12}}>{t(`Tariffs.*cloud_storage_from_to`)}</div>
          </div>;
        case Tarrifs.Business:
          return <div>{pointIcon}{t(`Tariffs.cloud_storage_${col.key}`)}</div>;
        default: 
          return <div>{'-'}</div>;
      };
    };

    if(info.key === 'additional_storage') {
      switch(col.key) {
        case Tarrifs.Trial:
          return <div>{iconClose}{t(`Tariffs.${info.key}`)}</div>
        case Tarrifs.Starter:
          return <div>
            <div>{iconCheck}{t(`Tariffs.${info.key}`)}</div>
            <div style={{fontSize: 12}}>{t(`Tariffs.*additional_storage`)}</div>
          </div>;
        case Tarrifs.Business:
          return <div>
            <div>{iconCheck}{t(`Tariffs.${info.key}`)}</div>
            <div style={{fontSize: 12}}>{t(`Tariffs.*additional_storage`)}</div>
          </div>;
        default: 
          return <div>{'-'}</div>;
      };
    };

    if(info.key === 'preconfigured_roles') {
      return <div>{iconCheck}{t(`Tariffs.preconfigured_roles`)}</div>;
    };

    if(info.key === 'back_up') {
      switch(col.key) {
        case Tarrifs.Trial:
          return <div>{iconClose}{t(`Tariffs.back_up`)}</div>;
        default: 
          return <div>{iconCheck}{t(`Tariffs.back_up`)}</div>;
      };
    };

    if (rowOn4Columns.includes(info.key) && col.key === Tarrifs.Trial) {
      return <div className={classes.subTitle}>
      {t(`Tariffs.${info.key}`)}
    </div>
    };

    if(info.key === '2FA') {
      return <div>{iconCheck}{t(`Tariffs.${info.key}`)}</div>;
    };

    if(requestFunc.includes(info.key)) {
      return col.key === Tarrifs.Trial
        ? <div>{iconClose}{t(`Tariffs.${info.key}`)}</div>
        : <div>
          <div>{iconCheckGray}{t(`Tariffs.${info.key}`)}*</div>
          <div>{t(`Tariffs.*SSO`)}</div>
        </div>
    };

    if(info.key === 'user_activity_log') {
      return <div>{iconCheck}{t(`Tariffs.user_activity_log`)}</div>
    };

    if (publicFunctions.includes(info.key)) {
      return <div>{iconCheck}{t(`Tariffs.${info.key}`)}</div>
    };

    return null;
  };

  return (
    <div className={isProtected ? classes.protectedWrap : classes.triffsWrap}>
      <div className={classes.headerWrap}>
        <div className={classes.periodWrap}>
          <span>{t(`Tariffs.forAMonth`)}</span>
          <Switch className={classes.switch} value={forAYear} onChange={setForAYear} />
          <span>{t(`Tariffs.forAYear`)}</span>
          <span className={classes.switchSale}>-10%</span>
        </div>
        {!isProtected && <Select
          defaultValue={defaultLanguage}
          onChange={onLanguageChange}
          style={{ minWidth: 130 }}
          options={languagesValues}
          optionLabelProp={'label'}
        />}
      </div>

      <Table
        columns={modifiedColumns}
        size='large'
        dataSource={dataSource}
        showHeader={false}
        className={classes.tariffsTable}
        pagination={false}
        rowHoverable={false}
        scroll={{
          x: '600px',
          // y: 'calc(100vh - 230px)'
        }}
      />
      <div style={{height: 1}}></div>

      {newRoomState.isOpenNewRoomModal && (
        <React.Suspense fallback={<div />}>
          <NewRoomModal
            open={newRoomState.isOpenNewRoomModal}
            onCancel={closeModals}
            newRoomState={newRoomState}
          />
        </React.Suspense>
      )}

      {newRoomState.isChoosePaymentFlow && (
        <React.Suspense fallback={<div />}>
          <PaymentFlowSelection
            open={newRoomState.isChoosePaymentFlow}
            onCancel={closeModals}
            newRoomState={newRoomState}
            setNewRoomState={setNewRoomState}
          />
        </React.Suspense>
      )}
    </div>
  );
}

export default TariffsComponent;
