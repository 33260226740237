import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { RootState } from 'store';
import { IDocument, IFolder } from 'interfaces';
import { fetchUserPermissions } from './userDataCreator';
import { setRooms, setUserAndRoomStatus } from 'store/slices/userDataSlice';
import { setCurrentFolder, setDocuments, setSelectedDocument } from 'store/slices/dataDocumentsSlice';
import { typesToSigning } from 'components/DocumentTable/DocumentTable';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { setFoldersForPermissions } from 'store/slices/dataPermissionsSlice';
import { fetchSigningState } from './signingCreator';
import { setIsOpenFileDetailsModal } from 'store/slices/windowStateSlice';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { TFunction } from 'i18next';

export type ISocketMessage = {
  timestamp: string;
  message: string;
  event: string; 
  room_id?: string;
  folder_id?: string;
  parent_folder_id?: string
}

type uploadPayload = {
  type: 'add' | 'replace';
  newDocuments?: IDocument[];
  websocketData?: any;
};

type deletePayload = {
  document_id: string;
  folder_id?: string;
  room_id?: string;
  prev_version?: string;
};

type deleteFolderPayload = {
  folder_id: string;
  room_id: string;
};

type moveDocumentPayload = {
  folder_id: string;
  room_id: string;
  document_id: string;
  older_folder_id: string;
};

type updatePermissionsType = {
  room_id: string;
  folder_id: string;
  new_permissions: string[];
  group_id: string;
  author: string;
};

const add = (newElements: IDocument[], source: (IDocument | IFolder)[], parentFolder?: any) => {
  const availableIds = source.map(doc => doc.id);
  const matchingIds: (string | null | undefined)[] = [];
  
  const newElementsWithoutDuplicates = newElements?.filter(document => {
    if (availableIds.includes(document.id)) matchingIds.push(document.id);
    return !availableIds.includes(document.id);
  }).map(doc => ({
    ...doc,
    status: 0,
    has_notes: false,
    confident_document: [],
    is_agreed: null,
    signing_status: typesToSigning.includes(doc.extension || '') ? null : '',
    permissions: parentFolder.permissions,
    clicked: false,
    key: doc.id,
    agreement_status: null,
  })) as IDocument[];  

  const sourceElementsWithRightPermissions = !matchingIds[0]
    ? source
    : source.map(document => (!matchingIds.includes(document.id)
      ? document
      : {
        ...document,
        status: 1,
        permissions: parentFolder.permissions,
      })
    );
  
  return [...newElementsWithoutDuplicates, ...sourceElementsWithRightPermissions];
};

const replace = (
  newElement: IDocument[],
  source: (IDocument | IFolder)[],
  permissions?: any,
  parentFolder?: string | null,
) => {
  const isHasDocument = Boolean(source.find(({ id }) => id === newElement[0].id));
  const isBasedOn = newElement[0]?.based_on;
  const isTheSameFolder = parentFolder === newElement[0].folder_id;
  
  return isTheSameFolder && !isHasDocument
    ? [...source, ...newElement].filter(file => file.id !== isBasedOn)
    : source.map((sourceDocument) =>
        sourceDocument.id === newElement[0].id
          ? {
              ...sourceDocument,
              key: newElement[0].token,
              upload_at: newElement[0].upload_at,
              is_confidential: newElement[0].is_confidential,
              status: newElement[0].status,
              token: newElement[0].token,
              is_signed: newElement[0].is_signed,
              permissions: permissions?.permissions,
              version_count: newElement[0].version_count,
            }
          : sourceDocument
      ).filter(file => file.id !== isBasedOn);
};

const statusCases = {
  add,
  replace,
};

const fetchDocumentsData = createAsyncThunk(
  'documents/fetchData',
  async ({ type, newDocuments, websocketData }: uploadPayload, thunkApi) => {
    const { dispatch } = thunkApi;
    const rootState = thunkApi.getState() as RootState;
    const { userFoldersPermissions, userRootPermissions } = rootState.permissions;
    const { dataRoom } = rootState.dataRoom;
    const { currentFolder, documents: sourceDocuments, folders: sourceFolders, selectedDocument } = rootState.documents;
    const folderPath = currentFolder[currentFolder.length - 1]?.id || null;
    const parentFolderID = currentFolder[0] ? currentFolder[0]?.id : null;
    const currentFolderWithPermissions = parentFolderID
      ? userFoldersPermissions?.find((folder) => folder.id === parentFolderID)
      : userRootPermissions;
    
    let payloadDocuments: IDocument[] | undefined = undefined;
    const isTheSameRoom = websocketData?.room_id === dataRoom?.id;
    const isTheSameFolder = websocketData?.folder_id === parentFolderID;
    const isThisFileConfident = websocketData?.is_confidential;
    const isApprovedNDA = dataRoom?.user_status === 2;
    const canSeeThisFile = isThisFileConfident ? isApprovedNDA : true;
    if (websocketData && isTheSameRoom && isTheSameFolder && canSeeThisFile) {
      try {
        const response = await api.getDocumentById(websocketData?.document_id)
        payloadDocuments = [response.data];
      } catch (e) {
        return thunkApi.rejectWithValue('Failed to get document by ID');
      }
    }

    const documentsForUpdate = newDocuments || payloadDocuments;
    if (documentsForUpdate) {
      // Если документа уже не существует, а модальное окно с деталями по нему сейчас открыто - то принудительно закрываем модальное окно с деталями файла
      documentsForUpdate[0].based_on === selectedDocument?.id && dispatch(setIsOpenFileDetailsModal(false));
      const documents = statusCases[type](
        documentsForUpdate,
        sourceDocuments,
        currentFolderWithPermissions,
        folderPath,
      ) as IDocument[];

      dispatch(setDocuments({ documents, folders: sourceFolders }));
    };
  }
);

const addNewFolder = createAsyncThunk(
  'addFolder/fetchData',
  async (newFolder: IFolder, thunkApi) => {
    const { dispatch } = thunkApi;
    const rootState = thunkApi.getState() as RootState;
    const { foldersForPermissions } = rootState.permissions;
    const { dataRoom } = rootState.dataRoom;
    const { currentFolder, documents: sourceDocuments, folders: sourceFolders } = rootState.documents;
    const folderPath = currentFolder[currentFolder.length - 1]?.id || null;

    const folders = sourceFolders.map(folder => folder.id).includes(newFolder.id) 
      ? sourceFolders
      : [...sourceFolders, newFolder];      
    dispatch(setDocuments({ documents: sourceDocuments, folders }));
    dispatch(setDataRoom(dataRoom!));      
    folderPath === null && dispatch(setFoldersForPermissions([...foldersForPermissions, newFolder]));
  }
);

const deleteThisDocument = createAsyncThunk('document_delete/ws_message', async (payload: deletePayload, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { documents, currentFolder, folders } = state.documents;
    const folderPath = currentFolder.length - 1;

    if ((!currentFolder[folderPath] && !payload.folder_id) || payload.folder_id === currentFolder[folderPath].id) {
      const withoutDeletedDocuments = documents.filter((doc) => doc.id !== payload.document_id);
      dispatch(setDocuments({ documents: withoutDeletedDocuments, folders }));
      return withoutDeletedDocuments;
    }

    return null;
  } catch (err) {
    return thunkApi.rejectWithValue('Failed to delete document');
  }
});

const deleteThisFolder = createAsyncThunk('folder_delete/ws_message', async (payload: deleteFolderPayload, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { documents, folders } = state.documents;
    const { dataRoom } = state.dataRoom;
    const { foldersForPermissions } = state.permissions;

    dispatch(setDocuments({ documents, folders: folders.filter(({ id }) => id !== payload.folder_id) }));
    dispatch(setDataRoom({...dataRoom!, folders: folders.filter(({ id }) => id !== payload.folder_id) }));
    dispatch(setFoldersForPermissions(foldersForPermissions.filter(({ id }) => id !== payload.folder_id)));

    return null;
  } catch (err) {
    return thunkApi.rejectWithValue('Failed to delete document');
  }
});

const fetchDocumentUpdate = createAsyncThunk('document_update/ws_message', async (payload: deletePayload, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { documents, folders, selectedDocument, dataDocumentTable } = state.documents;
    const documentPermissions = dataDocumentTable.find(({ id }) => payload.document_id)?.permissions!;
    
    const response = await api.getDocumentById(payload.document_id);
    /// functionality fo document_replace
    const updating_document_id = payload.prev_version ? payload.prev_version : payload.document_id;

    dispatch(
      setDocuments({
        documents: documents.map((el) =>
          el.id === updating_document_id ? { ...response.data, permissions: documentPermissions } : el
        ),
        folders,
      })
    );

    selectedDocument?.id === response.data.id && dispatch(setSelectedDocument(response.data));
    return null;
  } catch (err) {
    return thunkApi.rejectWithValue('Failed to delete document');
  }
});

const updateDocumentSign = createAsyncThunk('document_signing/update', async (payload: IDocument, thunkApi) => {
  const state = thunkApi.getState() as RootState;
  const { dispatch } = thunkApi;
  const { documents, folders, dataDocumentTable } = state.documents;
  const documentPermissions = dataDocumentTable.find(({ id }) => payload.id)?.permissions!;    
  dispatch(setDocuments({
    documents: documents.map((el) =>
      el.id === payload.id ? { ...payload, permissions: documentPermissions } : el
    ),
    folders,
  }));
  // selectedDocument?.id === payload.id && dispatch(setSelectedDocument(payload));
  return null;
});

const fetchDocumentMove = createAsyncThunk('document_move/ws_message', async (payload: moveDocumentPayload, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { documents, folders, currentFolder } = state.documents;
    const folderPath = currentFolder[currentFolder.length - 1].id || null;

    if (payload.folder_id === folderPath) {
      const response = await api.getDocumentById(payload.document_id);
      dispatch(setDocuments({ documents: [...documents, response.data], folders }));
      return null;
    }

    return null;
  } catch (err) {
    return thunkApi.rejectWithValue('Failed to moved document');
  }
});

const fetchFoldersPermissions = createAsyncThunk(
  'permissions_update/ws_message',
  async (payload: updatePermissionsType, thunkApi) => {
    try {
      const state = thunkApi.getState() as RootState;
      const { dispatch } = thunkApi;
      const { currentFolder } = state.documents;
      const { userData } = state.userData;
      const { dataRoom } = state.dataRoom;

      if (userData?.email === payload.author) return null;

      if (payload.folder_id === null || currentFolder.find(({ id }) => id === payload.folder_id)) {
        localStorage.removeItem('previousFolderPath');
        dispatch(setCurrentFolder([]));
        dispatch(fetchUserPermissions({ idRoom: dataRoom?.id! }));
        dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
        return null;
      }

      return null;
    } catch (err) {
      return thunkApi.rejectWithValue('Failed to update permissions');
    }
  }
);

const updateSigningState = createAsyncThunk('signing_state/update', async (payload: any, thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { selectedDocument } = state.documents;
    // Если id документа, модальное окно с деталями по которому сейчас открыто (selectedDocument.id)
    // совпадает с payload.document_id - то обновляем стейт подписания
    if (selectedDocument && selectedDocument?.signing_status !== null && payload.document_id === selectedDocument?.id) {
      dispatch(fetchSigningState({id: selectedDocument?.id!}));
    }
  } catch (err) {
    return thunkApi.rejectWithValue('Failed to update signing');
  }
});

const fetchNewFolder = createAsyncThunk('folder_create/ws_message', async (payload: ISocketMessage, thunkApi) => {
  const rootState = thunkApi.getState() as RootState;
  const { dispatch } = thunkApi;
  const { dataRoom } = rootState.dataRoom;
  const { foldersForPermissions } = rootState.permissions;
  const { currentFolder, documents: sourceDocuments, folders: sourceFolders } = rootState.documents;

  const folderPath = currentFolder[currentFolder.length - 1]?.id || null;
  const isTheSameRoom = payload?.room_id === dataRoom?.id;
  const parentFolderID = currentFolder[0] ? currentFolder[0]?.id : null;
  const prepared_parent_folder_id = payload.parent_folder_id === 'None' ? null : payload.parent_folder_id;
  const isTheSameFolder = prepared_parent_folder_id === parentFolderID;
  const neededParams = payload.folder_id && payload.room_id;

  try {
    if (isTheSameRoom && isTheSameFolder && neededParams) {
      const newFolder = await api.getFolderOfFolder(payload.folder_id!, payload.room_id!);
      const folders = sourceFolders.map(folder => folder.id).includes(newFolder.data.id) 
        ? sourceFolders
        : [...sourceFolders, newFolder.data];      
        dispatch(setDataRoom({...dataRoom!, folders }));      
        dispatch(setDocuments({ documents: sourceDocuments, folders }));
      folderPath === null && dispatch(setFoldersForPermissions([...foldersForPermissions, newFolder.data]));
    };
  } catch (err) {
    return thunkApi.rejectWithValue('fetchNewFolder / Failed to fetch new folder');
  }
});

const fetchUpdatedRoom = createAsyncThunk('agreement_update/ws_message', async (
  payload: {socketData: ISocketMessage ,navigate: NavigateFunction , t: TFunction},
  thunkApi) => {
  try {
    const state = thunkApi.getState() as RootState;
    const { dispatch } = thunkApi;
    const { dataRoom } = state.dataRoom;
    const { available_rooms } = state.userData;

    const room = await api.getRoom(payload.socketData.room_id!);

    const newRooms = available_rooms.map((some_room) =>
      some_room.id === room.data.id
        ? {...room.data,
          created_at: Date.parse(String(room.data.created_at)),
          last_action_timestamp: Date.parse(String(room.data.last_action_timestamp || room.data.created_at))
        }
        : some_room
    ).sort((a,b) => b.last_action_timestamp - a.last_action_timestamp);
    dispatch(setRooms(newRooms || []));

    if (room.data.id === dataRoom?.id) {
      dispatch(setDataRoom({...room.data, need_to_update_terms: true}));
    
      if (room.data?.user_status === 1 || room.data?.access_terms_status === 'waiting_for_approve') {
        payload.navigate(`/room/${room.data?.id}/nda`);
        message.warning(payload.t('Documents.accessChanged'), 10);
      };
    };
  } catch (err) {
    return thunkApi.rejectWithValue('fetchUpdatedRoom / Failed to update room');
  }
});

export default fetchDocumentsData;
export {
  addNewFolder,
  deleteThisDocument,
  deleteThisFolder,
  fetchDocumentUpdate,
  updateDocumentSign,
  fetchDocumentMove,
  fetchFoldersPermissions,
  updateSigningState,
  fetchNewFolder,
  fetchUpdatedRoom,
};
