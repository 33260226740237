import { ConfigProvider, Layout, message } from 'antd';
import en_US from 'antd/es/locale/en_US';
import ru_RU from 'antd/es/locale/ru_RU';
import es_ES from 'antd/es/locale/es_ES';
import api from 'api';
import { HeaderComponent, Navigation } from 'components/Header';
import SessionComponent from 'components/SessionComponent/SessionComponent';
import WorkerComponent from 'components/SessionComponent/WorkerComponent';
import SideBar from 'components/SideBar/SideBar';
import systemPages, { getKeyFromLocation, technicalPages } from 'helpers/pagesAndLocation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchChatId, fetchGlobalChats } from 'store/reducers/chatCreator';
import { fetchRoom } from 'store/reducers/roomsCreator';
import { fetchUserData, fetchUserPermissions, fetchUserRooms } from 'store/reducers/userDataCreator';
import { setCurrentFolder } from 'store/slices/dataDocumentsSlice';
import { setFoldersForPermissions, setUserPermissions } from 'store/slices/dataPermissionsSlice';
import { fetchGlobalUsers } from 'store/reducers/usersGroupCreator';
import { setRooms, setUserAndRoomStatus, setUserData } from 'store/slices/userDataSlice';
import { setIsOpenSuccessfulPayment, setSelectedKeys } from 'store/slices/windowStateSlice';
import Spinner from 'components/Spinner/Spinner';
import classes from './Main.module.scss';
import cn from 'classnames';
import { setCheckingMessages } from 'store/slices/chatsSlice';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import ru from'dayjs/locale/ru';
import es from'dayjs/locale/es';
import en from'dayjs/locale/en';
import duration from 'dayjs/plugin/duration';
import SuccessfulPayment from 'components/Modals/SuccessfulPayment/SuccessfulPayment';

// @ts-ignore
const isBillingActive = window.REACT_APP_IS_BILLING_ACTIVE !== 'REPLACE_REACT_APP_IS_BILLING_ACTIVE' ? window.REACT_APP_IS_BILLING_ACTIVE : process.env.REACT_APP_IS_BILLING_ACTIVE;

dayjs.extend(duration);
dayjs.extend(relativeTime);

const languageConfigs = { ru: ru_RU, en: en_US, kg: en_US, es: es_ES };
const dayjsLocales = { ru, en, kg: en, es };

export default function Main() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const currentLanguageConfig = languageConfigs[i18n.resolvedLanguage as keyof typeof languageConfigs];
  const isMobile = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)
  dayjs.locale(dayjsLocales[i18n.resolvedLanguage as keyof typeof dayjsLocales] || en)
  
  const { userData, userAndRoomStatus, available_rooms } = useAppSelector((store) => store.userData);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);  
  const { isResizing, isOpenSuccessfulPayment } = useAppSelector((store) => store.windowState);

  const { pathname: location } = useLocation();
  const params = useParams();
  const selectedKeys = getKeyFromLocation(location, params.room);
  const isNeedToDisplayRoomNavigation = !selectedKeys.includes('mobile') && ![...systemPages, ...technicalPages].includes(selectedKeys);

  const cases = {
    pendingUserData: async () => {
      dispatch(setSelectedKeys([selectedKeys]));
      await dispatch(fetchUserData({}));
      await dispatch(fetchUserRooms({}));
    },

    emptyDataRoom: () => {      
      dispatch(setSelectedKeys(['rooms']));
      localStorage.removeItem('previousRoomId');
      localStorage.removeItem('previousFolderId');
      navigate('/rooms');
    },

    fulfilledUserData: async () => {
      const previousPage = localStorage.getItem('previousPage') || 'rooms';
      const chatId = params?.chatId || localStorage.getItem('previousChatId');
      const lastRoomId = params.room || localStorage.getItem('previousRoomId');

      if (!lastRoomId && systemPages.includes(previousPage)) {                
        const rightPath = previousPage === 'global-chats' && chatId ? `${previousPage}/${chatId}` : previousPage;
        previousPage ? navigate(rightPath) : navigate('/rooms');
        dispatch(setSelectedKeys([previousPage]));
      } else if (lastRoomId && !systemPages.includes(previousPage)) {
        await dispatch(fetchUserPermissions({ idRoom: lastRoomId }));
        await dispatch(fetchRoom({ id: lastRoomId }));
        dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
      } else if (params.room) {
        message.error(t('main.inaccessibleRoom'), 10);
        dispatch(setUserAndRoomStatus('emptyDataRoom'));
      }
      dispatch(fetchGlobalUsers());
      dispatch(fetchGlobalChats({ ownerId: userData?.id!, chatId: chatId || null }));
    },

    fulfilledDataRoom: async () => {      
      const checkingMessages = JSON.parse(localStorage.getItem(userData?.email!) || '[]');
      dispatch(setCheckingMessages(checkingMessages));
            
      // RETURNED BILLING
      if (isBillingActive === 'true') {
        if (dataRoom?.lives_to !== null) {        
          navigate(`/room/${dataRoom?.id}/endedTariff`);
          return;
        } else if ( dataRoom?.tariff?.tariff_name && dataRoom?.tariff_lifetime_to === null) {
          navigate(`/room/${dataRoom?.id}/awaitingPayment`);
          return;
        }
      }
      //
       
      const folderIdFromMemory = params.folderId || localStorage.getItem('previousFolderId');
      // запись пути папки (из URL или previousFolderId) в LocalStorage как 'previousFolderPath';
      if (folderIdFromMemory && dataRoom) await getFolderPath(folderIdFromMemory, dataRoom?.id);
      
      const previousFolderPath: any[] = JSON.parse(localStorage.getItem('previousFolderPath') || '[]');
      const previousPage = localStorage.getItem('previousPage') || 'rooms';
      const chatId = params?.chatId || localStorage.getItem('previousChatId');

      dispatch(setCurrentFolder(previousFolderPath))
      dispatch(fetchChatId({ roomId: dataRoom?.id!, ownerId: userData?.id!, chatId: chatId ||  null }));
      // if (chatId && chatData[chatId] && !chatData[chatId].isLoadedHistory) {
      //   await dispatch(fetchChatHistory({ chat_id: chatId, room_id: dataRoom?.id! }));
      // }

      const lastFolderId = previousFolderPath[previousFolderPath.length - 1]?.id;

      const entityId = previousPage === 'documents' && lastFolderId
        ? `${lastFolderId}`
        : chatId
          ? `${chatId}`
          : '';
      
      const locationWithId = !dataRoom?.id
        ? '/rooms'
        : `/room/${dataRoom?.id}/${previousPage}/${entityId}`;

      let navigatePath = systemPages.includes(previousPage)
        ? previousPage
        : locationWithId;

      if (isMobile && dataRoom?.id) navigatePath = `/room/${dataRoom?.id}/mobile`
      else if (isMobile && available_rooms[0]) navigate('/unsupported')

      dataRoom?.user_status === 1 || dataRoom?.access_terms_status == 'waiting_for_approve'
        ? navigate(`/room/${dataRoom?.id}/nda`)
        : navigate(navigatePath);
      
      localStorage.removeItem('previousChatId');
      getMyPermissions();
    },
  };

  React.useEffect(() => {
    return () => {
      dispatch(setUserData(null));
      dispatch(setRooms([]));
      dispatch(setUserAndRoomStatus('pendingUserData'));
    };
  }, []);

  React.useEffect(() => {
    try {
      (cases[userAndRoomStatus as keyof typeof cases] || function () {})();
    } catch(err) {
      message.error(t('Header.error.personalData'));
    }
  }, [userAndRoomStatus]);

  const getMyPermissions = async () => {
    const { data: foldersPermissionsReq } = await api.getFoldersPermissions({ room_id: dataRoom?.id! });
    const rootFolder = foldersPermissionsReq.find((el: any) => el.id === 0);
    const onlyFolders = foldersPermissionsReq.filter((el: any) => el.id !== 0);   
    const foldersWithPermissions = (onlyFolders[0] ? onlyFolders : dataRoom?.folders) || [];
    const foldersWithoutNull = foldersWithPermissions?.map(folder => ({
      ...folder,
      permissions: folder.permissions && folder?.permissions[0] ? folder.permissions : []
    }));
    
    dispatch(setUserPermissions({ documents: [], folders: foldersWithoutNull, rootFolder }));
    dispatch(setUserAndRoomStatus(''));
    dispatch(
      setFoldersForPermissions(foldersWithoutNull.map((folder) => ({ ...folder, key: `folder ${folder.id}`, type: 'folder' })))
    );
  };
  
  const getFolderPath = async (folderId: string, roomId: string) => {
    try {
      const folder = await api.getFolderById(folderId, roomId)
      const folderPath = folder.data.folder_path ? folder.data.folder_path?.reverse() : [];
      localStorage.setItem('previousFolderPath', JSON.stringify(folderPath));
    } catch (e) {
      message.error(t('main.inaccessibleFolder'), 10)
      localStorage.setItem('previousFolderPath', JSON.stringify([]));
    }
    localStorage.removeItem('previousFolderId');
  };

  if (isMobile && !userData) {
    return (
      <div className={classes.spinner}>
        <Spinner size={100} />
      </div>
    )
  }

  if (isMobile && userData) {
    return <SideBar />
  }

  if (isMobile && !userData) {
    return (
      <div className={classes.spinner}>
        <Spinner size={100} />
      </div>
    )
  }

  if (isMobile && userData) {
    return <SideBar />
  }

  return (
    <Layout className={cn(classes.mainDiv, isResizing ? classes.resizing : 'null')}>
      <ConfigProvider locale={currentLanguageConfig}>
        <HeaderComponent />

        <Layout>

          <SideBar />

          <div className={classes.outletWrap}>
            {isNeedToDisplayRoomNavigation && <Navigation />}
            <Outlet />
          </div>

          {window.SharedWorker ? <WorkerComponent /> : <SessionComponent />}
      
          {isOpenSuccessfulPayment && userData && (
            <React.Suspense fallback={<div />}>
              <SuccessfulPayment
                isOpen={isOpenSuccessfulPayment}
                onCancel={() => dispatch(setIsOpenSuccessfulPayment(false))}
              />
            </React.Suspense>
          )}
        </Layout>
      </ConfigProvider>
    </Layout>
  );
}
