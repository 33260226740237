import { Routes, Route, Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import Login from 'routes/Login/Login';
import SignUp from 'routes/SignUp/SignUp';
import Main from 'routes/Main/Main';
import Profile from 'routes/Profile/Profile';
import Documents from 'routes/Documents/Documents';
import Sides from 'routes/Sides/Sides';
import Settings from 'routes/Settings/Settings';
import Logs from 'routes/Logs/Logs'
import NDASection from 'routes/NDASection/NDASection';
import MainPageEmpty from 'routes/MainPageEmpty/MainPageEmpty';
import Chat from 'routes/Chat/Chat';
import Organization from 'routes/Organization/Organization';
import Rooms from 'routes/Rooms/Rooms';
import Confirm from 'routes/Confirm/Confirm';
import Report from 'routes/Report/Report';
import ResetPassword from 'routes/ResetPassword/ResetPassword';
import Reset from 'routes/ResetPassword/Reset';
import TempLink from 'routes/TempLink/TempLink';
import Mobile from 'routes/Mobile/Mobile';
import { getCookie } from 'helpers/cookies';
import OrganizationBook from 'routes/OrganizationBook/OrganizationBook';
import TFAuth from 'routes/TFAuth/TFAuth';
import Billing from 'routes/Billing/Billing'
import CacheBuster from 'react-cache-buster';
import packageFile from '../package.json'
import WrongAddress from 'routes/WrongAddress/WrongAddress';
import MobileRoom from 'routes/MobileRoom/MobileRoom';
import FAQ from 'routes/FAQ/FAQ';
import { getKeyFromLocation } from 'helpers/pagesAndLocation';
import { setIsOpenSuccessfulPayment } from 'store/slices/windowStateSlice';
import { useAppDispatch } from 'store/hook';
import AwaitingPayment from 'routes/AwaitingPayment/AwaitingPayment';
import './scss/app.scss';
import TariffEnded from 'routes/TariffEnded/TariffEnded';
import TariffsComponent from 'components/TariffsComponent/TariffsComponent';

export default function App() {
  // @ts-ignore
  const isBillingActive = window.REACT_APP_IS_BILLING_ACTIVE !== 'REPLACE_REACT_APP_IS_BILLING_ACTIVE' ? window.REACT_APP_IS_BILLING_ACTIVE : process.env.REACT_APP_IS_BILLING_ACTIVE;
  const isProduction = true;

  return (
    <CacheBuster
      currentVersion={packageFile.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      metaFileDirectory={'.'}
    >
      <Routes>
        <Route path='/login' element={<Authorization><Login /></Authorization>}/>
        <Route path='/signup' element={<Authorization><SignUp /></Authorization>}/>
        <Route path='/reset' element={<Authorization><Reset /></Authorization>}/>
        <Route path='/users/restore-password/:email' element={<Authorization><ResetPassword /></Authorization>}/>
        <Route path='/invite/:hash' element={<Authorization><SignUp /></Authorization>}/>
        <Route path='/users/register/accept/:user_id' element={<Authorization><Confirm /></Authorization>}/>
        <Route path='/temp_link/:document_token' element={<TempLink />}/>
        <Route path='/two_factor_authentication/:password' element={<TFAuth />}/>
        <Route path='/organization/book/:encoded_jwt' element={<OrganizationBook />} />
        <Route path='/unsupported' element={<Mobile />} />
        <Route path='/room/:room'>
          <Route index path='mobile' element={<Protected><MobileRoom /></Protected>} />
          <Route index path='mobile/:folderId' element={<Protected><MobileRoom /></Protected>} />
        </Route>
        <Route path='/' element={<Protected><Main /></Protected>}>
          <Route path='/billing' element={<Protected><Billing /></Protected>} />
          <Route path='/FAQ' element={<Protected><FAQ /></Protected>} />
          <Route path='/rooms' element={<Protected><Rooms /></Protected>} />
          <Route path='/report' element={<Protected><Report /></Protected>} />
          <Route path='/profile' element={<Protected><Profile /></Protected>} />
          <Route path='/noAvailablesRooms' element={<Protected><MainPageEmpty /></Protected>} />
          <Route path='/organization' element={<Protected><Organization/></Protected>} />
          {/* // RETURNED BILLING */}
          {
            isBillingActive === 'true'
              && <Route path='/tariffs' element={<Protected><TariffsComponent isProtected={true} /></Protected>}/>
          }
          <Route path='/global-chats'>
            <Route index element={<Protected><Chat /></Protected>}  />
            <Route path=':chatId' element={<Protected><Chat /></Protected>} />
          </Route>
          <Route path='/room/:room'>
            <Route index path='endedTariff' element={<Protected><TariffEnded/></Protected>} />
            <Route index path='awaitingPayment' element={<Protected><AwaitingPayment /></Protected>} />
            <Route index path='documents' element={<Protected><Documents/></Protected>} />
            <Route index path='documents/:folderId' element={<Protected><Documents/></Protected>} />
            <Route path='sides' element={<Protected><Sides /></Protected>}/>
            <Route path='settings' element={<Protected><Settings/></Protected>} />
            <Route path='logs' element={<Protected><Logs /></Protected>}/>
            <Route path='nda' element={<Protected><NDASection /></Protected>}/>
            <Route path='chat'>
              <Route index element={<Protected><Chat /></Protected>} />
              <Route path='document/:chatId' element={<Protected><Chat /></Protected>} />
              <Route path='user/:chatId' element={<Protected><Chat /></Protected>} />
            </Route>
          </Route>
        </Route>

        <Route path='*' element={<WrongAddress />}/>
      </Routes>
    </CacheBuster>
  );
}

const Protected = ({ children }: any) => {
  const accessToken = getCookie('athena-token');

  const { pathname: location } = useLocation();
  const params = useParams();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  if (searchParams.get('message') === 'successful_payment') dispatch(setIsOpenSuccessfulPayment(true));

  const documentId = searchParams.get('document_id');
  const modalSection = searchParams.get('modalSection');

  if (documentId) localStorage.setItem('document_id', documentId);
  if (modalSection) localStorage.setItem('modalSection', modalSection);
  
  if (params.room) {    
    const correctPathWithChatChildren = getKeyFromLocation(location, params.room);    
    params.room && localStorage.setItem('previousRoomId', params.room);
    if (params.folderId) {
      localStorage.setItem('previousFolderId', params.folderId);
    } else {      
      localStorage.removeItem('previousFolderId');      
      localStorage.removeItem('previousFolderPath');
    }
    params.chatId && localStorage.setItem('previousChatId', params.chatId);
    localStorage.setItem('previousPage', correctPathWithChatChildren);
  } else {
    const sectionOfRoom = location.split('/').filter(str => str !== '')[0];
    sectionOfRoom && localStorage.setItem('previousPage', sectionOfRoom);
    params.chatId && localStorage.setItem('previousChatId', params.chatId);
  };

  if (!accessToken) {
    return <Navigate to='/login' state={{ from: location }} />
  }

  return children;
}

const Authorization = ({ children }: any) => {
  if (getCookie('athena-token')) {
    return <Navigate to='/'/>
  }
  return children;
}
