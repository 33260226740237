export const license = {
  en: <></>,
  es: <></>,
  kg: <></>,
  ru: <>
    <p><b>Лицензионное соглашение на использование программы - виртуальная комната данных TazaDeal</b></p>
    <p><b><i>Перед началом использования виртуальной комнаты данных TazaDeal и/ или мобильного приложения TazaDeal, пожалуйста, ознакомьтесь с условиями нижеследующего Лицензионного соглашения.</i></b></p>
    <p><b><i>Принимая условия Лицензионного соглашения путем нажатия на соответствующую кнопку «Я принимаю условия соглашения» ниже или путем активации или использования программного обеспечения, Вы подтверждаете, что прочитали настоящее соглашение, поняли его содержание или обязуетесь выполнять его условия.</i></b></p>
    <p><b><i>Любое использование Вами программы означает полное и безоговорочное принятие Вами условий настоящего Лицензионного соглашения.</i></b></p>
    <p><b><i>Если Вы не принимаете условия Лицензионного соглашения в полном объёме, Вы не имеете права использовать программу в каких-либо целях.</i></b></p>

    <p><b>1. Общие положения</b></p>
    <p>1.1. Настоящее Лицензионное соглашение (далее - Соглашение) устанавливает условия использования программы виртуальная комната данных TazaDeal (далее  -   Программа) и заключено между любым лицом, использующим Программу, и Обществом с ограниченной ответственностью «Таза Диджитал Технолоджи»</p>
    <p>(ИНН 02106202210224, регистрационный номер 207099-3310-ООО, юридический адрес: Кыргызская Республика, г.Ош, ул.Ленина, д.422 (далее  -  Общество, Правообладатель))</p>
    
    <p>1.2. В рамках настоящего Соглашения используются следующие термины и определения:</p>
    <p><b>Общество/ Правообладатель</b> - Общество с ограниченной ответственностью «Таза Диджитал Технолоджи»;</p>
    <p><b>Пользователь</b> - любое физическое или юридическое лицо, использующее Программу на условиях настоящего Соглашения;</p>
    <p><b>Программа</b> – виртуальная комната данных TazaDeal, представляющая собой онлайн-репозиторий (комната данных) в облачном сервисе с набором дополнительных функций и сервисов, входящих в технологию TazaDeal, который предоставляется Пользователю для хранения данных, а также для предоставления контролируемого доступа к файлам третьим лицам;</p>
    <p><b>Соглашение</b> – лицензионное соглашение на использование программы – виртуальная комната данных TazaDeal, на основе которого Общество предоставляет Пользователю право (Лицензию) на использование Программы (и/ или мобильного приложения Программы) путем удаленного доступа для хранения файлов Пользователя, а также для предоставления контролируемого доступа к ним третьим лицам, в рамках созданной в Программе для Пользователя комнаты данных, на срок действия Соглашения, равного периоду Лицензии, а Пользователь обязуется оплатить стоимость Лицензии в рамках выбранного им тарифного плана и соблюдать условия Соглашения;</p>
    <p><b>Технология TazaDeal</b> – означает все веб-сайты, мобильные приложения, компьютерный код, программное обеспечение, оборудование, системы, сети, интерфейсы, процессы, методологии, алгоритмы, инструменты, технологии, изобретения и другие материальные или нематериальные технические материалы или информацию, используемые Обществом для предоставления услуг Пользователям, а также все их усовершенствования, модификации и производные;</p>
    <p><b>Лицензия</b> – основание, подтверждающее право Пользователя на использование Программы;</p>
    <p><b>Тариф</b> - ставка, устанавливаемая Обществом, по которой Общество реализует свои услуги по использованию Программы. Тарифные планы могут различаться по объему предоставляемых услуг, количеству пользователей комнаты данных, доступным функциям и стоимости;</p>
    <p><b>Пробный период</b> - бесплатный период использования Программы, предоставляемый Пользователю для ознакомления с функциями и тестирования Программы перед покупкой Лицензии;</p>
    
    <p>1.3. Осуществляя действия по использованию Программы, а также установке ее на мобильное устройство, Пользователь подтверждает свое полное и безоговорочное согласие со всеми условиями настоящего Соглашения и обязуется их соблюдать в соответствии с условиями и требованиям ст. 394 Гражданского кодекса Кыргызской Республики. Лицо, осуществляющее акцепт настоящего Соглашения, подтверждает Обществу, что обладает необходимыми полномочиями по акцептованию положений настоящего Соглашения.</p>
    
    <p>1.4. Лицо, принявшее условия настоящего Соглашения от имени юридического лица, подтверждает Обществу, что обладает необходимыми полномочиями по принятию положений настоящего Соглашения.</p>
    
    <p>1.5. Использование Программы разрешается только на условиях настоящего Соглашения. В случае несогласия с условиями настоящего Соглашения, Пользователь не имеет права и не должен использовать Программу. Использование Программы с нарушением, несоблюдением или частичным соблюдением какого-либо из условий настоящего Соглашения запрещено.</p>
    
    <p>1.6. Во всем, что не предусмотрено настоящим Соглашением, отношения между Обществом и Пользователем в связи с использованием Программы регулируются документами, указанными в п. 1.11 настоящего Соглашения.</p>
    
    <p>1.7. Использование Программы на условиях и способами, не предусмотренными настоящим Соглашением, возможно только на основании отдельного соглашения между Обществом и Пользователем.</p>
    
    <p>1.8. Настоящее Соглашение может быть изменено Обществом в любой момент без какого-либо специального уведомления, новая редакция Соглашения вступает в силу с момента ее размещения в Программе.</p>

    <p>1.9. В случае если Обществом были внесены какие-либо изменения в настоящее Соглашение, в порядке, предусмотренном п. 1.8. настоящего Соглашения, с которыми Пользователь не согласен, он обязан прекратить использование Программы.</p>

    <p>1.10. Используя Программу, Пользователь дает свое согласие на получение сообщений рекламного и информационного характера. Пользователь вправе отказаться от получения сообщений рекламного характера путем использования соответствующего функционала Программы или следуя инструкциям, указанным в полученном сообщении.</p>


    <p>1.11. Принимая условия настоящего Соглашения, Пользователь соглашается с тем, что неотъемлемыми частями Соглашения являются следующие документы, условия которых в полной мере распространяются на использование Программы:<br/>
      <span> - Общие условия использования виртуальной комнаты данных TazaDeal;</span><br/>
      <span> - Политика конфиденциальности ОсОО «Таза Диджитал Текнолоджи»;</span><br/>
      <span> - Политика использования файлов cookie;</span><br/>
      <span> - Соглашение об уровне обслуживания.</span><br/>
      <span> Актуальные версии документов размещены по адресу:</span> <a target="_blank" href="https://demo.tazadeal.com/ru/documents ">https://demo.tazadeal.com/ru/documents</a>.<br/>
    </p>

    <p><b>2. Права на использование Программы</b></p>

    <p>2.1. Исключительное право на Программу принадлежит Правообладателю и защищены законодательством Кыргызской Республики.</p>

    <p>2.2. Правообладатель на платной основе предоставляет Пользователю непередаваемое право использования Программы на условиях простой и неисключительной лицензии в рамках выбранного Пользователем тарифного плана на территории стран всего мира.</p>
    <p>Исключением является условия предоставления бесплатного (безвозмездного) пробного периода использования Программы, указанные в п. 3 настоящего Соглашения.</p>

    <p>2.3. Периодом действия Соглашения является, период времени (месяц или год) на который Пользователем добровольно приобретается Лицензия и внесена соответствующая плата Правообладателю. </p>
    <p>Период Лицензии, равный одному месяцу, начинается с даты оплаты Лицензии Пользователем и истекает в соответствующее число календарного месяца, следующего за датой оплаты Лицензии.</p>
    <p>Период Лицензии, равный одному году, начинается с даты оплаты Лицензии и истекает в соответствующие месяц и число календарного года, следующего за датой оплаты Лицензии.</p>
    <p>Настоящая Лицензия распространяется на использование Программы на территории всего мира, где технологически допускается использование Программы.</p>

    <p>2.4. Сведения о тарифных планах, предоставляемом объеме хранения и доступных функциях, а также размере платы за Лицензию, способах ее внесения и периоде действия Лицензии, а также иная информация, связанная с приобретением Лицензии размещены по адресу: <a target="_blank" href="https://demo.tazadeal.com/plans">https://demo.tazadeal.com/plans</a>. Данные сведения являются частью настоящего Соглашения и к ним применимы правила п. 1.9. Соглашения. Пользователь понимает и соглашается с тем, что указанный по адресу <a target="_blank" href="https://demo.tazadeal.com/plans">https://demo.tazadeal.com/plans</a> перечень прав использования Программы, может изменяться (дополняться и/или сокращаться).</p>
    
    <p>2.5. При оформлении Лицензии с помощью мобильного приложения Программы размер платы за Лицензию может отличаться от размера платы, указанного в вэб-версии Программы. Актуальный размере платы за Лицензию мобильной версии Программы указан на страницах магазина приложений.</p>
    <p>Стоимость Лицензии не облагается НДС в связи с применением льготы, предусмотренной законодательством Кыргызской Республики.</p>
    
    <p>2.6. Общество может предоставлять Пользователям скидки на стоимость Лицензии в рамках проведения акций. Общество в одностороннем порядке определяет размер скидки, а также срок и иные условия предоставления скидки. Условия предоставления скидки регулируются отдельными документами, которые должны быть раскрыты в интерфейсе Программы, и могут являться приложениями к настоящему Соглашению.</p>
    
    <p>2.7. Внесение оплаты за Лицензию производится Пользователем в порядке предоплаты. Лицензия является предоставленной Пользователю со всеми функциями Программы и в полном объеме с момента получения оплаты Обществом и предоставления Пользователю доступа к функциям Программы. Никакой иной момент не может трактоваться как момент исполнения Обществом принятых обязательств.</p>
    <p>Подтверждением факта платы за Лицензию является кассовый чек, направляемый Обществом (или его аффилированными лицами) Пользователю на адрес электронной почты, указанный при выставлении счета, либо при регистрации учетной записи в Программе, под которой Пользователь был авторизирован в момент произведения оплаты за Лицензию.</p>

    <p>2.8. При оформлении Лицензии Пользователь поручает Обществу каждый период продления Лицензии, определенный Пользователем при оформлении Лицензии, в порядке предоплаты взимать установленную Обществом на день оплаты плату за Лицензию (подписку) до момента отказа Пользователя от продления Лицензии на следующий период Лицензии на странице в интерфейсе Программы в профиле пользователя. Кроме того, Лицензия может быть прекращена Обществом в иных случаях либо по иным основаниям, предусмотренным настоящим Соглашением.</p>
    
    <p>2.9. Оплата Лицензии осуществляется Пользователем путем привязки банковской карты. Для целей настоящего Соглашения любая банковская карта, привязанная в интерфейсе Программы или в учетной записи Пользователя в Программе, включая мобильную версию , считается привязанной картой. Общество вправе списать сумму, составляющую стоимость Лицензии с любой привязанной Пользователем банковской карты.</p>
    <p>Отказ от оплаты Пользователем за соответствующий период Лицензии является отказом Пользователя от продления Лицензии. В таком случае права доступа Пользователя к расширенным возможностям Программы прекращаются в течение 30 календарных дней со дня, следующего за последним днем оплаченного периода Лицензии. </p>
    <p>В случае отсутствия достаточной суммы денежных средств на привязанной (-ых) карте (-ах) для продления Лицензии, Общество вправе прекратить предоставлять права доступа Пользователя к Программе по истечении 30 календарных дней с даты окончания последнего оплаченного периода Лицензии. </p>
    <p>Пользователь осведомлен и принимает условие, что, если по истечении 30 календарных дней плата за Лицензию на очередной период Пользователем не произведена, Общество вправе ограничить доступ к комнате данных Пользователя или некоторым функциям, и/или удалить данные из комнаты без получения согласия Пользователя на соответствующие действия.  </p>
    <p>Пользователь имеет право осуществить плату за Лицензию в любой момент до истечения 30 календарных дней с даты окончания последнего оплаченного периода Лицензии в целях возобновления Лицензии.</p>
    
    <p>2.10. Пользователь, принимая настоящее Соглашение, дает поручение Обществу от имени Пользователя составлять распоряжения о списании денежных средств со счета (к которому привязана банковская карта Пользователя) Пользователя в пользу Общества за Лицензию, а также направлять указанные распоряжения в банк-эмитент Пользователя через банк-эквайер.</p>

    <p>2.11. Пользователь дает свое согласие на автоматическое периодическое списание денежных средств с его счета в оплату Лицензии по его распоряжению, отданному в порядке, предусмотренном настоящим пунктом Соглашения, и признает, что распоряжения на списание денежных средств с его счета, направленные в соответствии с настоящим пунктом Соглашения, являются распоряжениями самого Пользователя, а действия процессингового центра и банка-эквайера, направленные на списание денежных средств в соответствии с настоящим пунктом Соглашения, выполнены с согласия Пользователя.</p>
    
    <p>2.12. Пользователь обязан самостоятельно отслеживать в своем профиле в Программе информацию о состоянии Лицензии (оплаченный Период Лицензии, стоимость Лицензии, перечень доступных прав использования Программы), а также отслеживать внесение изменений и дополнений в настоящее Соглашение.</p>

    <p>2.13. По общему правилу идентификация Пользователя осуществляется по данным его учетной записи. Общество вправе использовать для идентификации Пользователя иные данные, в том числе - данные о лице, вносившем плату, в случаях, когда такая идентификация не влечет нарушение прав сторон, прав третьих лиц, либо нарушение действующего законодательства.</p>

    <p>2.14. Односторонний отказ Пользователя, которому были переданы права, от приобретенного права использования Программы законом не предусмотрен, в связи с чем не подлежат возврату приобретенные Лицензии, возврат денежных средств за них не предусмотрен.</p>
    <p>2.15. Пользователь имеет право отменить подписку на приобретение Лицензии в любое время.  Вместе с тем плата за Лицензию за неиспользованный Пользователем период Обществом не возвращается.</p>
    <p>2.16. В случаях невозможности использования приобретенной Лицензии Пользователем по причинам независящим от Общества и не связанным с Технологией TazaDeal или Программой, например, ввиду отсутствия или длительного ограничения доступа Пользователя к сети Интернет или ограничениями, связанными с устройством пользователя и/или программным обеспечением, установленным на устройство пользователя, период Лицензии не приостанавливается и плата Обществом не возвращается.</p>
    <p>В иных случаях невозможности использования приобретенной Лицензии, связанным с Программой и Технологией TazaDeal , а также предоставлением недостоверной информации о Программе и условиях приобретения и использования Лицензии или оказанием Обществом услуг Пользователю ненадлежащего качества, Пользователь вправе потребовать от Общества предоставления ему в разумно короткий срок надлежащей информации, возврата уплаченной за Лицензию суммы и возмещения понесенных убытков в рамках законодательства Кыргызской Республики. </p>
    
    <p>Пользователь также имеет право на возврат средств за оплату Лицензии в следующих случаях:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Если Общество не предоставило услуги в соответствии с выбранным Пользователем тарифным планом в течение 30 дней с момента оплаты Лицензии;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Если Пользователь отменяет подписку на Программу в течение 14 дней с момента оплаты Лицензии, при условии, что Пользователь не использовал предоставленную комнату данных в течение указанного периода.</p>
      </li>
    </ul>

    <p>Возврат средств за оплату Лицензии не производится в следующих случаях:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Если Пользователь использовал Программу после оплаты Лицензии и в течение периода, на который была оплачена Лицензия;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Если Пользователь использовал Программу после оплаты Лицензии и в течение периода, на который была оплачена Лицензия, самостоятельно или путем передачи соответствующих прав администратора другим пользователям комнаты данных через Программу удалил созданную для него комнату данных;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Если Пользователь нарушил условия настоящего Соглашения, включая, но не ограничиваясь, незаконным использованием услуг или размещением запрещенного контента.</p>
      </li>
    </ul>

    <p>2.17. Для возврата денежных средств, уплаченных Пользователем за Лицензию, по основаниям, указанным в п. 2.16 Соглашения, Пользователь  должен обратиться с письменным требованием в Общество, направив обращение в электронном виде на адрес электронной почты: <a href="mailto:info@taza-digital.com">info@taza-digital.com</a>, или в бумажном виде на юридический адрес Общества: Кыргызская Республика, г.Ош, ул.Ленина, д.422 ОсОО «Таза Диджитал Технолоджи».</p>
    <p>Обращение должно содержать контактные данные пользователя, включая:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Фамилию Имя Отчество;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Адрес электронной почты, с которой связана учетная запись Пользователя в Программе;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Основание для возврата денежных средств, оплаченных Пользователем за Лицензию;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Контактный номер телефона для связи.</p>
      </li>
    </ul>

    <p>2.18. В случаях, предусмотренных законодательством Кыргызской Республики, при оплате Лицензии в сети Интернет, Общество обязано в течение 10 (десяти) дней со дня получения соответствующего требования Пользователя произвести возврат средств за оплату Лицензии.</p>
    <p>При возврате Обществом денежных средств, полученных в результате указанной оплаты Пользователем Лицензии, кассовый чек направляется Обществом (или его аффилированными лицами) на адрес электронной почты, указанный при выставлении счета, либо при регистрации учетной записи в Программе, под которой Пользователь был авторизирован в момент произведения платы за Лицензию.</p>

    <p>2.19. При оформлении Лицензии при помощи мобильного приложения TazaDeal оплата стоимости выбранной Пользователем Лицензии в магазине приложений App Store осуществляется с использованием технологии «Лицензия внутри Приложений» («In App Subscriptions») или «Покупка внутри Приложений» («In App Purchases»), предоставляемой компанией Apple Inc. Автоматическое возобновление доступа к Лицензии производятся на условиях, размещенных в сети Интернет по адресу: <a target="_blank" href="https://www.apple.com/legal/internet-services/itunes/ru/terms.html">https://www.apple.com/legal/internet-services/itunes/ru/terms.html</a>.</p>
    <p>Оплата стоимости выбранной Пользователем Лицензии в магазине приложений Google Play осуществляется с использованием технологии «Лицензия внутри Приложений» («SUBS») или «Покупка внутри Приложений» («INAPP»), предоставляемой компанией Google LLC. Автоматическое возобновление доступа к Лицензии производятся на условиях, размещенных в сети Интернет по адресу: <a target="_blank" href="https://play.google.com/intl/ru_ru/about/play-terms.html">https://play.google.com/intl/ru_ru/about/play-terms.html</a>.</p>
    <p>К Лицензии, приобретаемой с использованием мобильного приложения TazaDeal применяются все положения настоящего Соглашения.</p>
    
    <p>2.20. По настоящему Соглашению права на новые версии Программы не предоставляются Пользователю в рамках приобретенной Лицензии, кроме случаев если обновления и изменения Программы осуществляются по инициативе Правообладателя в целях устранения ошибок функционирования приобретенной Программы.</p>
    
    <p>2.21. В случае, если в состав Программы входит программное обеспечение третьих лиц, которое не является объектом права интеллектуальной собственности или иного права Правообладателя, то использование такого программного обеспечения третьих лиц регламентируется соответствующими лицензионными соглашениями правообладателей такого программного обеспечения. Соглашаясь с условиями настоящего Соглашения, Пользователь автоматически соглашается со всеми условиями лицензионных соглашений на программное обеспечение третьих лиц, входящее в состав Программы, текст которых размещен на сайте Правообладателя. Правообладатель гарантирует, что при соблюдении условий лицензий третьих лиц, Пользователь сможет использовать программное обеспечение третьих лиц в составе Программы свободно, без дополнительных выплат, и с объёмом прав в отношении такого программного обеспечения не меньшим, чем объем прав, предоставляемых в отношении программного обеспечения.</p>
    
    <p>2.22. Общество оставляет за собой право изменять условия тарифных планов приобретения Лицензии, включая стоимость и функционал, предварительно уведомив Пользователя не менее чем за 30 дней до вступления изменений в силу.</p>
    
    <p><b>3. Бесплатные возможности Программы</b></p>
    
    <p>3.1. Общество предоставляет Пользователю право на бесплатное использование Программы в рамках «пробного периода», который составляет 14 календарных дней в период одного календарного года. </p>
    
    <p>3.2. Датой начала «пробного периода» считается дата, с которой Пользователь выбрав соответствующий бесплатный тариф https://demo.tazadeal.com/plans   и создал учетную запись в Программе.</p>
    <p>Датой окончания «пробного периода» считается дата, которая наступает после завершения периода в 14 календарных дней.</p>

    <p>3.3. Общество при выборе Пользователем тарифа «пробного периода» предоставляет Пользователю одну комнату данных с объемом хранения и составом функций Программы, определенным в рамках соответствующего бесплатного тарифа <a target="_blank" href="https://demo.tazadeal.com/plans">https://demo.tazadeal.com/plans</a>.</p>

    <p>3.4. По истечении «пробного периода» файлы, загруженные Пользователем в комнату данных Программы, хранятся в течение 30 календарных дней с даты окончания «пробного периода». </p>

    <p>Если в течение 30 календарных дней с даты окончания «пробного периода» Пользователем не была оплачена Лицензия в рамках платных тарифов Общество вправе удалить данные без получения дополнительного согласия Пользователя на соответствующие действия.</p>

    <p>3.5. При оплате Лицензии в рамках платных тарифов Пользователю бесплатно предоставляется 1 (одна) «Личная комната данных» объемом хранения до 1 Гб. Условия и период хранения данных в «Личной комнате данных» соответствуют условиям Лицензии использования комнаты данных в Программе, приобретенной Пользователем в рамках выбранного тарифа.</p>

    <p><b>4. Функции Программы</b></p>
    <p>4.1. Программа предлагает Пользователю следующие функциональные возможности:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Хранение и обмен файлами;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Контроль доступа к комнате данных на основе ролей и прав доступа;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Управление папками и файлами, в том числе:</p>
        <ul>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Создание/ редактирование/ удаление папки;
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Настройка прав доступа к папке;
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Настройка разрешений на действия с файлами в папке;
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Загрузка/ скачивание, в том числе в формате pdf/ просмотр/ редактирование наименования/ удаление файлов, в том числе графических, аудио и видео;
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Предоставление доступа на просмотр и скачивание файла по ссылке без регистрации в системе с ограничением по сроку действия ссылки или количеству раз открытия ссылки;
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Согласование и подписание документов, с использования простой электронной подписи Программы; 
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Добавление комментариев и заметок к файлу;
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Создание отдельной ветки в чате для обсуждения файла;
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Поиск файлов в комнате данных;
          </li>
          <li style={{marginLeft: 40, marginBottom: 20, listStyleType: 'circle'}}>
            Сортировка файлов;
          </li>
        </ul>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Работа с конфиденциальными и чувствительными документами в рамках соглашения о неразглашении (NDA) или иными внутренними положениями и политиками Пользователя;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Обеспечение безопасности данных с помощью функций:</p>
        <ul>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Шифрования данных при хранении и передаче данных с использованием протоколов шифрования (AES-256);
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Резервного копирования данных;
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Управления доступом на основе ролей и настройки разрешений для ограничения доступа пользователей комнаты данных к конфиденциальным данным;
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Обеспечения дополнительного уровня безопасности с помощью двухфакторной аутентификации (2MFA) (электронное письмо с кодом для подтверждения действия);
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Добавления видимых цифровых водяных знаков и невидимой маркировки в файлах для защиты результатов интеллектуальной деятельности и контролируемого распространения копий документов;
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Записи всех действий пользователей в Программе, в том числе при работе с документами, ведение аудиторского следа; 
          </li>
          <li style={{marginLeft: 40, listStyleType: 'circle'}}>
            Автоматического отключения пользователей после определенного периода бездействия;
          </li>
          <li style={{marginLeft: 40, marginBottom: 20, listStyleType: 'circle'}}>
            Проведения внутренних регулярных оценок методов обеспечения безопасности и уязвимостей;
          </li>
        </ul>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Взаимодействие пользователей комнаты данных во внутреннем чат для обсуждения вопросов и файлов.</p>
      </li>
    </ul>

    <p>4.2. Доступ к данным и файлам, хранящимся в комнате данных Пользователя в Программе, контролируется Пользователем или уполномоченными Пользователем лицами, зарегистрированными в Программе пользователями.</p>

    <p>4.3. Общество вправе вносить любые изменения в структуру и алгоритмы работы функций, приостанавливать их работоспособность, изменять или прекращать выполнение функций без заблаговременного предупреждения Пользователя об этом.</p>

    <p>4.4. Использование Программы осуществляется с применением учетной записи, созданной Пользователем в Программе. Пользователь предупрежден и согласен с тем, что его  Имя, Фамилия, адрес электронной почты (логин, имя учетной записи) и контактный номер телефона указанные при регистрации (использовании) такого логина, а также изображение со страницы его профиля (аватар) могут быть отображены и доступны для ознакомления третьим лицам, в том числе в журнале действий Программы при предоставлении Пользователем прав доступа к комнате данных и файлам в отдельных папках и при получении Пользователем прав о доступа к данным, при оставлении Пользователем комментариев в чате комнаты данных, а также при направлении Пользователем приглашений и при принятии им приглашений третьих лиц.</p>

    <p>Пользователь несет полную ответственность за безопасность своей учетной записи и все действия, совершенные с ее использованием.</p>

    <p>4.5. Программа предоставляет функции Пользователям предоставлять доступ к отдельным файлам комнаты данных третьим лицам, которые не являются Пользователями Программы (отсутствует учетная запись в Программе) при условии указания фамилии, имени и отчества, а также адреса электронной почты третьего лица.</p>

    <p>4.6.  Используюя Программу, Пользователь соглашается с тем, что сотрудникам службы технической поддержки Общества могут быть доступны атрибуты файлов (название, тип, размер и т.п.), которые хранятся у Пользователя в комнате данных, для целей обработки запросов пользователей и предоставления технической поддержки, при этом доступ к содержанию файлов у сотрудников технической поддержки Общества отсутствует.</p>

    <p>4.7. Пользователь уведомлен и согласен с тем, что сотрудники технической поддержки Общества самостоятельно или с участием партнеров вправе осуществлять исследование и анализ файлов, хранящихся на серверах, предоставляемых Обществом Пользователю для хранения файлов, в целях поиска в них признаков наличия вредоносных программ и кодов и улучшения работы системы антивирусной защиты. Общество в целях защиты оборудования Пользователя и собственного оборудования вправе ограничивать и удалять с серверов любые файлы в случаях, когда в указанных файлах обнаружены признаки наличия вредоносных программ или кодов.</p>

    <p><b>5. Ограничения в использовании Программы</b></p>

    <p>5.1. Пользователь самостоятельно определяет, как использовать Программу, и несет ответственность за ее использование. Пользователь несет ответственность за соответствие содержания любого размещаемого Пользователем файла требованиям действующего законодательства, в том числе нормам международного права, включая ответственность перед третьим лицами в случаях, когда размещение Пользователем файла нарушает права и законные интересы третьих лиц.</p>

    <p>5.2. При использовании Программы Пользователь обязан воздержаться от размещения таких данных и от совершения таких действий, размещение которых и совершение которых запрещено Соглашением.</p>

    <p>Пользователь настоящим ознакомлен и соглашается с тем, что запрещено использование Программы и/или отдельных функций Программы в недобросовестных целях, совершение действий, направленных или влекущих нарушение прав и законных интересов третьих лиц.</p>

    <p>5.3. Общество имеет право заблокировать доступ к файлам, размещенным Пользователем, или удалить их, а также вправе передать третьей стороне данные о Пользователе, полученные в ходе предоставления Программы, в следующих случаях:</p>

    <p>5.3.1. если нормативный акт возлагает на Общество обязанность осуществить такие действия;</p>

    <p>5.3.2. если размещение файла создает угрозу нормальной работоспособности Программы;</p>

    <p>5.3.3. если Общество получило судебный акт или акт правоохранительного органа, возлагающий на Общество обязанность осуществить такие действия.</p>

    <p>5.4. Общество оставляет за собой право ограничить доступ Пользователя к Программе (или к определенным функциям Программы, если это возможно технологически) с использованием его учетной записи или полностью заблокировать учетную запись Пользователя при неоднократном нарушении настоящего Соглашения или документов, на которые оно ссылаются.</p>

    <p>5.5. Общество оставляет за собой право устанавливать любые правила, лимиты и ограничения (технические, включая ограничение скорости загрузки и скачивания, юридические, организационные или иные) на использование Программы, и может менять их по собственному усмотрению, без предварительного уведомления Пользователя. В случаях, когда это не запрещено законодательством, указанные правила, лимиты и ограничения могут быть различными для различных категорий Пользователей.</p>

    <p>5.6. Пользователь не вправе возмездно или безвозмездно передавать свои права и/или обязанности третьим лицам.</p>

    <p>5.7. Общество оставляет за собой право ограничить доступ Пользователя к Программе, а также удалить любые или все файлы и данные, хранящиеся в Программе, в следующих случаях:</p>

    <p>5.7.1. при превышении Пользователем установленных для соответствующего типа использования ограничений и/или лимитов, а также в случае изменения Пользователем типа файла с целью обхода правил или лимитов, предусмотренных в Программе. Общество вправе предупредить Пользователя о предстоящих блокировке и удалении файлов. В случае неполучения ответа и/или корректировки лимитов, Общество вправе по истечении 10 дней с момента первого уведомления, заблокировать Пользователя, а по истечении 30 календарных дней с момента блокировки безвозвратно удалить его файлы и данные из Программы.</p>

    <p>5.7.2. по истечении срока 30 дневного срока с даты окончания периода последней оплаченной Пользователем Лицензии;</p>
    
    <p>5.7.3. в случае систематического нарушения Пользователем настоящего Соглашения.</p>

    <p>5.8. Программа предоставляет Пользователю возможность распространять данные путем предоставления доступа к файлу одному пользователю или группе пользователей.</p>

    <p>5.9. Используя возможности Программы, указанные в п. 5. Соглашения, Пользователь обязан действовать добросовестно и воздержаться от злоупотребления данными функциями. </p>

    <p><b>6. Обязанности </b></p>

    <p>6.1.  Общество обязуется:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Добросовестно предоставлять услуги, связанные с приобретением Пользователем Лицензии на использование Программы;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Обеспечивать доступ к функционалу Программы в соответствии с условиями настоящего Соглашения и тарифом, выбранным Пользователем;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Защищать персональные данные Пользователя в соответствии с законодательством Кыргызской Республики;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Предоставлять техническую поддержку Пользователям в случае возникновения вопросов или проблем с использованием Программы.</p>
      </li>
    </ul>

    <p>6.2. Пользователь обязуется:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Использовать Программу только в законных целях;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Не размещать и не передавать материалы, содержащие секретную данные, а также данные, нарушающие права третьих лиц.</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>Уведомлять Общество о любых случаях несанкционированного доступа к своей учетной записи.</p>
      </li>
    </ul>

    <p><b>7.  Ответственность</b></p>

    <p>7.1. Общество обязуется предпринимать все разумные меры и осуществлять любые необходимые действия, направленные на обеспечение сохранности данных Пользователя и поддержание работоспособности Программы. Пользователь при этом осознает возможность возникновения технических неисправностей и сбоев в работе Программы и согласен с тем, что у Общества отсутствует техническая возможность предсказать их возникновение, уведомить о них Пользователя заблаговременно, либо полностью исключить вероятность их возникновения. Возникновение таких неисправностей или сбоев вне зависимости от причин и последствий не может быть основанием для применения к Обществу мер ответственности.</p>

    <p>7.2. Общество не обязано осуществлять модерацию, просмотр или иные действия в отношении размещаемых Пользователем в рамках Программы файлов, и не может гарантировать, что размещенные Пользователем в рамках Программы файлы не нарушают положений настоящего Соглашения, правовых актов, иных документов, установленных законодательством Кыргызской Республики. </p>

    <p>7.3. Общество не несет ответственности за любые виды убытков, возникшие вследствие использования или невозможности использования Пользователем Программы или отдельных его частей/функций, в том числе из-за возможных ошибок или сбоев в работе, за исключением случаев, прямо предусмотренных законодательством.</p>

    <p>7.4. Пользователь осознает и согласен с тем, что он должен самостоятельно оценивать все риски, связанные с использованием данных, размещенных в Программе, включая оценку надежности, полноты, безопасности, законности или полезности этих данных.</p>

    <p>Пользователь гарантирует, что является владельцем любых документов, копий документов и информации, обрабатываемых при использовании Программы или имеет право на такое использование, и несет полную ответственность за их обработку, использование и хранение.</p>

    <p>7.5. Пользователь несет ответственность за нарушение условий Соглашения, а также за неправомерное использование Программы в соответствии с действующим законодательством Кыргызской Республики.</p>

    <p>7.6. Любые данные, полученные с использованием Программы, Пользователь использует на свой собственный риск и самостоятельно несет ответственность за возможные последствия использования указанных данных, в том числе за ущерб, который это может причинить компьютеру или мобильному устройству Пользователя или третьим лицам, за потерю данных, нарушение прав или любой другой вред. </p>

    <p>7.7. Общество ни при каких обстоятельствах не несет ответственности перед Пользователями за фактические, косвенные и второстепенные убытки, включая в числе прочего убытки в результате: потери прибыли, потери доходов, потери и получения доступа к конфиденциальной информации, персональных данных и иной информации, прерывания деятельности, в результате любых других материальных или иных убытков, возникающих в связи с использованием или невозможностью использования Программы или документации, а также при использовании стороннего программного обеспечения, предоставляемого третьими лицами.</p>

    <p>7.8. Общество не несет ответственность за функционирование операционных систем, браузеров и сторонних системных компонентов, не включенных в Программу. Пользователь самостоятельно осуществляет поддержку функционирования операционных систем, браузеров и сторонних системных компонентов, обладая соответствующими правами на их использование, выполняя организацию обновления и обеспечивая мероприятия безопасности, а также используя иные необходимые для этого способы.</p>

    <p>7.9. Вне зависимости от места нахождения Пользователя или устройств, которые используются Пользователем при доступе к Программе, ко всем отношениям, связанным с использованием Программы, подлежит применению право Кыргызской Республики и любые требования или иски, возникающие из использования Программы, должны быть поданы и рассмотрены в суде по месту нахождения Общества.</p>

    <p>7.10. Общество может предоставить Пользователю перевод настоящего Соглашения с русского языка на другие языки, однако в случае противоречия между версией Соглашения на русском языке и ее переводом, юридическую силу имеет русскоязычная версия.</p>

    <p>7.11. Все вопросы, обращения и претензии, связанные с использованием/невозможностью использования Программы, а также возможным нарушением законодательства и/или прав третьих лиц, должны направляться через один из следующих каналов:</p>

    <p>-  через форму обратной связи в Программе;</p>

    <p>- через форму обратной связи по адресу: <a target="_blank" href="https://demo.tazadeal.com/about">https://demo.tazadeal.com/about</a>;</p>

    <p>- на адрес электронной почты Общества<a href="mailto:info@taza-digital.com">info@taza-digital.com</a>.</p>

    <p><b>8. Персональные данные</b></p>

    <p>8.1. Общество при предоставлении прав на использование Программы Пользователю собирает и обрабатывает персональные данные Пользователя в соответствии с Законом Кыргызской Республики "О персональных данных".</p>

    <p>8.2. Пользователь дает согласие на обработку своих персональных данных для целей, связанных с выполнением настоящего Соглашения.</p>

    <p>8.3. Правовые и организационные основания обработки Обществом персональных данных Пользователей Программы отражены в «Политике конфиденциальности ОсОО «Таза Диджитал Текнолоджи» по адресу: <a target="_blank" href="https://demo.tazadeal.com/ru/documents">https://demo.tazadeal.com/ru/documents</a>.</p>

    <p><b>9. Техническая поддержка </b></p>

    <p>9.1. Общество предоставляет Пользователю, приобретающему Лицензию на использование Программы, услуги технической поддержки 24 (двадцать четыре) часа в сутки, 7 (семь) дней в неделю, измеряемым в течение каждого календарного месяца. </p>

    <p>9.2. Условия предоставления технической поддержки при приобретении Лицензии отражены в документе «Соглашение об уровне использования виртуальной комнаты данных TazaDeal», являющимся необъемлемой частью настоящего Соглашения и размещенные по адресу: <a target="_blank" href="https://demo.tazadeal.com/ru/documents">https://demo.tazadeal.com/ru/documents</a>.</p>

    <p>9.3. Пользователь может обратить в техническую поддержку Общества через один из следующих каналов:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>через форму обратной связи в Программе;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>через форму обратной связи по адресу: <a target="_blank" href="https://demo.tazadeal.com/about">https://demo.tazadeal.com/about</a>;</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p>на адрес электронной почты Общества <a href="mailto:info@taza-digital.com">info@taza-digital.com</a>.</p>
      </li>
    </ul>

    <p><b>10. Заключительные положения</b></p>

    <p>10.1. Настоящее Соглашение регулируется законодательством Кыргызской Республики.</p>

    <p>10.2. Все споры, возникающие в связи с настоящим Соглашением, подлежат разрешению в судебных органах Кыргызской Республики.</p>

    <p>10.3. Соглашение вступает в силу с момента его акцепта Пользователем и действует до момента его расторжения.</p>

    <p style={{paddingBottom: 5}}>10.4. Принимая настоящее Соглашение, Пользователь подтверждает свое согласие на то, что Правообладатель вправе публиковать и использовать информацию о Пользователе в маркетинговых целях, которая включает в себя информацию о факте использования программного обеспечения Правообладателя, наименование лица и логотип (товарный знак) на безвозмездной основе.</p>
  </>
}
