import { requestTemplate } from 'api';
import { IDataRoom } from 'interfaces';

//ROOMS
const getUserRooms = () => requestTemplate.get<IDataRoom[]>('/rooms/');
const getRoom = (id: string) => requestTemplate.get<IDataRoom>(`/rooms/${id}/`);
const checkCustomUrl = (url: string) => requestTemplate.get<{ available: boolean }>(`/rooms/url_check/?url=${url}`);
const deleteSelectedUser = (user_id: string, room_id: string) => requestTemplate.delete<string>(`/rooms/user/?user_id=${user_id}&room_id=${room_id}`);

const createRoom = (payload: FormData) => requestTemplate.post<IDataRoom>(`/rooms/?action=create`, payload, {
  headers: { 'Content-Type': 'multipart/form-data' }
});

const setRoomSettings = (
  payload: FormData, roomId: string, action: string, document_name: string
) => requestTemplate.put<IDataRoom>(`/rooms/?id=${roomId}&action=${action}&document_name=${document_name}`, payload, {
  headers: { 'Accept': 'application/json', 'Content-Type': 'multipart/form-data' } 
});

const setRoomAgreement = (payload: FormData) => requestTemplate.post<any>(`/rooms/agreement/`, payload, {
  headers: { 'Content-Type': 'multipart/form-data' }
});

const setRoomAgreementState = (room_id: string) => requestTemplate.get<any>(`/documents/agreement/?room_id=${room_id}`);

const addOrganizationToSide = (
  payload: {room_id: string, organization_id: string}
) => requestTemplate.post<{ message: string }>(`/rooms/organization/add/`, payload);


const deleteRoom = (
  payload: { room_id: string },
) => requestTemplate.delete<any>(`/rooms/`,  { data: payload });

const toggleRoomWatermark = (payload: { id: string; enable: boolean }) =>
  requestTemplate.post<{ success: true; detail: 'Watermark was enabled successfully' }>('/rooms/watermark/', payload);


const rooms = {
  getUserRooms,
  getRoom,
  checkCustomUrl,
  deleteSelectedUser,
  createRoom,
  setRoomSettings,
  setRoomAgreementState,
  addOrganizationToSide,
  deleteRoom,
  toggleRoomWatermark,
  setRoomAgreement,
};

export default rooms;
