import api from 'api';
import { IColumn, IDocument } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Button, Dropdown, Empty, MenuProps, message, Table } from 'antd';
import TableLoader from 'components/Sceletons/TableLoader';
import { getStateColumns } from './columns/StateColumns';
import classes from './StateOfTerm.module.scss';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import { useAppSelector } from 'store/hook';
import { CheckOutlined, ClockCircleOutlined, CloseOutlined, EllipsisOutlined, FilePdfOutlined, PrinterOutlined } from '@ant-design/icons';
import { pdfHelper } from 'helpers/pdfHelper';

type StateOfTermType = {
  title: string;
  documentName: string;
  selectedTerm?: IDocument;
};

const StateOfTerm = ({title, documentName, selectedTerm}: StateOfTermType) => {
  const [isLoadingState, setIsLoadingState] = React.useState(false);
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [downloadProcess, setDownloadProcess] = React.useState<string[]>([]);
  const [isOpenDropdown, setIsOpenDropdown] = React.useState(false);

  const { userData } = useAppSelector((store) => store.userData);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);

  const { t, i18n } = useTranslation();

  const getTermsState = async () => {
    setIsLoadingState(true);
    try {
      const response = selectedTerm
        ? await api.getStateOfTerm(selectedTerm?.id!, dataRoom?.id!)
        : await api.setRoomAgreementState(dataRoom?.id!);

      const stateOfStatuses = response.data.members_status || response.data.rooms_members_status;      
      setDataSource(stateOfStatuses.map((user: any) => ({
        ...user,
        signed_date: user.accepted_at ? dayjs(user.accepted_at).valueOf() : null,
        status_access: user.status_access || user.user_in_room_status,
        key: user.id,
      })));
    } catch (e) {
      message.error(t('Settings.terms.errorUploadState'));
    } finally {
      setIsLoadingState(false);
    };
  };

  React.useEffect(() => {
    getTermsState();
  }, [selectedTerm]);

  React.useEffect(()=> {
    const stateColumns = getStateColumns(t);
    const customColumns = stateColumns.map((col: IColumn) => ({
      ...col,
      render: (value: any, info: any) => renderCell({value, info, col}),
    }))
    setModifiedColumns(customColumns);
  },[dataSource, i18n.language]);

  const getReport = async (type: string) => {
    setDownloadProcess(prev => [...prev, type]);
    const statusLocalized = {
      2: t('Settings.terms.state.accept'),
      3: t('Settings.terms.state.reject'),
      1: t('Settings.terms.state.nonAccept'),
      waiting_for_approve: t('Settings.terms.state.nonAccept'),
      denied: t('Settings.terms.state.reject'),
      approved: t('Settings.terms.state.accept'),
    };
    
    try {
      const dataForExport = dataSource.map(row => ({
        ...row,
        signed_date: row.signed_date ? getDateWithTimeZone(row.signed_date, userData?.date_format!) : '-',
        status_access: statusLocalized[row.status_access as keyof typeof statusLocalized],
      }));
      const tableColumns = getStateColumns(t);
      const titleText = [
        `${t('Settings.room.title')} - ${dataRoom?.name}`,
        `${title} - ${documentName}`,
      ]

      await pdfHelper<any>(
        dataForExport,
        tableColumns,
        titleText,
        type
      )
    } catch (e) {
      message.error(t(`Documents.details.agreement.${type}Error`));
    } finally {
      setDownloadProcess(downloadProcess.filter(el => el === type));
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'export',
      className: classes.dropdownItem,
      label: (
        <Button
          loading={downloadProcess.includes('export')}
          onClick={() => getReport('export')}
          icon={<FilePdfOutlined />}
          className={classes.dropdownButton}
          disabled={Boolean(downloadProcess[0]) || isLoadingState}
        >
          {t('Confirm.download')}
        </Button>
      ),
    },
    {
      key: 'print',
      className: classes.dropdownItem,
      label: (
        <Button
          loading={downloadProcess.includes('print')}
          onClick={() => getReport('print')}
          icon={<PrinterOutlined />}
          className={classes.dropdownButton}
          disabled={Boolean(downloadProcess[0]) || isLoadingState}
        >
          {t('Confirm.print')}
        </Button>
      ),
    },
  ];

  const getStatusRow = (status?: string | number) => {
    switch(status) {
      case 'denied':
        return <div className={classes.rowWrap}>
          <CloseOutlined className={classes.rejectIcon}/>
          <span className={classes.reject}>{t('Settings.terms.state.reject')}</span>
        </div>;
      case 'approved':
        return <div className={classes.rowWrap}>
          <CheckOutlined className={classes.acceptIcon}/>
          <span className={classes.accept}>{t('Settings.terms.state.accept')}</span>
        </div>;
      case 3:
        return <div className={classes.rowWrap}>
          <CloseOutlined className={classes.rejectIcon}/>
          <span className={classes.reject}>{t('Settings.terms.state.reject')}</span>
        </div>;
      case 2:
        return <div className={classes.rowWrap}>
          <CheckOutlined className={classes.acceptIcon}/>
          <span className={classes.accept}>{t('Settings.terms.state.accept')}</span>
        </div>;
      case 1: return <div className={classes.rowWrap}>
        <ClockCircleOutlined className={classes.notAcceptIcon}/>
        <span className={classes.nonAccept}>{t('Settings.terms.state.nonAccept')}</span>
      </div>
      default: 
        return <div className={classes.rowWrap}>
          <ClockCircleOutlined className={classes.notAcceptIcon}/>
          <span className={classes.nonAccept}>{t('Settings.terms.state.nonAccept')}</span>
        </div>;
    };
  };

  const renderCell = (props: any) => {
    const { value, info, col } = props;

    if(col.key === 'signed_date'){
      return value ? getDateWithTimeZone(value, userData?.date_format!) : '-';
    };

    if(col.key === 'status_access'){
      return getStatusRow(value)
    };

    return value;
  };

  if (isLoadingState) {   
    return (
      <div
        className={classes.tabWrap}
        style={{height: 'calc(100vh - 256px)', padding: 0}}
      >
        <TableLoader height={'calc(100vh - 212px)'}/>
      </div>
    )
  };

  return (
    <div
      className={classes.tabWrap}
      style={{ height: 'calc(100vh - 256px)', padding: 15 }}
    >

      <Dropdown
        open={isOpenDropdown || Boolean(downloadProcess[0])}
        onOpenChange={(open) => setIsOpenDropdown(open)}
        menu={{ items }}
        placement='bottomLeft'
      >
        <Button icon={<EllipsisOutlined />} className={classes.actionsButton} />
      </Dropdown>

      <Table
        style={{backgroundColor: 'white'}}
        dataSource={dataSource}
        columns={modifiedColumns}
        pagination={false}
        scroll={{ y: 'calc(100vh - 335px)' }}
        size='middle'
        locale={{
          emptyText: <Empty
            style={{ height: 'calc(100vh - 395px)', paddingTop: 100 }}
            description={t('Settings.terms.state.emptyTable')}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        }}
      />
    </div>
  );
}

export default StateOfTerm;
