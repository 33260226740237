import { Button, Modal, message } from 'antd';
import React from 'react';
import { useAppSelector } from 'store/hook';
import classes from './PaymentFlowSelection.module.scss';
import { ChoosePayment } from 'components/TariffsComponent/TariffsComponent';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import cn from 'classnames';
import api from 'api';

type PaymentFlowSelectionProps = {
  open: boolean
  onCancel: () => void;
  newRoomState: ChoosePayment;
  setNewRoomState: React.Dispatch<React.SetStateAction<ChoosePayment>>;
};

export default function PaymentFlowSelection({open, onCancel, newRoomState, setNewRoomState}: PaymentFlowSelectionProps) {
      
  const { available_rooms } = useAppSelector(store => store.userData);
  const { t } = useTranslation();
  const ended_tariff_lifetime = localStorage.getItem('ended_tariff_lifetime');

  const tariffName = `${t(`Tariffs.tariff_name.${newRoomState?.tariff?.tariff_name}`)} ${newRoomState?.tariff?.tariff_constraints.storage} ${t('Tariffs.gb')}`;
  const targetRoom = available_rooms.find(room => room.id === ended_tariff_lifetime);
  const naveOfExpiredRoom = targetRoom?.name;

  const payForRenewal = async () => {
    try {
      const paymentData = { ...newRoomState.tariff, room_id: targetRoom?.id };
      const { data } = await api.extendRoom(paymentData);        
      if (data) {
        window.location.href = data;
      };
      localStorage.removeItem('ended_tariff_lifetime');
      onCancel();
    } catch (e) {
      message.error(t(`Tariffs.flow_selection.error_link_creating`))
    }
  };

  return (
    <Modal 
      styles={{ body: {maxHeight: '85vh'} }}
      width={'auto'}
      title={`${t(`Tariffs.tariff`)} ${tariffName}`}
      open={open}
      centered={true}
      onCancel={onCancel}
      footer={null}
      className={classes.modalAddRoom}
    >
      <div className={classes.modalWrap}>
        <div className={classes.text}>{`${t(`Tariffs.flow_selection.want_to_pay`)} "${tariffName}":`}</div>
        <div className={classes.itemsWrap}>
          <div className={classes.item}>
            <div className={cn(classes.text, classes.textItem)}>{`${t(`Tariffs.flow_selection.gain_ccess`)} "${naveOfExpiredRoom}"`}</div>
            <Button
              type='primary'
              onClick={payForRenewal}
            >
              {t(`Tariffs.flow_selection.extend_access`)}
            </Button>
          </div>
          <div className={classes.item}>
            <div className={cn(classes.text, classes.textItem)}>{t(`Tariffs.flow_selection.for_new_room`)}</div>
            <Button
              type='primary'
              onClick={() => setNewRoomState({isOpenNewRoomModal: true, isChoosePaymentFlow: false, tariff: newRoomState.tariff})}
            >
              {t(`Tariffs.flow_selection.create_new`)}
             </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
