import { Empty, Modal, Tabs, TabsProps, Typography, message } from 'antd';
import api from 'api';
import axios, { AxiosError, CancelToken } from 'axios';
import RowLoader from 'components/Sceletons/RowLoader';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { TFunction } from 'i18next';
import { setCurrentFolder } from 'store/slices/dataDocumentsSlice';
import { IDataRoom, IDocument, IFolder, IUsers } from 'interfaces';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { resetUserPermissions, setRooms, setSelectedDataRoom } from 'store/slices/userDataSlice';
import DeleteRoomTreeSelect from './DeleteRoomTreeSelect';
import classes from './RoomAction.module.scss';
import { deleteStatusType } from './types';
import DeleteRoomUsersTable from './DeleteRoomUsersTable';
import { clearAllChatData } from 'store/slices/chatsSlice';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { setGroupChoosen, setGroups, setUserAndData } from 'store/slices/dataUsersSlice';
import { setDataForPermissionTable, setUserPermissions } from 'store/slices/dataPermissionsSlice';
import { resetOrgsAndSides } from 'store/slices/dataSidesSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { removeItemsFromLocalStorage } from 'helpers/cleanLocalStorage';

type Props = {
  room: IDataRoom;
  t: TFunction;
  isOpen: boolean;
  onCancel: () => void;
};

const knownErrors = ["This room doesn't exists", 'This room is on agreement mode', 'This room has related documents'];

export default function DeleteRoomModal({ room, t, isOpen, onCancel }: Props) {
  const [isDeletingRoom, setIsDeletingRoom] = React.useState(false);
  const [documents, setDocuments] = React.useState<IDocument[]>([]);
  const [folders, setFolders] = React.useState<IFolder[]>([]);
  const [users, setUsers] = React.useState<IUsers[]>([]);
  const [deleteStatus, setDeleteStatus] = React.useState<deleteStatusType>('pending');
  const [isLoadingDocuments, setIsLoadingDocuments] = React.useState(false);

  const { available_rooms } = useAppSelector((state) => state.userData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const clearRedux = () => {
    dispatch(setDataRoom(null));
    dispatch(clearAllChatData());
    dispatch(resetUserPermissions());
    dispatch(setUserAndData({ users: [], dataUserTable: [] }));
    dispatch(setGroups([]));
    dispatch(setGroupChoosen(null));
    dispatch(setCurrentFolder([]));
    dispatch(setDataForPermissionTable({ documents: [] }));
    dispatch(setUserPermissions(null));
    dispatch(resetOrgsAndSides());
    dispatch(setSelectedDataRoom(undefined));
    removeItemsFromLocalStorage();
  };

  React.useEffect(() => {
    const source = axios.CancelToken.source();
    fetchRoomDocuments(source.token);

    return () => source.cancel();
  }, []);

  const clearData = (clearCase: 'documents' | 'users') => {
    if (clearCase === 'documents') {
      setDocuments([]);
      setFolders([]);

      users.length === 0 && setDeleteStatus('deleteRoom');
    } else if (clearCase === 'users') {
      setUsers([]);

      documents.length === 0 && folders.length === 0 && setDeleteStatus('deleteRoom');
    }
  };

  const fetchRoomDocuments = async (sourceToken: CancelToken) => {
    try {
      setIsLoadingDocuments(true);
      const [response, foldersResponse, usersResponse] = await Promise.all([
        api.getDocuments({ room_id: room.id, action: '' }, sourceToken),
        api.getFolders(room.id, sourceToken),
        api.getUsers(room.id),
      ]);

      const withoutOwnerUsers = usersResponse.data.filter(({ id }) => id !== room.owner_id);
      setUsers(withoutOwnerUsers);
      setDocuments(response.data);
      setFolders(foldersResponse.data.map((folder) => ({ ...folder, type: 'folder' })));
      const isForDeleteElements = Boolean(
        withoutOwnerUsers.length || response.data.length || foldersResponse.data.length
      );
      setDeleteStatus(isForDeleteElements ? 'deleteElements' : 'deleteRoom');
    } catch (Err) {
      message.error('Download failed');
    } finally {
      setIsLoadingDocuments(false);
    }
  };

  const onDeleteClick = async () => {
    setIsDeletingRoom(true);
    try {
      await api.deleteRoom({ room_id: room?.id });
      const filteredRooms = available_rooms.filter((available_room) => available_room.id !== room.id);
      document.getElementById(`room_card_${room.id}`)?.classList.toggle(classes.slickHide);
      clearRedux();
      console.log(params.room, '<<params.room');
      
      params.room && navigate('/rooms');
      setTimeout(() => {
        dispatch(setRooms(filteredRooms));
      }, 400);

      message.success(`${t('Rooms.modals.delete.room')} '${room?.name}' ${t('Rooms.modals.delete.wasDeleted')}`);
    } catch (e: AxiosError | unknown ) {
      if (axios.isAxiosError(e)) {
        const errorText = e.response?.data?.detail?.message;
        const isKnownError = knownErrors.includes(errorText);

        isKnownError
          ? message.warning(
              `${t('Rooms.modals.delete.failedToDelete')} "${room?.name}". ${t(`Rooms.modals.delete.${errorText}`)}`,
              6
            )
          : message.error(
              `${t('Rooms.modals.delete.failedToDelete')} "${room?.name}": "${e?.message || 'unknown error'}"`,
              6
            );
      }
    } finally {
      onCancel();
      setIsDeletingRoom(false);
    }
  };

  const items: TabsProps['items'] = [
    {
      key: 'documents',
      children:
        !documents.length && !folders.length ? (
          <Empty rootClassName={classes.margin} description={t('Documents.table.fallback')} />
        ) : (
          <DeleteRoomTreeSelect t={t} clearData={clearData} room={room} folders={folders} documents={documents} />
        ),
      label: t('sidebar.documents'),
    },
    {
      key: 'users',
      children: !users.length ? (
        <Empty rootClassName={classes.margin} description={t('Users.table.empty')} />
      ) : (
        <DeleteRoomUsersTable room={room} users={users} clearData={clearData} t={t} />
      ),
      label: t('sidebar.users'),
    },
  ];

  const deleteCases = {
    pending: [1, 2, 3].map((el) => <RowLoader key={`loader-${el}`} width={480} height={60} />),
    deleteElements: (
      <div>
        <Typography.Title level={5}>{t('Rooms.modals.delete.deleteElementsTitle')}</Typography.Title>
        <Tabs items={items} />
      </div>
    ),
    deleteRoom: (
      <div>
        {t('Rooms.modals.delete.body')} <b>"{room?.name}"?</b>
      </div>
    ),
  };

  return (
    <Modal
      title={`${t('Rooms.modals.delete.title')} ${room?.name}`}
      onOk={onDeleteClick}
      className={classes.modals}
      okButtonProps={{
        disabled: isLoadingDocuments || Boolean(documents.length) || Boolean(folders.length) || Boolean(users.length),
      }}
      open={isOpen}
      onCancel={onCancel}
      confirmLoading={isDeletingRoom}
      centered
    >
      <div>{deleteCases[deleteStatus]}</div>
      {isDeletingRoom && <UploadingComponent />}
    </Modal>
  );
}
