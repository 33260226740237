import { MenuProps, message, Popover } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setRooms, setSelectedDataRoom, setUserAndRoomStatus } from 'store/slices/userDataSlice';
import { fetchRoom } from 'store/reducers/roomsCreator';
import { fetchUserPermissions } from 'store/reducers/userDataCreator';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';
import classes from './RoomsTab.module.scss';
import React from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';
import { setFoldersForPermissions } from 'store/slices/dataPermissionsSlice';
import { ConfirmModal, DeleteRoomModal, EditRoomModal, NewRoomModal } from 'components/Modals';
import RoomsTabControl from './RoomsTabControl';
import { IDataRoom } from 'interfaces';
import EmptyRoom from './EmptyRoom';
import RowLoader from 'components/Sceletons/RowLoader';
import Mark from 'mark.js';
import RoomCard from './RoomCard';
import systemPages from 'helpers/pagesAndLocation';
import api from 'api';
import { setNotifications, setNotificationsStatus } from 'store/slices/dataRoomSlice';
import dayjs from 'dayjs';
import { Tarrifs } from 'components/TariffsComponent/tariffsData';

// @ts-ignore
const isBillingActive = window.REACT_APP_IS_BILLING_ACTIVE !== 'REPLACE_REACT_APP_IS_BILLING_ACTIVE' ? window.REACT_APP_IS_BILLING_ACTIVE : process.env.REACT_APP_IS_BILLING_ACTIVE;

type RoomsTabProps = {
  clearRedux: () => void;
};

const RoomsTab = ({clearRedux}: RoomsTabProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isNewRoomModal, setIsNewRoomModal] = React.useState(false);
  const [isOpenConfrimInviteModal, setIsOpenConfrimInviteModal] = React.useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState(false);
  const [selectedToConfirmRoom , setSelectedToConfirmRoom] = React.useState<IDataRoom | null>(null);
  const [searchValue, setSearchValue] = React.useState('');

  const { userData, selectedRoom, isLoadingUserData, available_rooms, isLoadingRooms } = useAppSelector((store) => store.userData);
  const { dataRoom, isLoadingRoom, notifications } = useAppSelector((store) => store.dataRoom);
  const { selectedKeys } = useAppSelector((store) => store.windowState);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();  

  const searchedRooms = React.useMemo(
    () => available_rooms.filter((room) => room.name.toLowerCase().includes(searchValue.toLocaleLowerCase())),
    [searchValue, available_rooms]
  );  

  const onEditRoomCancel = () => setIsEditModalOpen(false);
  const onDeleteRoomCancel = () => setIsDeleteModalOpen(false);

  const onOpenClick = async (_: any, room: IDataRoom, isLoadingRoom: boolean, option: string = 'documents') => {
    if (room?.invite_id){
      setIsOpenConfrimInviteModal(true);
      setSelectedToConfirmRoom(room);
      return;
    }
    let rightOption = room?.access_terms_status == 'waiting_for_approve' ? 'nda' : option;
    
    if ((dataRoom?.id !== room.id || systemPages.includes(selectedKeys[0])) && !isLoadingRoom) {
      clearRedux();      
      dispatch(setUserAndRoomStatus(''))
      dispatch(setFoldersForPermissions([]));
      dispatch(setSelectedKeys([`${rightOption}`]));
      dispatch(setSelectedDataRoom(room));
      navigate(`/room/${room.custom_url || room.id}/${rightOption}`);

      // RETURNED BILLING
      if (isBillingActive === 'true') {
        if (room.lives_to !== null) {
          navigate(`/room/${room?.id}/endedTariff`);
          return;
        } else if (room?.tariff?.tariff_name && room?.tariff_lifetime_to === null) {        
          navigate(`/room/${room?.id}/awaitingPayment`);
          return;
        }
      }
      // 
      await dispatch(fetchRoom({ id: room.id }));
      
      await dispatch(fetchUserPermissions({ idRoom: room.id }));
      dispatch(setUserAndRoomStatus('fulfilledDataRoom')); // были проблемы с обновлением ло статуса fulfilledDataRoom для этого в начале стоит setUserAndRoomStatus('')
      // navigate(`/room/${room.custom_url || room.id}/${rightOption}`);
    } else {
      return;
      // navigate(`/room/${room.custom_url || room.id}/${rightOption}`);
    }
  };

  const onEditClick = (e: MenuInfo, room: IDataRoom) => {
    e.domEvent.stopPropagation();
    setIsEditModalOpen(true);    
    dispatch(setSelectedDataRoom(room));
  };
    
  const onDeleteClick = (e: MenuInfo, room: IDataRoom) => {
    e.domEvent.stopPropagation();
    dispatch(setSelectedDataRoom(room));
    setIsDeleteModalOpen(true);
  };

  const toRoomSettings = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, room: IDataRoom) => {
    e?.stopPropagation();
    navigate(`/room/${room.custom_url || room?.id}/settings`);
  }

  const markText = (text: string, markerRef: React.RefObject<HTMLDivElement>, timeout?: number ) => {    
    const markInstance = new Mark(markerRef.current as HTMLElement);
    
    setTimeout(() => {
      markInstance.unmark({
        className: classes.mark,
        done: () => {
          markInstance.mark(text, {
            className: classes.mark,
          });
        }
      });
    }, timeout || 100);
  }

  const controlItems = (room: IDataRoom): MenuProps['items'] => {
    const isOwner = userData?.id === room.owner_id;
    const isPaidTariff = Boolean(room?.tariff?.tariff_name && room?.tariff_lifetime_to !== null)
      && room?.tariff?.tariff_name !== Tarrifs.Trial;

    let items: MenuProps["items"] = [
      {
        key: 'edit',
        label: (
          <span>
            <EditOutlined /> {t('Users.HelperMenu.edit')}
          </span>
          ),
        onClick: (e) => onEditClick(e, room),
        disabled: !room.permissions?.can_edit_room_settings,
      },
      {
        key: 'delete',
        label: isPaidTariff 
          ? (<Popover title={t('Rooms.popovers.paidRoom')}>
            <span>
              <DeleteOutlined /> {t('Users.HelperMenu.delete')}
            </span>
          </Popover>)
          : (isOwner
            ? (<span>
              <DeleteOutlined /> {t('Users.HelperMenu.delete')}
            </span>)
            : (<Popover title={t('Rooms.popovers.delete')}>
              <span>
                <DeleteOutlined /> {t('Users.HelperMenu.delete')}
              </span>
            </Popover>)
          ),
        onClick: (e) => onDeleteClick(e, room),
        disabled: !isOwner || isPaidTariff,
      },
    ]

    const isAvailableSettings = room?.permissions?.can_edit_room_settings
      && room.lives_to === null
      && !(room?.tariff?.tariff_name && room?.tariff_lifetime_to === null);
      
    if (isAvailableSettings) {
      items.push({
        key: 'settings',
        label:(
          <span>
            <SettingOutlined className={classes.iconBtn} /> {' '}
            {t('sidebar.settings')}
          </span>
        ),
        onClick: (e) => {
          e.domEvent.stopPropagation();
          onOpenClick(e, room, isLoadingRoom, 'settings');
        },
      })
    };

    return items;
  }

  const onConfirmClose = () => setIsOpenConfrimInviteModal(false);
  const onRejectInvite = async () => {
    setIsConfirmLoading(true);
    try {
      const response = await api.declineInvite(selectedToConfirmRoom?.invite_id!);
      message.success(t('Header.Notification.invites.success.declined'));
      dispatch(setNotificationsStatus('pending'));
      dispatch(
        setNotifications(
          notifications.map((not, idx) =>
            not.details.invite_id === selectedToConfirmRoom?.invite_id
              ? { ...not, details: { ...not.details, invite_status: 2 as any } }
              : not
          )
        )
      );
      
      dispatch(setRooms(available_rooms.filter(({ id }) => id !== selectedToConfirmRoom?.id)!));
      setTimeout(() => dispatch(setNotificationsStatus('fulfilled')), 10);
    } catch (Err) {
      message.error(t('Header.Notification.invites.error.declined'));
    } finally {
      onConfirmClose();
      setIsConfirmLoading(false);
    }
  };

  const onAcceptInvite = async () => {
    try {
      setIsConfirmLoading(true);
      const respRoom = await api.acceptInvite(selectedToConfirmRoom?.invite_id!);
      message.success(t('Header.Notification.invites.success.accepted'));
      dispatch(setNotificationsStatus('pending'));

      dispatch(
        setNotifications(
          notifications.map((not, idx) =>
            not.details.invite_id === selectedToConfirmRoom?.invite_id
              ? { ...not, details: { ...not.details, invite_status: 1 as any } }
              : not
          )
        )
      );
      const UTCDate = dayjs().valueOf() + new Date().getTimezoneOffset() * 60 * 1000;
    
      dispatch(setRooms(available_rooms.map((room) =>
        room.id === selectedToConfirmRoom?.id
        ? {
            ...room,
            invite_id: undefined,
            agreement_enable: respRoom.data.agreement_enable,
            access_terms_status: respRoom.data.access_terms_status,
            last_action_timestamp: respRoom.data.last_action_timestamp || UTCDate,
            created_at: respRoom.data.created_at,
            lives_to: null,
          }
        : room
      )));
      setTimeout(() => dispatch(setNotificationsStatus('fulfilled')), 10);
    } catch (Err) {
      message.error(t('Header.Notification.invites.error.accepted'));
    } finally {
      onConfirmClose();
      setIsConfirmLoading(false);
    }
  };  

  return (
    <div>
      <RoomsTabControl
        setIsVisibleModal={setIsNewRoomModal}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isLoadingUserData={isLoadingUserData || isLoadingRooms}
      />
      {
        isLoadingRooms
          ? <div style={{marginRight: 10}}>
            <RowLoader width={600} padding={'0 0 0 10px'} height={70} borderradius={5}/>
            <RowLoader width={600} padding={'0 0 0 10px'} height={70} borderradius={5}/>
          </div>
          : <div className={classes.roomCardsWrap}>
            {(!available_rooms[0] && !isLoadingUserData) || isLoadingRooms
              ? <EmptyRoom />
              : searchedRooms?.map((room) => {
                return(
                  <div key={room.id}>
                    <RoomCard
                      room={room}
                      onOpenClick={onOpenClick}
                      controlItems={controlItems}
                      toRoomSettings={toRoomSettings}
                      searchValue={searchValue}
                      markText={markText}
                      dateFormat={userData?.date_format || 'international'}
                    />
                  </div>
                )
              })
            }
          </div>
      }

      {isDeleteModalOpen && (
        <React.Suspense fallback={<div />}>
          <DeleteRoomModal t={t} room={selectedRoom!} onCancel={onDeleteRoomCancel} isOpen={isDeleteModalOpen} />
        </React.Suspense>
      )}

      {isEditModalOpen && (
        <React.Suspense fallback={<div />}>
          <EditRoomModal t={t} room={selectedRoom!} onCancel={onEditRoomCancel}  isOpen={isEditModalOpen} />
        </React.Suspense>
      )}

      {isNewRoomModal && (
        <React.Suspense fallback={<div />}>
          <NewRoomModal open={isNewRoomModal} onCancel={() => setIsNewRoomModal(false)}/>
        </React.Suspense>
      )}

      {isOpenConfrimInviteModal && (
        <React.Suspense fallback={<div />}>
          <ConfirmModal
            okText={t('Confirm.accept')}
            cancelText={t('Confirm.reject')}
            bodyContent={t('Rooms.modals.confirmInvite.body')}
            onCancel={onRejectInvite}
            onClose={onConfirmClose}
            onOk={onAcceptInvite}
            loading={isConfirmLoading}
            open={isOpenConfrimInviteModal}
            title={t('Rooms.modals.confirmInvite.title')}
          />
        </React.Suspense>
      )}
    </div>
  );
}

export default RoomsTab;
