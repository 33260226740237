import React from 'react';
import classes from './AccessTerms.module.scss';
import { Button, Card, message, Upload, UploadFile } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';
import { IDocument } from 'interfaces';
import { RcFile } from 'antd/es/upload';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import { ItemRender } from 'antd/es/upload/interface';
import api from 'api';
import { useAppDispatch, useAppSelector } from 'store/hook';
import Spinner from 'components/Spinner/Spinner';
import ActiveTerm from './components/ActiveTerm';
import ModalTerm from './components/ModalTerm';
import dayjs from 'dayjs';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { setRooms } from 'store/slices/userDataSlice';
import { ConfirmationModal } from 'components/Modals';

const { Dragger } = Upload;

const AccessTerms = () => {
  const [needShowWarn, setNeedShowWarn] = React.useState(false);
  const [isLoadingTerms, setIsLoadingTerms] = React.useState(true);
  const [isUploading, setIsUploading] = React.useState(false);
  const [modalTermOpen, setModalTermOpen] = React.useState(false);
  const [confirmUploadingModal, setConfirmUploadingModal] = React.useState(false);
  const [maxFileCount, setMaxFileCount]= React.useState(10);
  const [fileList, setFileList] = React.useState<RcFile[]>([]);
  const [activeTerms, setActiveTerms] = React.useState<IDocument[]>([]);
  const [selectedTerm, setSelectedTerm] = React.useState<IDocument | null>(null);

  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { userPermissions, available_rooms } = useAppSelector((state) => state.userData);
  
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getTermsOfAccess = async () => {
    setIsLoadingTerms(true);
    try {
      const versions = await api.getTermsOfAccess(dataRoom?.id!);
      setMaxFileCount(10 - versions.data.length)
      setActiveTerms(versions.data.map(term => ({
        ...term,
        user_status: {
          ...term.user_status!,
          accepted_at: dayjs(term.user_status?.accepted_at).valueOf(),
        }
      })));
      dispatch(setDataRoom({...dataRoom!, need_to_update_terms: false}));
    } catch (e) {
      message.error(t('Settings.terms.uploadFile.accessTermsError'));
    } finally {
      setIsLoadingTerms(false);
    }
  };

  React.useEffect(() => {
    dataRoom && getTermsOfAccess();
  }, [dataRoom?.id, dataRoom?.need_to_update_terms]);

  React.useEffect(() => {    
    needShowWarn && message.warning(t('Settings.terms.uploadFile.accessTermsOverflow'), 5)
    setNeedShowWarn(false);
  }, [needShowWarn]);

  React.useEffect(() => {
    if (dataRoom?.access_terms_status == 'waiting_for_approve' && activeTerms[0]) {
      const allStatuses = activeTerms.map(term => String(term.user_status?.status_access));
      const isAllTermsAccepted = Boolean(!allStatuses.reduce((sum: number, current: string) => {
        if (current !== 'approved') sum++
        return sum
      }, 0));

      if (isAllTermsAccepted) {
        dispatch(setDataRoom({
          ...dataRoom,
          access_terms_status: 'approved',
        }));
        dispatch(setRooms(available_rooms.map((room) =>
          room.id === dataRoom.id
            ? { ...dataRoom, access_terms_status: 'approved' }
            : room
        )));
      }
    }
  }, [activeTerms]);

  const onRemove = (file: UploadFile<any>) => setFileList(fileList.filter((currentFile)=> file.uid !== currentFile.uid));

  const fileNames = React.useMemo(() => [...activeTerms, ...fileList].map((data) => data.name), [fileList, activeTerms]);

  const onBeforeUpload = (file: RcFile, allFiles: RcFile[]) => {
    if (fileNames.includes(file.name)) {
      message.error(t('Settings.terms.uploadFile.uniqueFiles'), 5);
      return false;
    }
    if (fileList.length < maxFileCount) {
      setFileList((prev) => {
        if (prev.length < maxFileCount) {
          return [...prev, file];
        } else {
          setNeedShowWarn(true);
          return prev;
        }
      });
      return false;
    }
  }

  const iconRender = (file: UploadFile) => {
    const formatNDA = file?.name?.split('.')?.reverse()[0];  
    const icon = availableIconFormat.includes(formatNDA) ? formatNDA : 'default';
    return documnentIcons[icon as keyof typeof documnentIcons];
  };

  const itemRender: ItemRender = React.useMemo(
    () =>
      (originNode, doc, fileList, { remove }) => (
        <div className={classes.fileItem}>
          {originNode} {!isUploading && <DeleteOutlined className={classes.deleteIcon} onClick={remove} />}
        </div>
      ),
    [isUploading]
  );
  
  const acceptedTerms = activeTerms.reduce((acc: number, currnetTerm: IDocument) => {
     if (currnetTerm?.user_status?.status_access === 'approved') acc+= 1
    return acc;
  }, 0);
  
  const saveTerms = async () => {
    setIsUploading(true);
    setConfirmUploadingModal(false);
    if(fileList.length > 0) {
      await Promise.all(fileList.map((file) => {
        const formData = new FormData();
        formData.append('document', file as RcFile);
        formData.append('room', dataRoom?.id!);

        return api.uploadTerm(formData);
      }))
      .then(values => {
        message.success(t('Settings.terms.uploadFile.successAdded'));
        const uploadedTermsIds = values.map((response) => response.data.name);
        setFileList(prev => prev.filter(el => !uploadedTermsIds.includes(el.name)))
        getTermsOfAccess();
      })
      .catch((error: any) => {
        message.error(t('Settings.terms.uploadFile.errorAdding'))
      })
      .finally(() => {
        setIsUploading(false)
      })
    };
  };

  return (
    <Card className={classes.termsCard} title={t('Settings.terms.titleTerms')} bordered>
      {userPermissions?.can_edit_room_settings && <>
        <Dragger
          name='file'
          listType='picture'
          multiple={true}
          className={classes.dragger}
          showUploadList
          disabled={fileList.length >= maxFileCount || dataRoom?.access_terms_status === 'waiting_for_approve'}
          onRemove={onRemove}
          beforeUpload={onBeforeUpload}
          fileList={fileList}
          iconRender={iconRender}
          openFileDialogOnClick={fileList.length < maxFileCount ? true : false}
          itemRender={itemRender}
          accept='.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf'
        >
          {
              fileList.length < 10
                ? <>
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
          
                  {!fileList[0]
                    ? <p className='ant-upload-text'>{t('Settings.terms.uploadFile.selectAccessTerms')}</p>
                    : <p className='ant-upload-text'>{t('Settings.terms.uploadFile.addMoreTerms')}</p>
                  }
                  <p className='ant-upload-hint'>{t('Settings.terms.uploadFile.subtitle')}</p>
                </>
              : <p className='ant-upload-text'>{t('Settings.terms.uploadFile.10TermsAlready')}</p>
            }
        </Dragger>
        
        {fileList[0] && <div className={classes.saveButton}>
            <Button
              type='primary'
              loading={isUploading}
              onClick={() => setConfirmUploadingModal(true)}
              // disabled={!isAvailableToUpdate}
            >
              {t('main.saveButton')}
            </Button>
          </div>}
      </>}

      {
        activeTerms[0] && <div className={classes.activeTermsWrap}>
          <div className={classes.activeTermsTitle}>
            <span>{t('Settings.terms.activeTermsOfAccess')}.</span>
            <span className={classes.status}>(
              <span>{t('Settings.terms.acceptedTerms')}</span>
              <span style={{paddingLeft: 4}}>
                {`${acceptedTerms}/${activeTerms?.length}`}
              </span>
            )</span>
          </div>
          {activeTerms.map((term, i) => (
            <ActiveTerm
              key={term.id}
              document={term}
              setIsOpen={setModalTermOpen}
              setSelectedTerm={setSelectedTerm}
              setActiveTerms={setActiveTerms}
              setMaxFileCount={setMaxFileCount}
            />
          ))}
        </div>
      }

      { (isLoadingTerms || isUploading) && <div style={{marginTop: 25}}><Spinner size={42} /></div> }

      {modalTermOpen && (
        <React.Suspense fallback={<div />}>
          <ModalTerm
            open={modalTermOpen}
            setIsOpen={setModalTermOpen}
            selectedTerm={selectedTerm!}
            setSelectedTerm={setSelectedTerm}
            setActiveTerms={setActiveTerms}
          />
        </React.Suspense>
      )}

      {confirmUploadingModal && (
        <React.Suspense fallback={<div />}>
          <ConfirmationModal
            isOpen={confirmUploadingModal}
            onCancel={() => setConfirmUploadingModal(false)}
            isLoading={isUploading}
            submit={saveTerms}
            okText={t('Settings.terms.confirmModal.okButton')}
            text={<div className={classes.textModal}>{t('Settings.terms.confirmModal.text')}</div>}
            title={<div className={classes.titleRow}>
              <ExclamationCircleOutlined className={classes.exclamationIcon}/>{t('Settings.terms.confirmModal.title')}
            </div>}
          />
        </React.Suspense>
      )}

    </Card>
  );
}

export default AccessTerms;
