import React from 'react';
import LogsTable from '../../components/LogsTable/LogsTable';
import LogsManagement from '../../components/LogsManagement/LogsManagement';
import { useAppDispatch, useAppSelector } from 'store/hook';
import classes from './Logs.module.scss';
import { fetchAvailableLogs } from 'store/reducers/logsCreator';
import { useNavigate, useParams } from 'react-router-dom';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import { useTranslation } from 'react-i18next';
import { setLogsForPrint } from 'store/slices/dataLogsSlice';

const Logs = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { dataRoom, isLoadingRoom } = useAppSelector((store) => store.dataRoom);
  const { logs, isLoadingLogs } = useAppSelector((store) => store.logs);
  const { userPermissions, userData, userAndRoomStatus } = useAppSelector((store) => store.userData);

  const { i18n } = useTranslation()
  const locale = i18n.language || 'en'
  
  React.useEffect(() => {
    if (userData?.id && !userAndRoomStatus) {
      if (!userPermissions?.can_view_logs) {
        navigate(`/room/${dataRoom?.custom_url || dataRoom?.id || params.room}/documents`);
      }
    }
  }, [userPermissions, userAndRoomStatus]);

  React.useEffect(() => {
    dispatch(setSelectedKeys(['logs']));
    window.scroll(0, 0);
    dataRoom?.id && dispatch(fetchAvailableLogs({ roomId: dataRoom?.id, locale }));

    return () => {
      dispatch(setLogsForPrint([]))
    }
  }, [dataRoom, locale]);

  return (
    <div className={classes.tableWrapper}>
      <div className={classes.subWrapper}>
        <LogsManagement />

        <LogsTable
          logs={logs}
          isLoading={isLoadingLogs || isLoadingRoom || userAndRoomStatus !== ''}
          
          scroll={{
            y: 'calc(100vh - 285px)',
            // x: '625px'
          }}
        />
      </div>
    </div>
  );
};

export default Logs;
