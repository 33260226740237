import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { deleteCookie } from 'helpers/cookies';
import { IDataRoom } from 'interfaces';
import { IRoomInite } from 'interfaces/IInvitations';
import { RootState } from 'store';
import { setRooms } from 'store/slices/userDataSlice';

export const prepareRooms = (available_rooms: IDataRoom[]) => {
  return available_rooms
    .map((room) => ({
      ...room,
      created_at: Date.parse(String(room.created_at)),
      last_action_timestamp: Date.parse(String(room.last_action_timestamp || room.created_at)),
    }))
    .sort((a, b) => b.last_action_timestamp - a.last_action_timestamp);
};

export const fetchUserData = createAsyncThunk('dataUser/fetchAll', async (props: {}, thunkAPI) => {
  try {
    const userInfo = await api.getUserInfo();
    
    return await userInfo.data;
  } catch (e) {
    deleteCookie('athena-token');
    deleteCookie('refresh_token');
    window.location.href = `/login`;
    return thunkAPI.rejectWithValue('Error while retrieving user data');
  }
});

export const fetchUserRooms = createAsyncThunk('userData/fetchUserRooms', async (props: {}, thunkAPI) => {
  try {
    const userRooms = await api.getUserRooms();
    let invites: IRoomInite[] = [];

    try {
      const userInvites = await api.getInvitesByUser();
      invites = userInvites.data
    } catch (e) {}

    const invitesToRooms: IDataRoom[] = invites
      .map((invite) => ({
        ...invite.room,
        last_action_timestamp: null,
        lives_to: null,
        created_at: null,
        invite_id: invite.id,
        invited_at: invite.created_at
      }));    
    
    return prepareRooms([...invitesToRooms, ...userRooms.data,])
  } catch (e) {
    return thunkAPI.rejectWithValue("Error while retrieving user's rooms");
  }
});

export const fetchUserPermissions = createAsyncThunk(
  'permissionsUser/fetchAll',
  async (props: { idRoom: string }, thunkAPI) => {
    try {
      const userPermissions = await api.getUserPermissions(props.idRoom);
      return userPermissions.data;
    } catch (e) {
      return thunkAPI.rejectWithValue('Download failed');
    }
  }
);

export const updateRooms = createAsyncThunk('update/rooms', async (payload, thunkAPI) => {
  try {
    const { dispatch } = thunkAPI;
    const userAvailableRooms = await api.getUserRooms();
    let invites: IRoomInite[] = [];

    try {
      const userInvites = await api.getInvitesByUser();
      invites = userInvites.data
    } catch (e) {}

    const invitesToRooms: IDataRoom[] = invites
      .map((invite) => ({
        ...invite.room,
        last_action_timestamp: null,
        lives_to: null,
        created_at: null,
        invite_id: invite.id,
        invited_at: invite.created_at
      }));

    dispatch(setRooms(prepareRooms([...invitesToRooms, ...userAvailableRooms.data])));
  } catch (err) {
    console.log(err);
  }
});
