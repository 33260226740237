import { Button, message, Table, TableProps, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'store/hook';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import TableLoader from 'components/Sceletons/TableLoader';
import classes from '../../Billing.module.scss';
import api from 'api';
import { IColumn } from 'interfaces';
import { getRoomsBillingColumns } from './collumn';
import { Link } from 'react-router-dom';
import { CloseCircleFilled } from '@ant-design/icons';

const RoomsBillingTable = () => {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  const [paymentsDeclining, setPaymentsDeclining] = React.useState<string[]>([]);

  const arrColumnsWithFilters = ['room_name', 'payment_status'];

  const { userData } = useAppSelector((store) => store.userData);
  const { i18n, t } = useTranslation();

  React.useEffect(() => {
    getBills();
  }, []);

  const getBills = async () => {
    setIsLoading(true);
    try {
      const response = await api.getRoomsBills();          
      setDataTable(response.data.map((el: any, i: number) => ({
        ...el,
        key: el.id,
        room_name: el.room?.name,
        tariff_lifetime_to: el.room?.tariff_lifetime_to,
      })));
    } catch (e: any) {
      message.error(t('Billing.error.failedGet'));
    } finally {
      setIsLoading(false);
    };
  };

  React.useEffect(()=> {
    setModifiedColumns(getColumnsWithFilters(dataTable, arrColumnsWithFilters));
  },[dataTable, i18n.language, paymentsDeclining]);
  
  const declinePayment =  async (payment: any) => {
    setPaymentsDeclining(prev => [...prev, payment.id]);
    try {
      await api.declinePayment({billing_id: payment.id});
      setDataTable(dataTable.map((el: any) => (el.id === payment.id
        ? {...el, payment_status: 'declined'}
        : el
      )));
      message.success(t('Billing.success.declinePayment'));
    } catch (e: any) {
      message.error(t('Billing.error.declinePayment'));
    } finally {
      setPaymentsDeclining(prev => prev.filter((el: any) => el !== payment.id));
    };
  };

  const getPaymentStatusRow = (status: string, payment_link?: string) => {
    switch(status) {
      case 'awaiting_payment':
        return <Link to={payment_link!}>{ t(`Billing.columns.${status}`) }</Link>;
      case 'paid':
        return <span style={{color: 'lightgreen'}}>{t(`Billing.columns.${status}`)}</span>;
      default: 
        return <span style={{color: 'red'}}>{t(`Billing.columns.${status}`)}</span>;
    };
  };

  const renderCell = ({ value, info, col }: any) => {
    const isDataCol = col.key === 'paid_at' || col.key === 'purchase_date' || col.key === 'created_at';
    if (isDataCol) {
        return value ? getDateWithTimeZone(value, userData?.date_format! || 'international') : '-';
    };
    if (col.key === 'tariff_lifetime_to') {
      return (value && info.payment_status === 'paid')
        ? getDateWithTimeZone(value, userData?.date_format! || 'international')
        : '-';
  } ;
    if (col.key === 'paid_period') {
      return info.duration && info.duration_unit
        ? `${info.duration} ${t(`Billing.roomsColumns.${info.duration_unit.toLowerCase()}`)}`
        : '-';
    };
    if (col.key === 'cost') {
      return `${value} $`;
    };
    if (col.key === 'payment_status') {
      return <div style={{textWrap: 'wrap'}}>
        {getPaymentStatusRow(value, info.payment_link)}
      </div>
    };

    if(col.key === 'tariff_name') {
      const tariffName = info.tariff_name.split(' ')[0];      
      const tariff_name = tariffName
        ? t(`Tariffs.${col.key}.${tariffName}`)
        : '-';
      return <div className={classes.tariff_name}>
        {[
          tariff_name,
          `${info.updates.constraints.storage} ${t(`Tariffs.gb`)}`,
          `${info.updates.constraints.members} ${t(`Tariffs.members`)}`
        ].join(' / ')}
      </div>;
    };

    if (col.key === 'decline' && info.payment_status === 'awaiting_payment') {
      return <Tooltip title={t('Billing.declinePayment')}>
        <Button
          danger
          size='small'
          type='text'
          loading={paymentsDeclining.includes(info.id)}
          disabled={paymentsDeclining.includes(info.id)}
          onClick={() => declinePayment(info)}
          icon={<CloseCircleFilled />}
        />
      </Tooltip>
    };

    return value;
  };
  
  const getColumnsWithFilters = (dataSource: any, filtrableСolumns: string[]) => {
    const logsColumns = getRoomsBillingColumns(t);

    return logsColumns.map((col: IColumn) => {
      let currentFilters: any[] = []
      if (filtrableСolumns.includes(col.key)) {
        const unicValues = Array.from(new Set(dataSource.map((log: any) => log[col.dataIndex])));
        
        currentFilters = unicValues.map((value: any) => {
          if (col.key === 'payment_status') {
            return { value: value, text: t(`Billing.columns.${value}`) };
          }
          return { value: value, text: value };
        });
      };
      
      return {
        ...col,
        render: (value: any, info: any) => renderCell({value, info, col}),
        filters: filtrableСolumns.includes(col.dataIndex) && currentFilters,
        onFilter: (value: boolean, record: any) => record[col.dataIndex] === value,
        ellipsis: filtrableСolumns.includes(col.dataIndex) && true,
      };
    });
  };
  
  const onChange: TableProps<object>['onChange'] = (pagination, filters, sorter, extra) => {    
    if (extra.action === 'filter') {
      setModifiedColumns(getColumnsWithFilters(extra.currentDataSource, arrColumnsWithFilters));
    };    
  };

  if (isLoading) {
    return (
      <div className={classes.scheduleWrap}>
        <TableLoader height={'calc(100vh - 165px)'} padding={15} />
      </div>
    );
  };

  return <Table
    dataSource={dataTable}
    columns={modifiedColumns}
    pagination={false}
    size='middle'
    onChange={onChange}
    className={classes.scheduleTable}
    scroll={{
      y: 'calc(100vh - 220px)',
    }}
  />
}

export default RoomsBillingTable;
