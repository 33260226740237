import { message, Segmented } from 'antd';
import api from 'api';
import { InviteUsersModal } from 'components/Modals';
import ConfigureRoles from 'components/Modals/ConfigureRoles/ConfigureRoles';
import NewRolePermissions from 'components/Modals/PermissionsModal/NewRolePermissions';
import RowLoader from 'components/Sceletons/RowLoader';
import { IDataUserTable, IPendingInviteUsers, IRestrictedUser, IUsers } from 'interfaces';
import Mark from 'mark.js';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import fetchGroupsAndUsers, { defaultGroups, fetchPermissions, setNewRole } from 'store/reducers/usersGroupCreator';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import {
  setIsConfigureRolesOpen,
  setPendingInviteUsers,
  setUserAndData,
  setUserChoosen,
} from 'store/slices/dataUsersSlice';
import { setMoreInfoHidden } from 'store/slices/windowStateSlice';
import MoreInfoComponent from './MoreInfoComponent';
import UserCard from './UserCard';
import classes from './UsersTab.module.scss';
import UsersTabControl from './UsersTabControl';
import PendingInvitations from 'components/Invitations/PendingInvitations';

const UsersTab = () => {
  const [isInviteUserOpen, setIsInviteUserOpen] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [configurableRole, setConfigurableRole] = React.useState<IDataUserTable | null>(null);
  const [isOpenPermissionsModal, setIsOpenPermissionsModal] = React.useState(false);
  const [currentSegment, setCurrentSegment] = React.useState<keyof typeof usersCases>('room');
  const [displayedUsers, setDisplayedUsers] = React.useState<(IUsers | IRestrictedUser)[]>([]);

  const { dataUserTable, isConfigureRolesOpen, isGroupLoading, groups, users, globalUsers, pendingInviteUsers } =
    useAppSelector((state) => state.dataUsers);
  const { isLoadingUserData, isLoadingUserPermissions } = useAppSelector((state) => state.userData);
  const { isLoadingRoom, dataRoom } = useAppSelector((store) => store.dataRoom);
  const { isMoreInfoHide } = useAppSelector((state) => state.windowState);

  const usersCases = { main: globalUsers, room: users };
  const isLoadingUsersInfo = isGroupLoading || isLoadingRoom || isLoadingUserData || isLoadingUserPermissions;

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setSearchValue('');
    dispatch(fetchPermissions({ t }));
  }, [i18n.language]);

  React.useEffect(() => {
    dataRoom && dispatch(fetchGroupsAndUsers({ room_id: dataRoom?.id }));
    return () => {
      dispatch(setMoreInfoHidden(true));
    };
  }, [dataRoom?.id]);

  React.useEffect(() => {
    currentSegment === 'main' ? setDisplayedUsers(globalUsers) : setDisplayedUsers(users);
  }, [globalUsers, users]);

  const markText = (text: string, markerRef: React.RefObject<HTMLDivElement>, timeout?: number) => {
    const markInstance = new Mark(markerRef.current as HTMLElement);

    setTimeout(() => {
      markInstance.unmark({
        className: classes.mark,
        done: () => {
          markInstance.mark(text, {
            className: classes.mark,
          });
        },
      });
    }, timeout || 100);
  };

  const changeRole = async (user: IUsers, group_id: string, targetGroupName?: string) => {
    const groupName = groups.find((group) => group.id === group_id)?.name;

    const details = {
      username: user?.first_name,
      group_name: groupName,
      prev_group_name: groupName,
    };
    const responce = await dispatch(setNewRole({ user, group_id, room_id: dataRoom?.id!, details, t }));
    const localizedRoleName =
      groupName && defaultGroups.includes(groupName) ? t(`Users.table.${groupName}`) : groupName;

    if (responce?.payload === 'Failed setting new role') {
      message.error(`${t('Users.error.addUser')} '${localizedRoleName}'`);
    } else {
      message.success(`${t('Users.success.addUser')} '${localizedRoleName}'`);
    }
  };

  const onUserClick = (event: any, userId: string) => {
    if (event.target.role === 'tooltip') return;
    const userRecord = (usersCases[currentSegment] as IRestrictedUser[]).find((user) => user.id === userId) as IUsers;
    dispatch(setUserChoosen(userRecord || null));
    dispatch(setMoreInfoHidden(false));
  };

  const deleteUser = async (selectedUser: IUsers) => {
    try {
      await api.deleteSelectedUser(selectedUser!.id, dataRoom!.id);

      const tableDataWithoutSelectedUser = dataUserTable.map((elem) => {
        let first_name: string;
        const isNeedToChangeName = elem.children?.find((child: IUsers) => child.id === selectedUser!.id);
        if (isNeedToChangeName)
          first_name = `${elem.emailOrRole} (${elem.children?.length ? elem.children.length - 1 : 0})`;
        else first_name = elem.first_name!;
        return {
          ...elem,
          first_name,
          children: elem.children ? elem.children.filter((user) => user.id !== selectedUser!.id) : [],
        };
      });
      const available_users = dataRoom?.available_users!.filter((user) => user.id !== selectedUser?.id) || [];

      dispatch(
        setUserAndData({
          dataUserTable: tableDataWithoutSelectedUser,
          users: users.filter((elem) => elem.id !== selectedUser!.id),
        })
      );
      dispatch(setDataRoom({ ...dataRoom!, available_users }));
      message.success(t('Users.confirm.userExcludeSuccess'));
    } catch (e) {
      message.error(t('Users.confirm.userExcludeError'));
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const lowercaseValue = value.toLocaleLowerCase();
    setSearchValue(value.toLocaleLowerCase());
    const filteredUsers = (usersCases[currentSegment] as IUsers[]).filter(
      (user) =>
        user.email.toLowerCase().includes(lowercaseValue) ||
        `${user.first_name} ${user.last_name}`.toLowerCase().includes(lowercaseValue) ||
        t(`Users.table.${user.role}`).toLocaleLowerCase().includes(lowercaseValue)
    );

    setDisplayedUsers(filteredUsers);
  };

  const deleteInvite = useCallback(
    async (invite_id: string) => {
      try {
        const statusChangedUsers = pendingInviteUsers.map((user) =>
          user.id === invite_id ? { ...user, invite_status: 3 } : user
        );
        dispatch(setPendingInviteUsers(statusChangedUsers));
        await api.deleteInvite(invite_id);
        const filteredUsers = pendingInviteUsers.filter(({ id }) => id !== invite_id);
        dispatch(setPendingInviteUsers(filteredUsers));
      } catch (err) {
        message.error('Cannot delete invite');
      }
    },
    [pendingInviteUsers]
  );

  const reInvite = useCallback(
    async (user: IPendingInviteUsers) => {
      try {
        const statusChangedUsers = pendingInviteUsers.map((user) =>
          user.id === user.id ? { ...user, invite_status: 3 } : user
        );
        dispatch(setPendingInviteUsers(statusChangedUsers));
        await api.deleteInvite(user.id);
        await api.getNewInvite({
          emails: [user.email],
          room_id: user?.room_id!,
          type: 'common',
          group_id: user.group_id,
          details: {
            emails: [user.email],
            group_name: user.group_name,
            group_role: user.group_name,
            group_id: user?.group_id,
          },
        });
        const newPendingUsers = pendingInviteUsers.map((user) =>
          user.id === user.id ? { ...user, invite_status: 0 } : user
        );
        dispatch(setPendingInviteUsers(statusChangedUsers));
        dispatch(setPendingInviteUsers(newPendingUsers));
      } catch (err) {
        message.error('Cannot re-invite');
      }
    },
    [pendingInviteUsers]
  );

  const segmentOptions = [
    {
      label: 'Комната',
      value: 'room',
    },
    {
      label: 'Контакты',
      value: 'main',
    },
  ];

  return (
    <div className={classes.usersTab}>
      {/* <Segmented
        className={classes.segment}
        options={segmentOptions}
        value={currentSegment}
        disabled={!dataRoom?.id}
        onChange={(value) => {
          setCurrentSegment(value as keyof typeof usersCases);
          setDisplayedUsers(usersCases[value as keyof typeof usersCases]);
        }}
      /> */}
      <UsersTabControl
        setIsInviteUserOpen={setIsInviteUserOpen}
        isLoadingUsersInfo={isLoadingUsersInfo}
        onSearch={onSearch}
        searchValue={searchValue}
      />
      {Boolean(pendingInviteUsers.length) ? (
        <div style={{ marginRight: 17 }}>
          <PendingInvitations
            reInvite={reInvite}
            users={pendingInviteUsers}
            isLaoding={isLoadingUsersInfo}
            deleteInvite={deleteInvite}
            t={t}
          />
        </div>
      ) : null}
      {isLoadingUsersInfo ? (
        <div style={{ marginRight: 10 }}>
          <RowLoader width={600} padding={'0 0 0 0'} height={70} />
          <RowLoader width={600} marginTop={10} padding={'0 0 0 0'} height={70} />
          <RowLoader width={600} marginTop={10} padding={'0 0 0 0'} height={70} />
        </div>
      ) : isMoreInfoHide ? (
        <div className={classes.usersWrap}>
          {displayedUsers[0] ? (
            displayedUsers.map((user) => (
              <div key={user.id}>
                <UserCard
                  user={user as IUsers}
                  currentSegment={currentSegment}
                  changeRole={changeRole}
                  deleteUser={deleteUser}
                  markText={markText}
                  searchValue={searchValue}
                  onUserClick={onUserClick}
                />
              </div>
            ))
          ) : (
            <div className={classes.noFound}>{t('Users.Tabs.noFound')}</div>
          )}
        </div>
      ) : (
        <MoreInfoComponent currentSegment={currentSegment} />
      )}

      {isInviteUserOpen && (
        <React.Suspense fallback={<div />}>
          <InviteUsersModal isOpen={isInviteUserOpen} setIsInviteUserOpen={setIsInviteUserOpen} />
        </React.Suspense>
      )}

      {isConfigureRolesOpen && (
        <React.Suspense fallback={<div />}>
          <ConfigureRoles
            isOpen={isConfigureRolesOpen}
            onClose={() => dispatch(setIsConfigureRolesOpen(false))}
            configurableRole={configurableRole}
            setConfigurableRole={setConfigurableRole}
            setIsOpenPermissionsModal={setIsOpenPermissionsModal}
          />
        </React.Suspense>
      )}

      {isOpenPermissionsModal && (
        <React.Suspense fallback={<div />}>
          <NewRolePermissions
            open={isOpenPermissionsModal}
            onCancel={() => setIsOpenPermissionsModal(false)}
            choosenRole={configurableRole}
          />
        </React.Suspense>
      )}
    </div>
  );
};

export default UsersTab;
