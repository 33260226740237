// @ts-nocheck
import React from 'react';
import cn from 'classnames'
import classes from './Mobile.module.scss'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Mobile = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'MobileApps' });
  const navigate = useNavigate();

  React.useEffect(() => {
    const isMobile = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent)
    if (!isMobile) navigate('/');
  }, []);

  return (
    <div className={classes.wrapper}>
        <div className={classes.headerImg}>TazaDeal</div>
        <div className={cn(classes.text, classes.title)}>{t('MobileApp.welcomeUser')}</div>
        <div className={classes.text}>{t('MobileApp.notSupported')}</div>
        <div className={classes.text}>{t('MobileApp.installApp')}</div>
        <div className={classes.linkWrap}>
            <a href={`${window.REACT_APP_IOS_LINK}`} className={cn(classes.link, classes.apple)} />
            <a href={`${window.REACT_APP_ANDROID_LINK}`} className={cn(classes.link, classes.google)} />
        </div>
    </div>
  )
}

export default Mobile
