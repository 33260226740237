import { TFunction } from 'i18next';
import { ChangeEvent, Dispatch, KeyboardEvent, RefObject, SetStateAction, useEffect } from 'react';
import { CURRENT_INPUT_TAG } from '../Messages/patterns';
import classes from '../Messanger.module.scss';

type CustomInputProps = {
  submitOnEnterClick: (event: KeyboardEvent<HTMLDivElement>) => void;
  onChange: (event: any) => void;
  setIsOpenTags: Dispatch<SetStateAction<boolean>>;
  setSearch: Dispatch<SetStateAction<string>>;
  setIsOpenHelp: Dispatch<SetStateAction<boolean>>;
  t: TFunction;
  customProp: string;
  isOpenHelp: boolean;
  editableRef: RefObject<HTMLDivElement>;
};

export default function CustomInput({
  submitOnEnterClick,
  onChange,
  t,
  setIsOpenTags,
  setSearch,
  setIsOpenHelp,
  isOpenHelp,
  editableRef,
}: CustomInputProps) {
  useEffect(() => {
    editableRef.current?.focus();
  }, []);

  const onInput = (event: ChangeEvent<HTMLDivElement>) => {
    const nativeEvent = event.nativeEvent as InputEvent;
    const selection = document.getSelection()!;
    const targetElement = selection.focusNode?.parentElement as HTMLElement;
    if (nativeEvent.inputType === 'insertParagraph') event.target.textContent = '';

    const currentValue = event.target.textContent!;
    const spans = editableRef.current?.querySelectorAll('span');
    const searchValue = editableRef.current?.querySelector('span[data-current="true"]')?.textContent?.slice(1);
    searchValue && setSearch(searchValue);

    spans?.forEach((span) => {
      if ((span.childNodes[0] as Text).data.trim().length <= 0 || (span.childNodes[0] as Text).data === ' ') {
        span.remove();
      }
    });

    if (targetElement.nodeName !== 'SPAN') {
      setSearch('');
      setIsOpenTags(false);
    }

    if (nativeEvent.data === '/') {
      setIsOpenHelp(true);
    } else if (isOpenHelp) {
      setIsOpenHelp(false);
    }

    if (nativeEvent.data === '#') {
      const before = selection.focusOffset <= 0 ? '' : currentValue.slice(0, selection.focusOffset - 1);

      editableRef.current!.innerHTML = `${before} ${CURRENT_INPUT_TAG.OPEN}#${
        CURRENT_INPUT_TAG.CLOSE
      } ${currentValue.slice(selection?.focusOffset)} `;

      document.execCommand('selectAll', false, undefined);
      selection?.collapse(editableRef.current, 2);
      setIsOpenTags(true);
    }

    onChange({ customProp: event.target.textContent });

    // помещаем курсор в конце строки
    // document.execCommand('selectAll', false, undefined);
    // document.getSelection()?.collapseToEnd();
  };
  return (
    <div
      autoFocus
      ref={editableRef}
      data-placeholder={t('Chats.startTyping')}
      onInput={onInput}
      onKeyDown={submitOnEnterClick as any}
      className={classes.messanger_textarea}
      contentEditable
      suppressContentEditableWarning
    />
  );
}
