import { CloseOutlined, MessageOutlined, SendOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Empty, Form, InputRef, MenuProps, Tooltip, Typography } from 'antd';
import api from 'api';
import classNames from 'classnames';
import RowLoader from 'components/Sceletons/RowLoader';
import dayjs from 'dayjs';
import getDateWithTimeZone, { dateFormatCase } from 'helpers/getDateWithTimeZone';
import { TFunction } from 'i18next';
import { IChat, IChatMessage } from 'interfaces';
import { CSSProperties, FC, KeyboardEvent, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchUnviewedAmount } from 'store/reducers/chatCreator';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import { setMessageForReply } from 'store/slices/chatsSlice';
import { setIsOpenViewer } from 'store/slices/viewerSlice';
import CustomInput from './CustomInput/CustomInput';
import { CURRENT_INPUT_TAG, INPUT_TAG, MESSAGE_TAG } from './Messages/patterns';
import RecieveMessage from './Messages/RecieveMessage';
import SendMessage from './Messages/SendMessage';
import classes from './Messanger.module.scss';
import colors from 'scss/variables/colors.module.scss';

type Props = {
  onSendMessage: (event: any) => void;
  onChatUsersOpen: () => void;
  chatGroups: IChatMessage[][];
  chatInfo: IChat | null;
  t: TFunction;
};

const Messanger: FC<Props> = ({ onChatUsersOpen, onSendMessage, t, chatGroups, chatInfo }) => {
  const [isOpenTags, setIsOpenTags] = useState(false);
  const [isOpenHelp, setIsOpenHelp] = useState(false);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [tagsDocuments, setTagsDocuments] = useState<MenuProps['items']>([]);
  const [search, setSearch] = useState<string>('');

  const { userData } = useAppSelector((state) => state.userData);
  const { rootFolderForPermissions } = useAppSelector((state) => state.permissions);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { messageForReply } = useAppSelector((state) => state.chat);
  const editableRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const inputRef = useRef<InputRef>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [form] = Form.useForm();

  const keydownListener = (e: globalThis.KeyboardEvent) => {
    if (e.code === 'Escape') dispatch(setMessageForReply(null));
  };

  const onDropDownCLick = (info: any) => {
    const label = info.key.split(' ')[1];
    const idx = editableRef.current?.innerHTML.indexOf(CURRENT_INPUT_TAG.OPEN)!;
    const holderTemplate = editableRef.current?.innerHTML.slice(idx);
    const closeIdx = holderTemplate?.indexOf(CURRENT_INPUT_TAG.CLOSE);

    const beforeTemplate = editableRef.current?.innerHTML.slice(0, idx - 1);
    const afterTemplate = holderTemplate?.slice(closeIdx);

    editableRef.current?.focus();
    editableRef.current!.innerHTML = `${beforeTemplate} ${INPUT_TAG.OPEN}#${label}${INPUT_TAG.CLOSE} ${afterTemplate}&nbsp;`;

    // помещаем курсор в конце строки
    document.execCommand('selectAll', false, undefined);
    document.getSelection()?.collapseToEnd();

    setIsOpenTags(false);
  };

  useEffect(() => {
    dispatch(fetchUnviewedAmount({ data: chatInfo, count: -1 }));
    form.setFieldValue('message', '');
    document.addEventListener('keydown', keydownListener);

    return () => {
      document.removeEventListener('keydown', keydownListener);
      dispatch(setMessageForReply(null));
    };
  }, []);

  const scrollToBottom = () => bodyRef.current?.scrollTo({ top: bodyRef.current.scrollHeight + 150 });

  useEffect(() => {
    if (bodyRef.current) scrollToBottom();
  }, [form, bodyRef, chatGroups]);

  useEffect(() => {
    if (dataRoom) {
      fetchTagsDocuments(dataRoom.id);
    }
  }, [dataRoom]);

  const fetchTagsDocuments = async (room_id: string) => {
    try {
      setIsLoadingTags(true);
      const response = await api.getAllDocumentsOfRoom(room_id);
      setTagsDocuments(
        response.data.map((doc) => ({
          key: `${doc.id} ${doc.folder_name}/${doc.name}`,
          label: `${doc.folder_name}/${doc.name}`,
          onClick: onDropDownCLick,
        }))
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoadingTags(false);
    }
  };

  const onFinish = (values: any) => {
    const content = editableRef.current?.innerHTML
      .replaceAll(`${CURRENT_INPUT_TAG.OPEN}#${CURRENT_INPUT_TAG.CLOSE}`, '#')
      .replaceAll(`${INPUT_TAG.CLOSE}#${INPUT_TAG.CLOSE}`, '#')
      .replaceAll(INPUT_TAG.OPEN, MESSAGE_TAG.OPEN)
      .replaceAll(INPUT_TAG.CLOSE, MESSAGE_TAG.CLOSE)
      .replaceAll(CURRENT_INPUT_TAG.OPEN, MESSAGE_TAG.OPEN);

    onSendMessage(content?.replace(/\<br\>/g, '\n').replace(/&nbsp;/g, ' '));
    form.resetFields(['message']);
  };

  const submitOnEnterClick = (event: KeyboardEvent<HTMLDivElement>) => {
    const selection = document.getSelection()!;
    const target = selection.focusNode?.parentElement as HTMLElement;
    const nativeEvent = event.nativeEvent;
    const targetElement = selection.focusNode?.parentElement as HTMLElement;

    if (!target.dataset?.current && target.nodeName === 'SPAN' && event.key === 'Backspace') {
      target.remove();
    }
    if (
      !targetElement.dataset?.current &&
      targetElement.nodeName === 'SPAN' &&
      nativeEvent.key !== 'ArrowRight' &&
      nativeEvent.key !== 'ArrowLeft'
    ) {
      nativeEvent.preventDefault();
      nativeEvent.returnValue = false;
      return false;
    }
    if (event.key === 'Enter' && event.ctrlKey === true) {
      event.preventDefault();
      document.execCommand('insertHTML', false, '&nbsp;<br>&nbsp;');
      return false;
    }

    if (event.key === 'Enter' && event.shiftKey === false) {
      onFinish({});
      return false;
    }
  };

  const onTagClick = async (event: MouseEvent<HTMLAnchorElement>) => {
    try {
      const docName = (event.target as HTMLAnchorElement).textContent?.split('/')[1];
      const document = (await api.hasDuplicate({ room_id: dataRoom?.id!, document_name: docName! })).data[0];
      navigate(`${window.location.pathname}?webviewer_file_id=${document.id}`);
      dispatch(setIsOpenViewer(true));
      await dispatch(
        fetchDocumentToViewer({
          token: document.token,
          extension: document.extension,
          is_agreement: false,
          id: dataRoom?.id,
          entity: document.id,
          action: document.is_confidential ? 'view_confident' : 'view',
          locale: i18n.language,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };

  const contextMenu = (message: IChatMessage): MenuProps['items'] => [
    {
      label: (
        <span>
          <MessageOutlined style={{ paddingRight: 6, fontSize: 16 }} />
          {t('Chats.reply')}
        </span>
      ),
      key: 'reply',
      disabled: message.status === 'pending',
      onClick: () => {
        const replied_to = {
          id: message.id,
          content: message.message,
          author: {
            name: message.user_name,
          },
        };
        dispatch(setMessageForReply(replied_to));
        inputRef.current?.focus();
      },
    },
  ];

  const scrollToElement = (chatMessage: IChatMessage) => {
    const targetMessage = chatMessage?.replied_to?.id && document.getElementById(chatMessage?.replied_to?.id);
    if (targetMessage && targetMessage.style) {
      targetMessage.style.setProperty('opacity', '.75');
      targetMessage.style.setProperty('transform', 'scale(1.05)');

      setTimeout(() => {
        targetMessage.style.setProperty('opacity', '1');
        targetMessage.style.setProperty('transform', 'scale(1)');
      }, 1000);
      bodyRef.current?.scrollTo({ top: targetMessage.offsetTop - 20, behavior: 'smooth' });
    }
  };

  const filteredTags = useMemo(
    () => tagsDocuments?.filter((tag) => (tag?.key! as string).includes(search)),
    [search, tagsDocuments]
  );

  const replyOn = (chatMessage: IChatMessage, isRecieve: boolean) => {
    return (
      <div
        onClick={() => scrollToElement(chatMessage)}
        className={classNames(classes.repliedWrap, isRecieve ? classes.recieve : classes.send)}
      >
        <div className={classes.repliedAuthor}>{chatMessage.replied_to?.author.name}</div>
        <div className={classes.repliedMessage}>{chatMessage.replied_to?.content}</div>
      </div>
    );
  };

  return (
    <>
      {chatInfo ? (
        <div className={classes.messanger}>
          <div role='navigation' className={classes.messanger_header}>
            <div className={classes.messanger_header_items}>
              <div
                className={classes.messanger_avatars}
                style={{ '--length': chatInfo?.members.length } as CSSProperties}
                onClick={onChatUsersOpen}
              >
                {chatInfo?.members.map((member, idx) =>
                  idx > 4 ? null : (
                    <Avatar
                      key={`avatar-${idx}`}
                      style={{ '--idx': idx } as CSSProperties}
                      className={classes.messanger_avatar}
                      icon={<UserOutlined />}
                    />
                  )
                )}
              </div>
            </div>
            <div className={classNames(classes.messanger_title, classes.messanger_header_items)}>
              <div className={classes.messanger_name}>
                {t(`Chats.types.${chatInfo?.entity_type}`)} {chatInfo?.entity_name}
              </div>
              {/* <span className={classes.messanger_date}>{t('Chats.lastSeen')}</span> */}
            </div>
          </div>
          <div className={classes.messanger_body} ref={bodyRef}>
            <div className={classes.messanger_bodyMessages}>
              {chatGroups?.map((chats, index) => (
                <div className={classes.messanger_chatGroups} key={`group-${chats[0]?.timestamp}-${index}`}>
                  {chats[0]?.timestamp && (
                    <div className={classes.messanger_dateSection}>
                      {dayjs(
                        getDateWithTimeZone(chats[0]?.timestamp, userData?.date_format!),
                        dateFormatCase[userData?.date_format!]
                      ).format('DD/MM/YYYY')}
                    </div>
                  )}

                  {chats.map((chatMessage, idx) => {
                    const isFirst = chatMessage.user_name !== chatGroups[index][idx - 1]?.user_name;
                    return chatMessage.email === userData?.email ? (
                      <SendMessage
                        key={`message-${chatMessage.created_at + idx}-sender`}
                        chatMessage={chatMessage}
                        isFirst={isFirst}
                        data={chatMessage}
                        date={`${getDateWithTimeZone(chatMessage.created_at, `${userData?.date_format!}Hours`, true)}`}
                        contextMenu={contextMenu}
                        isTagsDisabled={Boolean(
                          rootFolderForPermissions && rootFolderForPermissions.permissions.length
                        )}
                        replyOn={replyOn}
                        onTagClick={onTagClick}
                        t={t}
                      />
                    ) : (
                      <RecieveMessage
                        key={`message-${chatMessage.created_at + idx}-recieve`}
                        chatMessage={chatMessage}
                        isFirst={isFirst}
                        date={`${getDateWithTimeZone(chatMessage.created_at, `${userData?.date_format!}Hours`, true)}`}
                        contextMenu={contextMenu}
                        isTagsDisabled={Boolean(rootFolderForPermissions && rootFolderForPermissions.length)}
                        replyOn={replyOn}
                        onTagClick={onTagClick}
                        t={t}
                      />
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
          <Form onFinish={onFinish} form={form} className={classes.messanger_footer_form}>
            {messageForReply && (
              <div className={classes.replyBlock}>
                <div className={classes.replyWrap}>
                  <div className={classes.whom}>
                    {t('Chats.inReply')} {messageForReply?.author?.name}
                  </div>
                  <div className={classes.reply}>{messageForReply?.content}</div>
                </div>
                <div className={classes.replyClose}>
                  <CloseOutlined onClick={() => dispatch(setMessageForReply(null))} />
                </div>
              </div>
            )}
            <div className={classNames(classes.messanger_footer, !messageForReply?.content ? classes.borderTop : null)}>
              <Dropdown
                menu={{
                  items: isLoadingTags
                    ? [{ key: 'loading', label: <RowLoader width='500px  ' height='20px' /> }]
                    : filteredTags?.length
                    ? filteredTags
                    : [{ key: 'empty', label: t('Documents.table.fallback') }],
                }}
                open={isOpenTags}
                className={classes.tag_documentList}
                placement='top'
              >
                <div className={classes.messanger_tags} />
              </Dropdown>
              <Tooltip
                title={
                  <>
                    <div> {t('Chats.commands.documentsTag')}</div>
                    <div> {t('Chats.commands.usersTag')}</div>
                  </>
                }
                open={isOpenHelp}
                overlayClassName={classes.messanger_overlay}
                placement='topLeft'
                color={colors.primary100}
              >
                <div className={classes.messanger_tags} />
              </Tooltip>
              <Form.Item
                name='message'
                className={classes.messanger_footer_item}
                valuePropName='customProp'
                getValueProps={(v) => ({ customProp: v })}
                getValueFromEvent={(e) => e.customProp}
              >
                {/* <Input.TextArea
              ref={inputRef}
              autoFocus
              className={classes.messanger_textarea}
              placeholder={t('Chats.startTyping')}
              autoSize={{ maxRows: 10, minRows: 1 }}
              onKeyDown={submitOnEnterClick}
              onChange={onChange}
            /> */}
                {/* @ts-ignore */}
                <CustomInput
                  t={t}
                  submitOnEnterClick={submitOnEnterClick}
                  setIsOpenTags={setIsOpenTags}
                  setSearch={setSearch}
                  editableRef={editableRef}
                  setIsOpenHelp={setIsOpenHelp}
                  isOpenHelp={isOpenHelp}
                />
              </Form.Item>
              <div className={classes.messanger_sendingIcons}>
                <SendOutlined onClick={form.submit} className={classes.messanger_footerIcons} />
                <strong className={classes.messanger_footerIcons} onClick={() => setIsOpenHelp((prev) => !prev)}>
                  /
                </strong>
              </div>
            </div>
          </Form>
        </div>
      ) : (
        <Empty description={t('Chats.unselectedState')} className={classes.empty} />
      )}
    </>
  );
};

export default Messanger;
