import { Button, message } from 'antd';
import api from 'api';
import dayjs from 'dayjs';
import { RoomData, RoomMark } from 'components/RoomSettings';
import SettingsLoader from 'components/Sceletons/SettingsLoader';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { IDataRoom, ISettings } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import classes from './Settings.module.scss';
import { createSettingLog } from './createSettingLog';
import { setRooms } from 'store/slices/userDataSlice';

export default function Settings() {
  const [isUpdateLoading, setIsUpdateLoading] = React.useState<boolean>(false);
  const [settings, setSettings] = React.useState<ISettings>({
    name: '',
    description: '',
    watermark_enabled: false,
  });
  const [isAvailableToUpdate, setIsAvailableToUpdate] = React.useState(false);

  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { dataRoom, isLoadingRoom } = useAppSelector((state) => state.dataRoom);
  const { userPermissions, userAndRoomStatus, userData, isLoadingUserPermissions, available_rooms } = useAppSelector((state) => state.userData);

  React.useEffect(() => {
    if (userData?.id && !userAndRoomStatus) {
      if (!userPermissions?.can_edit_room_settings) {        
        navigate(`/room/${dataRoom?.custom_url || dataRoom?.id || params.room}/${ localStorage.getItem('previousPage') || 'documents'}`);
      }
    }
  }, [userPermissions, userAndRoomStatus]);

  React.useEffect(() => {
    dispatch(setSelectedKeys(['settings']));
  }, []);

  React.useEffect(() => {
    setSettings((prev) => ({
      ...prev,
      name: dataRoom?.name || '',
      description: dataRoom?.description || '',
      watermark_enabled: dataRoom?.watermark_enabled,
    }));
  }, [dataRoom]);

  React.useEffect(() => {
    dataRoom && onFieldsChange(dataRoom, settings)
  }, [settings])

  const onChangeSettings = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setSettings((prev) => ({ ...prev, [target.name]: target.value }));
  };

  const onFieldsChange = (dataRoom: IDataRoom, settings: ISettings) => {
    let isSomeChanged = false;
    for (let field in settings) {
      const exceptions = ['action', 'agreement_enable', 'agreement_file'];
      if (!exceptions.includes(field)) {
        const was = dataRoom[field as keyof typeof dataRoom];
        const becomed = settings[field as keyof typeof settings];
        // eslint-disable-next-line eqeqeq
        if (becomed != was) {
          isSomeChanged = true;
        }
      }
    }
    setIsAvailableToUpdate(isSomeChanged);
  }
  const onToggleWatermark = (isEnableWatermark: boolean) => {
    setSettings((prev) => ({ ...prev, watermark_enabled: isEnableWatermark }));
  };

  const onSave = async () => {
    setIsUpdateLoading(true);
    try {   
      const details = createSettingLog(dataRoom!, settings);

      const formDataSettings = new FormData() as any;
      formDataSettings.append('name', settings.name);
      formDataSettings.append('description', settings.description);
      formDataSettings.append('details', JSON.stringify(details));

      settings.watermark_enabled !== dataRoom?.watermark_enabled &&
        (await api.toggleRoomWatermark({ enable: settings.watermark_enabled!, id: dataRoom?.id! }));
        
      const response = await api.setRoomSettings(
        formDataSettings,
        dataRoom?.id!,
        'update',
        settings.agreement_file?.name || ''
      );
      const UTCDate = dayjs().valueOf() + new Date().getTimezoneOffset() * 60 * 1000;
      const newRooms = available_rooms.map((room) =>
        room.id === response.data.id
          ? {
            ...response.data,
            created_at: dayjs(response.data.created_at).unix() * 1000,
            last_action_timestamp: UTCDate,
            permissions: room.permissions,
          }
          : room
      ).sort((a,b) => b.last_action_timestamp - a.last_action_timestamp);
      dispatch(setRooms(newRooms || []));
      navigate(`/room/${response.data?.custom_url || response.data?.id}/settings`);
      dispatch(setDataRoom({...response.data, user_status: 2}));
      message.success(
        `${t('Settings.success.settingsUploaded.before')} ${response.data?.name}
        ${t('Settings.success.settingsUploaded.after')}`
      );
    } catch (err) {
      message.error(
        `${t('Settings.error.settingsUpload.before')} ${dataRoom?.name} 
        ${t('Settings.error.settingsUpload.after')}`
      );
    } finally {
      setIsUpdateLoading(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.card_group}>
        {isLoadingRoom || isLoadingUserPermissions || userAndRoomStatus !== '' ? (
          <SettingsLoader />
        ) : (
          <>
            <RoomData t={t} setSettings={onChangeSettings} />
            <RoomMark t={t} watermark_enabled={settings.watermark_enabled!} setWaterMark={onToggleWatermark} />

            <Button
              loading={isUpdateLoading}
              onClick={onSave}
              className={classes.saveButton}
              type='primary'
              disabled={!settings.name || !isAvailableToUpdate}
            >
              {t('main.saveButton')}
            </Button>
          </>
        )}
      </div>

      {isUpdateLoading && <UploadingComponent />}
    </div>
  );
}
