import React from 'react';
import classes from './TariffEnded.module.scss';
import { DownloadOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, message, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getFile } from 'helpers/downloadSomeFile';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchRoom } from 'store/reducers/roomsCreator';
import RowLoader from 'components/Sceletons/RowLoader';
import { Tarrifs } from 'components/TariffsComponent/tariffsData';

const TariffEnded = () => {
  const [isDownloading, setIsDownloadLoading] = React.useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { dataRoom, isLoadingRoom } = useAppSelector((store) => store.dataRoom);  
  const { isLoadingUserData } = useAppSelector((store) => store.userData);  

  React.useEffect(() => {
    params.room && dispatch(fetchRoom({ id: params.room }));
  }, [])
  
  React.useEffect(() => {
    if (!dataRoom?.lives_to) {      
      dataRoom && navigate(`/room/${dataRoom?.id}/documents`);
    }
  }, [dataRoom])

  const downloadDocumentsOfRoom = async () => {
    setIsDownloadLoading(true)

    try {
      const response = await api.downloadAsArchive(dataRoom?.id!);
      getFile(response.data, `documents_of_room(${dataRoom?.name})`);

      message.success(t('ended_tariff_lifetime.successDownload'));
    } catch (e) {
      message.error(t('ended_tariff_lifetime.errorDownload'));
    } finally {
      setIsDownloadLoading(false);
    }
  }

  const navigateToTariffs = () => {
    if (!isLoadingRoom && !isLoadingUserData){
      navigate(`/tariffs`);
      dataRoom?.id && localStorage.setItem('ended_tariff_lifetime', dataRoom?.id);
    }
  }

  return (
    <div className={classes.infoWrap}>
      <div className={classes.info}>
        <WarningOutlined className={classes.warningIcon}/>
        <div className={classes.text}>
          <p style={{display: 'flex'}}>
            {(isLoadingRoom || isLoadingUserData)
              ? <RowLoader width='150px' padding='2px 0 0 0' height='24px' />
              : <b>{dataRoom?.name}:</b>
            }
            <span style={{paddingLeft: 3}}>{t('ended_tariff_lifetime.accessLimited')}</span>
          </p>

          { (isLoadingRoom || isLoadingUserData)
            ? <RowLoader width='800' padding='2px 0 0 0' height='200px' />
            : (<div className={classes.productCard}>
              <div className={classes.title}>{t('Header.createModal.productCard.titleTariff')}</div>
              <div className={classes.row}>
                <div className={classes.name}>{t('Header.createModal.productCard.tariffName')}</div>
                <div className={classes.text}>{t(`Tariffs.tariff_name.${dataRoom?.tariff?.tariff_name}`)}</div>
              </div>
              {dataRoom?.tariff?.tariff_name !== Tarrifs.Trial && <div className={classes.row}>
                <div className={classes.name}>{t('Header.createModal.productCard.tariffCost')}</div>
                <div className={classes.text}>{`${dataRoom?.tariff?.cost}$ ${t(`Tariffs.one_month`)}`}</div>
              </div>}
              {dataRoom?.tariff?.constraints && <div className={classes.row}>
                <div className={classes.name}>{t('Header.createModal.productCard.tariffInfo')}</div>
                <div className={classes.text}>
                  <span>{`- ${t('Header.createModal.productCard.storage')} - ${dataRoom?.tariff?.constraints?.storage} ${t('Tariffs.gb')}`}</span>
                  <br/>
                  <span style={{paddingTop: 5}}>- {t(`Tariffs.number_of_users_${dataRoom?.tariff?.constraints?.members}`)}</span>
                </div>
              </div>}
            </div>)
          }

          <p>{t('ended_tariff_lifetime.deletionWarning')}</p>
          <p>{t('ended_tariff_lifetime.paymentOffer')}</p>
        </div>
        <div className={classes.actions}>
          {/* <Tooltip title={t('ended_tariff_lifetime.notAvailable')}>
            <Button 
              disabled //={isLoadingRooms || isLoadingUserData || !dataRoom || isDownloading}
              loading={isLoadingRooms || isLoadingUserData || !dataRoom || isDownloading}
              type='primary'
              onClick={downloadDocumentsOfRoom}
              className={classes.downloadBtn}
              icon={<DownloadOutlined className={classes.downloadIcon}/>}
            >
              {t('ended_tariff_lifetime.downloadBtn')} 
            </Button>
          </Tooltip> */}


          {(isLoadingRoom || isLoadingUserData)
            ? <RowLoader width='150px' padding='2px 0 0 0' height='24px' />
            : <div onClick={navigateToTariffs} className={classes.primary}>
                {t('ended_tariff_lifetime.tarrifsLink')}
              </div>
          }
          
        </div>
      </div>
    </div>
  );
}

export default TariffEnded;
