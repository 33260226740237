export const tariffsColumns = [
  {
    title: 'Trial',
    dataIndex: 'trial',
    key: 'trial',
    width: '25%',
    // onCell: (record: any) => ({
    //   colSpan: record.key  === 'available_rooms' ? 4 : 1,
    // })
  },
  {
    title: 'Starter',
    dataIndex: 'starter',
    key: 'starter',
    width: '25%',
    // onCell: (record: any) => ({
    //   colSpan: record.key === 'available_rooms' ? 0 : 1,
    // })
  },
  {
    title: 'Business',
    dataIndex: 'business',
    key: 'business',
    width: '25%',
    // onCell: (record: any) => ({
    //   colSpan: record.key === 'available_rooms' ? 0 : 1,
    // })
  },
  {
    title: 'Enterprise',
    dataIndex: 'enterprise',
    key: 'enterprise',
    width: '25%',
    // onCell: (record: any) => ({
    //   colSpan: record.key === 'available_rooms' ? 0 : 1,
    // })
  },
];
